<template>
    <app-layout>

        <h1 class="hdln-1 mb-8">Reports</h1>

        <div class="flex flex-wrap">

            <router-link v-for="report in reports"
                         :to="report.link"
                         class="report bg-secondary hover:bg-secondary-lighter p-4 rounded text-white w-60 mr-4 mb-4">
                <p class="font-bold">{{ report.name }}</p>
                <font-awesome-icon icon="clipboard-list" class="float-right text-4xl mt-4" />
            </router-link>

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'

export default {
    components: {
        AppLayout,
        Panel
    },
    computed: {
        reports() {
            return [
                { name: 'Policies', link: '/admin/reports/policies' },
                { name: 'Users', link: '/admin/reports/users' }
            ];
        }
    }
}

</script>
