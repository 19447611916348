<template>
  <div
    class="flex flex-col h-full w-full overflow-y-scroll chatscroll bg-white"
    ref="feed"
  >
    <div
      v-for="message in messages"
      :key="message.id"
      class="flex m-4"
      :class="
        `${message.user_id == getUser.id ? 'justifiy-start' : 'justify-end'}`
      "
    >
      <div
        :class="
          `${
            message.user_id == getUser.id
              ? 'bg-primary rounded-br-3xl rounded-tr-3xl rounded-tl-xl'
              : 'bg-secondary  rounded-bl-3xl rounded-tl-3xl rounded-tr-xl'
          }`
        "
        class="py-3 px-4  text-white"
      >
        <div class="font-semibold">{{ message.body }}</div>
        <div class="border-t border-opacity- text-grey-lighter text-xs mt-px">
          <div>{{ getSentUser(message) }}</div>
          {{ message.created_at }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },

  computed: {
    getUser() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    scrollToBottom() {
      setTimeout(() => {
        this.$refs.feed.scrollTop =
          this.$refs.feed.scrollHeight - this.$refs.feed.clientHeight;
      }, 50);
    },
    getDate(message) {
      let date = new Date(message.created_at);
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let year = date.getFullYear();
      let hours = date.getHours();
      let mins = date.getMinutes();
      let newdate = day + "/" + month + "/" + year + " " + hours + ":" + mins;
      return newdate;
    },
    getSentUser(message) {
      return message.user.first_name + ' ' + message.user.last_name
    }
  },
  watch: {
    contact(contact) {
      this.scrollToBottom();
    },
    messages(messages) {
      this.scrollToBottom();
    },
  },
};
</script>
