<template lang="html">

    <div class="overlay pb-8 mb-8" :style="{ display: ( isOpen ? 'block' : 'none' ) }" :class="{'loading-white': loading}">

        <div class="mt-20">

            <div class="confirm-box modal-inner bg-white rounded relative mb-20">
                <!-- <font-awesome-icon icon="times-circle" class="absolute -right-3 -top-3 text-white text-2xl cursor-pointer" @click="$emit('closed')" /> -->
                <div class="bg-primary text-white rounded-t px-4 py-2">{{ header }}</div>
                <div class="p-8 modal-body overflow-y-scroll">
                    <slot></slot>
                </div>

                <div class="p-4 actions flex justify-center">
                    <app-button color="secondary" style="width: 160px; text-align: center;" @click="$emit('onSave')">Done</app-button>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import AppButton from '../ui/AppButton'

    export default {
        components: {
            AppButton
        },
        props: {
            message: String,
            loading: {
                type: Boolean,
                default: false
            },
            header: String,
            isOpen: Boolean,
            id: Number
        }
    }

</script>

<style lang="css">

    .overlay {
        position: fixed;
        background: #000000c9;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 9;
        overflow-y: scroll;
    }

    .modal-inner {
        width: 90%;
        max-width: 1000px;
        padding-bottom: 60px;
        margin: 100px auto;
    }

    .modal-body {
        max-height: 800px;
    }

    .actions {
        position: absolute;
        bottom: 0px;
        width: 100%;
    }

</style>
