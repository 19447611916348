<template>
    <app-layout>

        <div :class="{ 'loading-white': $store.state.loading }">

            <div class="flex justify-between items-center mb-8">
                <h1 class="hdln-1">Policy Management</h1>
                <div>
                    <input class="bg-white border-b placeholder-black focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search Policy" />
                    <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
                </div>
            </div>

            <div class="relative mb-6" v-for="policy in policies">
                <app-table>
                    <template #thead>
                        <tr class="text-white">
                            <th>
                                <div class="flex flex-wrap items-center">
                                    <span>
                                        <text-highlight :queries="[searchKeyword]">{{ policy.policy_title }}</text-highlight> - version: {{ policy.version }} ( {{ policy.expiration_date | formatDate }} )
                                    </span>
                                    <span class="text-xs ml-2">
                                        {{ policy.lesson_count }} Lessons &amp {{ policy.company_tests_count || 0 }} Tests
                                    </span>
                                </div>
                            </th>
                            <th>
                                <div class="flex justify-end">
                                    <app-button color="blue" class="flex items-center" @click="generatePDF(policy.id)">
                                        <font-awesome-icon icon="file-pdf" />
                                        <p class="ml-2">PDF</p>
                                    </app-button>
                                    <div class="p-1 flex rounded items-center justify-center w-auto cursor-pointer hover:bg-primary-lighter"
                                         style="width: 140px;"
                                         @click="revertPolicy(policy)">
                                        <font-awesome-icon icon="redo-alt" class="mr-2" />
                                        <span>Revert Policy</span>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </template>
                    <template #tbody>
                        <tr v-for="lesson in policy.company_lessons">
                            <td>
                                <div class="flex items-center">
                                    <font-awesome-icon icon="clipboard-list" class="text-grey-lighter text-5xl my-2 mr-4" />
                                    <div>
                                        <p class="text-lg">
                                            <text-highlight :queries="[searchedKeyword]">{{ lesson.title }}</text-highlight>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="flex justify-end items-center pr-2">
                                    <router-link :to="'/admin/lesson/' + lesson.id + '/edit'">
                                        <icon-button color="secondary" icon="edit" />
                                    </router-link>
                                    <!-- <icon-button icon="trash-alt" color="danger" class="ml-2" @click="deleteLesson(lesson.id)" /> -->
                                </div>
                            </td>
                        </tr>
                    </template>
                </app-table>
            </div>

        </div>

    </app-layout>
</template>
<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import AppButton from '../../../components/ui/AppButton'
    import IconButton from '../../../components/ui/IconButton'
    import AppTable from '../../../components/ui/AppTable'

    export default {
        components: {
            AppLayout,
            AppButton,
            IconButton,
            AppTable
        },
        data() {
            return {
                policies: [],
                searchKeyword: '',
                searchedKeyword: ''
            }
        },
        methods: {
            search() {
                const data = {
                    company_id: this.$store.state.user.currentCompany.id,
                    searchKeyword: this.searchKeyword
                };
                this.$store.dispatch('getCompanyPolicies', data).then((policies) => {
                    this.policies = policies.ListPolicy;
                    this.searchedKeyword = this.searchKeyword;
                });
            },
            revertPolicy(policy) {
                const data = {
                    company_id: policy.company_id,
                    company_policy_id: policy.id
                };
                this.$store.dispatch('revertCompanyPolicies', data).then((success) => {
                    this.$fire({
                        title: "Company Policy Reverted",
                        type: "success",
                        timer: 3000
                    });
                });
            },
            generatePDF(policyId) {
                this.$store.dispatch('generarePolicyPDF', { policyId, companyId: this.$store.state.user.user.company_id }).then((download_link) => {
                    window.location.href = download_link;
                });
            },
            // deleteLesson(id) {
            //     this.$confirm("Are you sure you want to delete this lesson?").then(() => {
            //         this.$store.dispatch('deleteCompanyLesson', id).then((success) => {
            //             this.search();
            //             this.$fire({
            //                 title: "Company Lesson Deleted",
            //                 type: "success",
            //                 timer: 3000
            //             });
            //         });
            //     });
            // }
        },
        mounted() {

            this.$store.dispatch('getUser').then((user) => {
                this.search();
            });

        }
    }

</script>
