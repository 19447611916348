<template>
    <img :class="'company-logo logo-box company-position-' + position"
         :src="getImageSrc"
         :style="{ width: width + 'px', height: height + 'px' }"
         v-if="imageExists" />
     <div v-else class="text-center" :style="{ width: width + 'px' }">
         <font-awesome-icon icon="image"
                        :class="'text-grey-light mx-auto'"
                        :style="{ marginLeft: leftMargin + 'px', fontSize: height + 'px' }" />
    </div>
</template>

<script>

    export default {
        props: {
            imageSrc: String,
            position: {
                type: String,
                default: 'center'
            },
            // iconSize: {
            //     type: Number,
            //     default: 48
            // },
            width: {
                type: Number,
                default: 150
            },
            height: {
                type: Number,
                default: 60
            }
        },
        computed: {
            getImageSrc() {
                const queryString = '';
                if(this.isUrl(this.imageSrc)) {
                    return this.imageSrc + queryString;
                }
                return this.$store.state.baseUrl + '/uploads/companies/' + this.imageSrc + queryString;
            },
            imageExists() {
                if(this.imageSrc !== undefined && this.imageSrc !== 'undefined' && this.imageSrc !== null && this.imageSrc !== '') {
                    return true;
                }
                return false;
            },
            leftMargin() {
                let margin = 0;
                // margin = this.width / 2;
                return margin;
            }
        },
        methods: {
            isUrl(string) {
                var pattern = /^((http|https|ftp):\/\/)/;
                if(!pattern.test(string)) {
                    return false;
                }
                return true;
            }
        }
    }

</script>

<style lang="css" scoped>

    .company-logo {
        background: #fff;
        width: 150px;
        height: 60px;
        object-fit: contain;
    }

    .company-position-left {
        object-position: left;
    }

    .company-position-right {
        object-position: right;
    }

    .company-position-center {
        object-position: center;
    }

</style>
