import Placeholders from '../views/Placeholders'
import PlaceholdersAdd from '../views/Placeholders/Add'
import PlaceholdersEdit from '../views/Placeholders/Edit'

export default {
    routes: [
        {
            path: '/placeholders',
            name: 'Placeholders',
            component: Placeholders,
            meta: {
                allowAnonymous: false
            }
        },
        {
            path: '/placeholders/add',
            name: 'PlaceholdersAdd',
            component: PlaceholdersAdd,
            meta: {
                allowAnonymous: false
            }
        },
        {
            path: '/placeholders/:id/edit',
            name: 'PlaceholdersEdit',
            component: PlaceholdersEdit,
            meta: {
                allowAnonymous: false
            }
        }
    ]
}
