<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <bread-crumbs :links="[
                { name: 'Master Test Sets', href: '/master-test-sets' },
                { name: testSet.name + ' Exams' }
            ]" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">Master Exams for {{ testSet.name }}</h1>
                <router-link :to="'/master-test-sets/' + $route.params.id + '/exams/add'">
                    <app-button>Add New Exam</app-button>
                </router-link>
            </div>

            <div class="mb-8">
                <input class="bg-white border-b placeholder-black focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search Exams" />
                <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
            </div>

            <div :class="{ 'loading-white': examsLoading }">
                <exam-list :base_link="'/master-test-sets/' + $route.params.id + '/exams/'" :exams="exams" />
            </div>

            <!-- <div class="flex flex-wrap" :class="{ 'loading-white': examsLoading }">
                <div v-for="exam in exams" class=" w-full lg:w-60 md:mr-6 mb-6 bg-blue text-white rounded">

                    <div class="flex flex-col justify-between h-full">
                        <div class="p-4">
                            <p class="font-bold mb-2">{{ exam.exam_title }}</p>
                            <hr>
                            <div class="text-sm mt-2">
                                <p>Total Marks: {{ exam.exam_marks }}</p>
                                <p>Expiration Date: {{ exam.expiration_date | formatDate }}</p>
                            </div>
                        </div>
                        <div class="flex justify-end mt-2 bg-blue-dark p-2 rounded-b">
                            <router-link :to="'/master-test-sets/' + $route.params.id + '/exams/' + exam.id + '/edit'">
                                <icon-button icon="edit" color="blue-dark" />
                            </router-link>
                            <router-link :to="'/master-test-sets/' + $route.params.id + '/exams/' + exam.id + '/questions'">
                                <icon-button icon="eye" color="blue-dark" />
                            </router-link>
                        </div>
                    </div>

                </div>
            </div> -->

        </div>

    </app-layout>
</template>
<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import Panel from '../../../components/ui/Panel'
    import BreadCrumbs from '../../../components/ui/BreadCrumbs'
    import AppButton from '../../../components/ui/AppButton'
    import IconButton from '../../../components/ui/IconButton'
    import AppTable from '../../../components/ui/AppTable'
    import PopUpConfirm from '../../../components/PopUpConfirm'
    import ExamList from '../../../components/exams/List'

    export default {
        components: {
            AppLayout,
            Panel,
            BreadCrumbs,
            AppButton,
            IconButton,
            AppTable,
            PopUpConfirm,
            ExamList
        },
        data() {
            return {
                loading: false,
                examsLoading: false,
                searchKeyword: '',
                testSet: {
                    name: '',
                    id: null
                },
                exams: []
            }
        },
        methods: {
            search() {
                this.examsLoading = true;
                const testSetId = this.$route.params.id;
                const searchKeyword = this.searchKeyword;
                this.$store.dispatch('getExams', { testSetId, searchKeyword }).then((exams) => {
                    this.exams = exams.ListExam;
                    this.examsLoading = false;
                });
            }
        },
        mounted() {

            this.loading = true;

            const testSetId = this.$route.params.id;
            const searchKeyword = this.searchKeyword;

            Promise.all([
                this.$store.dispatch('getMasterTestSet', testSetId),
                this.$store.dispatch('getExams', { testSetId, searchKeyword })
            ]).then((data) => {
                this.testSet = data[0];
                this.exams = data[1].ListExam;
                this.loading = false;
            });

        }
    }

</script>
