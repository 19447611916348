<template lang="html">

    <div class="summer-note-editor" :id="'summernote_' + index"></div>

</template>

<script>

    import summernote from './../lib/summernote/summernote'
    import caret from './../lib/summernote/caret'

    window.Popper = require('@popperjs/core');
    window.$ = require('jquery');

    export default {
        props: {
            index: Number,
            value: String
        },
        data() {
            return {
                editor: null,
                contentEditableElement: null,
                lastRange: 0,
                caretPos: null
            }
        },
        methods: {
            setRange() {
                const range = $.summernote.range;
                $(this.contentEditableElement).caret('pos', this.caretPos);
            },
            getHtml() {
                return this.editor.summernote('code');
            },
            setHtml(markupStr) {
                this.editor.summernote('code', markupStr);
                this.setRange();
            }
        },
        watch: {
            value: function(newVal, oldVal) { // watch it
                this.setHtml(newVal);
            }
        },
        mounted() {

            let vue = this;

            vue.editor = $(vue.$el);

            let options = {
                // summernote config
                height: 300,
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'italic', 'underline', 'clear']],
                    // ['fontname', ['fontname']],
                    ['fontsize', ['fontsize']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    // ['insert', ['link', 'picture', 'video']],
                    ['view', ['fullscreen', 'codeview', 'help']],
                ],
                callbacks: {
                    onChange(contents) {
                        vue.$emit('input', contents);
                    }
                }
            };

            vue.editor.summernote(options);

            const noteEditor = $(this.editor[0]).next('.note-editor');  //
            this.contentEditableElement = noteEditor.find('.note-editable')[0];

            vue.editor.on('summernote.keydown', function(we, e) {

                const selection = window.getSelection();
                var key = event.keyCode || event.charCode;

                switch (key) {
                    case 8:
                    case 9:

                        // delete or backspace
                        if(selection.isCollapsed) {
                            vue.caretPos = $(vue.contentEditableElement).caret('pos') - 1;
                        }else{
                            // removed selection
                            vue.caretPos = $(vue.contentEditableElement).caret('pos');
                        }

                        break;
                    default:
                        vue.caretPos = $(vue.contentEditableElement).caret('pos') + 1;
                }

            });

            $(this.contentEditableElement).on('click', function(e) {
                vue.caretPos = $(vue.contentEditableElement).caret('pos');
            });

            vue.setHtml(vue.value);

        }
    }

</script>

<style lang="css">

    @import '../lib/summernote/style.css';

    .note-editor,
    .note-toolbar {
        background: #fff;
    }

    .note-editable {
        font-family: Roboto;
        font-weight: 300;
    }

    .note-editable ul {
        list-style: inside;
        list-style-type: disc;
    }

    .note-editable ol {
        list-style: inside;
        list-style-type: decimal;
    }

    .note-modal-backdrop {
        z-index: 1;
    }

</style>
