<template>
    <app-layout size="wide">

        <bread-crumbs :links="[
            { name: 'My Policy Profile', href: '/my-policy-profile' },
            { name: 'Read Policy' }
        ]" />

        <!-- <p class="text-secondary font-bold">{{ duration }}</p> -->

        <div :class="{ 'loading-white': this.loading }">

            <div class="flex flex-col mb-8">

                <div class="md:flex justify-between items-center mb-8">
                    <h1 class="hdln-1">Read Policy</h1>
                    <div class="flex justify-between items-center">
                        <!-- <app-button v-if="this.lessonIndex > 0" color="white" class="" @click="previousLesson">
                            <div class="text-primary">Previous Lesson</div>
                        </app-button>
                        <app-button v-if="this.lessonIndex < this.lessons.length - 1" color="white" class=" ml-4" @click="nextLesson">
                            <div class="text-primary">Next Lesson</div>
                        </app-button> -->
                        <router-link :to="'/my-policy-profile'">
                            <app-button color="danger" class=" ml-4">Back to Policy Profile</app-button>
                        </router-link>
                    </div>
                </div>

                <!-- <p class="mb-4 bg-secondary rounded text-white text-center p-1">Go to policy section and start test</p> -->

                <div class="md:flex">

                    <div class="md:w-1/4 md:mr-2">
                        <panel class="p-2" :class="{ 'loading-white': statLoading }">
                            <div class="bg-white p-4">
                                <percentage-circle :circumference="295" :percent="progress">
                                    <img class="w-24 h-24 object-cover rounded-full" :src="$store.getters.userProfilePicture" />
                                </percentage-circle>
                                <p class="mt-4 font-bold">{{ Math.ceil(progress) }}% Complete</p>
                            </div>
                        </panel>

                        <div>

                            <div v-for="(lesson, i) in lessons"
                                 :class="'border-l-4 px-4 ' + lessonListTextClasses(i)" @click="changeLesson(i)">
                                <div class="py-2 flex justify-between items-center cursor-pointer mb-1">
                                    <div class="py-1">{{ lesson.title }}</div>
                                    <font-awesome-icon v-if="currentLessonIcon(i)" :icon="currentLessonIcon(i)" />
                                </div>
                            </div>

                            <div v-if="progress === 100"
                                 :class="'border-l-4 px-4 bg-greenbright text-white border-greenbright-dark'" @click="startTest">
                                <div class="py-2 flex justify-between items-center cursor-pointer mb-1">
                                    <div class="py-1">Go Back &amp; Start Test</div>
                                    <font-awesome-icon icon="clipboard-list" />
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="md:w-3/4 md:ml-2">
                        <panel class="p-2 align-left font-policy font-light">
                            <p class="mb-4 text-2xl border-b-2 border-grey-lighter pb-2">Lesson Details</p>
                            <div class="lesson">
                                <div class="p-4" v-for="component in lessons[lessonIndex].components">
                                    <div v-html="component"></div>
                                </div>
                            </div>
                        </panel>

                        <div class="flex justify-end items-center">
                            <app-button v-if="this.lessonIndex > 0" color="white" class="" @click="previousLesson">
                                <div class="text-primary">Previous Lesson</div>
                            </app-button>
                            <app-button v-if="this.lessonIndex < this.lessons.length - 1" color="white" class=" ml-4" @click="nextLesson">
                                <div class="text-primary">Next Lesson</div>
                            </app-button>
                            <div v-if="progress === 100" @click="startTest">
                                <app-button color="greenbright" class="ml-2 flex items-center">
                                    <div class="mr-2">Go Back &amp; Start Test</div>
                                    <font-awesome-icon icon="clipboard-list" />
                                </app-button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../components/layout/AppLayout'
import AppButton from '../../components/ui/AppButton'
import Panel from '../../components/ui/Panel'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import PercentageCircle from '../../components/ui/PercentageCircle'

export default {
    components: {
        BreadCrumbs,
        AppLayout,
        AppButton,
        Panel,
        PercentageCircle
    },
    methods: {
        startTest() {
            this.loading = true;
            this.updateReadDuration(() => {
                this.$router.push('/my-policy-profile');
            });
        },
        lessonListTextClasses(i) {
            // let list = 'border-grey-lighter text-grey hover:text-black';
            let list = 'bg-whiteer text-grey hover:text-black border-grey-dark';
            if(this.isComplete(i)) {
                // list = 'border-secondary text-secondary hover:text-secondary-lighter';
                list = 'border-secondary-dark bg-secondary text-white hover:text-secondary-lighter';
            }
            if(this.isActive(i)) {
                // list = 'border-primary text-primary hover:text-primary-ligher';
                list = 'bg-primary text-white hover:text-primary-lighter border-primary-dark';
            }
            return list;
        },
        isActive(i) {
            if(i === this.lessonIndex) {
                return true;
            }
            return false;
        },
        isComplete(i) {
            if(this.lessons[i].read_status) {
                return true;
            }
            return false;
        },
        changeLesson(i) {
            this.loading = true;
            this.updateReadDuration(() => {
                this.lessonIndex = i;
                this.addStat();
                this.getReadDuration(() => {
                    this.stopCounter();
                    this.startCounter();
                    this.loading = false;
                });
            });
        },
        nextLesson() {
            if(this.lessonIndex < this.lessons.length - 1) {
                this.changeLesson(this.lessonIndex + 1);
                window.scrollTo(0, 0);
            }
        },
        previousLesson() {
            if(this.lessonIndex > 0) {
                this.changeLesson(this.lessonIndex - 1);
            }
        },
        currentLessonIcon(i) {

            if(this.isActive(i)) {
                return 'ellipsis-h';
            }

            if(this.isComplete(i)) {
                return 'check';
            }

            return '';

        },
        getData(done) {
            Promise.all([
                this.$store.dispatch('getCompanyLessonsByCompanyPolicy', this.$route.params.id),
                this.$store.dispatch('getCompanyMyPolicyProfile', this.$route.params.id),
            ]).then((data) => {
                this.lessons = data[0].ListLesson;
                this.userStatics = data[0].userStatics;

                for (let i = 0; i < this.userStatics.length; i++) {
                    for(let x = 0; x < this.lessons.length; x++) {
                        if (this.userStatics[i]['component_id'] == this.lessons[x]['id']) {
                            this.lessons[x]['read_status'] = true;
                        }
                    }
                }

                this.progress = data[1].log_percent;
                this.loading = false;
                if(done) {
                    done();
                }
            });
        },
        addStat() {
            const lesson = this.lessons[this.lessonIndex];
            const data = {
                company_policy_id: lesson.company_policy_id,
                component_id: lesson.id
            };
            this.$store.dispatch('addUserStatistic', data).then((data) => {
                this.getData();
            });
        },
        updateReadDuration(done) {
            const read = this.duration;
            this.$store.dispatch('updateReadDuration', {
                company_policy_id: this.currentLesson.company_policy_id,
                company_lesson_id: this.currentLesson.id,
                read_duration: read
            }).then((data) => {
                console.log(data);
                done();
            });
        },
        getReadDuration(done) {
            this.$store.dispatch('getReadDuration', {
                lessonId: this.currentLesson.id
            }).then((data) => {
                this.duration = data.read_duration;
                done();
            });
        },
        stopCounter() {
            clearInterval(this.counterInterval);
        },
        startCounter() {
            this.counterInterval = setInterval(() => {
                this.duration += 1;
            }, 1000);
        }
    },
    computed: {
        currentLesson() {
            return this.lessons[this.lessonIndex];
        }
    },
    data() {
        return {
            lessons: [{components: []}],
            userStatics: [],
            lessonIndex: 0,
            progress: 0,
            loading: false,
            statLoading: false,
            duration: 0,
            counterInterval: null
        };
    },
    created() {
        window.addEventListener('beforeunload', (event) => {
            this.updateReadDuration(() => {});
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = '';
        });
    },
    mounted() {

        this.loading = true;

        this.$store.dispatch('getUser').then(() => {

            this.getData(() => {
                this.addStat();
                this.getReadDuration(() => {

                    this.startCounter();

                });
            });

        });

    }
}

</script>
<style scoped>

    .lesson {
        text-align: left;
        height: 650px;
        overflow-y: scroll;
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid #dee2e6;
    }

    table td, table th {
        border: 1px solid #ececec;
        padding: 5px 3px;
    }

</style>
