<template lang="html">
    <!-- <div class="bread-crumbs text-primary flex items-center mb-8" :class="{ 'opacity-0': $store.state.loading }"> -->
    <div class="bread-crumbs text-primary flex items-center mb-8">
        <div v-for="(link, i) in links" class="crumb hover:text-primary-lighter">
            <router-link v-if="link.href" :to="link.href" class="mr-2">{{ link.name }}</router-link>
            <span v-else>{{ link.name }}</span>
            <span class="mr-2" icon="chevron-right" v-if="i < links.length - 1">/</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            links: Array
        },
        data() {
            return {

            };
        }
    }
</script>

<style lang="css">

    .bread-crumbs {
        font-size: 16px;
    }

</style>
