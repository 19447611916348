<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white': pageLoading }">

            <bread-crumbs :links="[
                { name: 'Master Test Sets', href: '/master-test-sets' },
                { name: testSet.name + ' Exams', href: '/master-test-sets/' + testSet.id + '/exams' },
                { name: 'Add Exam' }
            ]" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">Add Exam for {{ testSet.name }}</h1>
            </div>

            <app-form @onSubmit="submit" :class="{ 'loading-white': formLoading }" />

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import AppButton from '../../../components/ui/AppButton'
import IconButton from '../../../components/ui/IconButton'
import AppTable from '../../../components/ui/AppTable'
import PopUpConfirm from '../../../components/PopUpConfirm'
import AppForm from './AppForm'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        IconButton,
        AppTable,
        AppForm
    },
    data() {
        return {
            pageLoading: false,
            formLoading: false,
            testSet: {
                id: null,
                name: ''
            }
        }
    },
    methods: {
        submit(data) {

            this.formLoading = true;
            this.$store.dispatch('addExam', data).then((success) => {
                if(success) {

                    this.$fire({
                        title: "Exam Added",
                        text: success.message,
                        type: "success",
                        timer: 3000
                    });

                    this.formLoading = false;
                    this.$router.push('/master-test-sets/' + this.$route.params.id + '/exams');
                }
            }).catch(() => { this.formLoading = false; });

        }
    },
    mounted() {

        this.pageLoading = true;

        this.$store.dispatch('getMasterTestSet', this.$route.params.id).then((testSet) => {
            this.testSet = testSet;
            this.pageLoading = false;
        });

    }
}

</script>
