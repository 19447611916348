<template lang="html">

    <div>

        <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg">Sites</h2>
            <router-link :to="'/company/' + companyId + '/site/add'">
                <app-button>Add Site</app-button>
            </router-link>
        </div>

        <div class="relative">

            <app-table :class="{ 'loading-white' : loading }">
                <template #thead>
                    <tr class="text-white">
                        <th>Image</th>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="site in sites">
                        <td>
                            <uploaded-logo :imageSrc="site.site_logo" :width="70" :height="50" />
                        </td>
                        <td>{{ site.site_name }}</td>
                        <td>
                            <div class="flex justify-end items-center">
                                <router-link :to="'/company/' + companyId + '/site/' + site.id + '/edit'" class="mr-2">
                                    <icon-button size="sm" icon="edit" color="secondary" />
                                </router-link>
                                <icon-button size="sm" icon="trash-alt" color="danger" @click="deleteSite(site.id)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

    </div>

</template>

<script>

    import AppTable from '../../components/ui/AppTable'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import Modal from '../../components/ui/Modal'
    import UploadedLogo from '../../components/UploadedLogo'

    export default {
        components: {
            AppTable,
            AppButton,
            IconButton,
            Modal,
            UploadedLogo
        },
        props: {
            companyId: Number
        },
        methods: {
            deleteSite(siteId) {
                this.$confirm('Are you sure you want to delete this site?').then(() => {
                    const formData = new FormData();
                    formData.append('company_id', this.companyId);
                    formData.append('site_id', siteId);
                    this.$store.dispatch('companySiteDelete', formData).then((success) => {

                        this.getSites();

                        this.$fire({
                            title: "Site Deleted",
                            text: "",
                            type: "success",
                            timer: 3000
                        });

                    });
                });
            },
            getSites() {
                this.loading = true;
                this.$store.dispatch('companySiteList', this.companyId).then((sites) => {
                    this.sites = sites;
                    this.loading = false;
                }).catch(() => { this.loading = false; });
            }
        },
        data() {
            return {
                loading: false,
                sites: []
            }
        },
        mounted() {
            // this.getSites();
        }
    }
</script>

<style lang="css">
</style>
