<template lang="html">

    <div class="flex flex-wrap">
        <div v-for="exam in exams" class=" w-full lg:w-60 md:mr-6 mb-6 bg-blue text-white rounded">

            <div class="flex flex-col justify-between h-full">
                <div class="p-4">
                    <p class="font-bold mb-2">{{ exam.exam_title }}</p>
                    <hr>
                    <div class="text-sm mt-2">
                        <p>Total Marks: {{ exam.exam_marks }}</p>
                        <p>Expiration Date: {{ exam.expiration_date | formatDate }}</p>
                    </div>
                </div>
                <div class="flex justify-end mt-2 bg-blue-dark p-2 rounded-b">
                    <router-link :to="base_link + exam.id + '/edit'" v-if="$store.getters.userRole === 'super_admin'">
                        <icon-button icon="edit" color="blue-dark" />
                    </router-link>
                    <router-link :to="base_link + exam.id + '/questions'">
                        <icon-button icon="eye" color="blue-dark" />
                    </router-link>
                </div>
            </div>

        </div>
    </div>

</template>

<script>

import IconButton from '../ui/IconButton'

export default {
    components: {
        IconButton
    },
    props: {
        exams: Array,
        base_link: String
    }
}

</script>

<style lang="css">
</style>
