<template lang="html">

    <div>

        <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg">Users</h2>
            <div class="flex">
                <router-link :to="'/company/' + companyId + '/users/add'" class="text-primary hover:text-primary-lighter">
                    <app-button>Add User</app-button>
                </router-link>
                <div @click="importModalOpen = true" class="ml-4 text-primary hover:text-primary-lighter">
                    <app-button>Import Users</app-button>
                </div>
            </div>
        </div>

        <div class="relative">

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Image</th>
                        <th>Name</th>
                        <th>Job Role</th>
                        <th class="text-right">
                            <input class="bg-primary border-b placeholder-white focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search" />
                            <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
                        </th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="user in users">
                        <td>
                            <avatar size="sm" :imageSrc="(user.profile_picture ? uploadsPath + '/' + user.profile_picture : null)" />
                        </td>
                        <td>{{ user.first_name + ' ' + user.last_name }}</td>
                        <td>{{ user.job_role.job_title }}</td>
                        <td>
                            <div class="flex justify-end items-center">
                                <router-link :to="'/company/' + companyId + '/users/' + user.id + '/edit'" class="mr-2">
                                    <icon-button size="sm" icon="edit" color="secondary" />
                                </router-link>
                                <icon-button size="sm" icon="trash-alt" color="danger" @click="deleteUser(user.id)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

        <modal header="Import Users" :isOpen="importModalOpen" @closed="importModalOpen = false" :loading="$store.state.users.loading.import">

            <font-awesome-icon icon="file-import" class="text-grey text-6xl block mx-auto" />

            <div class="text-center">
                <p class="mt-6">Below shows the data for one user as a line in the CSV </br>Enter muliple lines in this format to import multiple users</p>
                <code>
                    First Name, Last Name, email, job title (2 for admin, 5 for staff), phone number, address line 1, address line 2, city, postcode, country ( GB ), status ( 1 )
                </code>
            </div>

            <label class="mt-6 block" for="csv">
                <input type="file" id="csv" class="hidden" ref="csvFile" @change="csvFileInputChanged" />
                <div class="bg-primary rounded p-2 text-white cursor-pointer">Upload CSV</div>
            </label>

        </modal>

    </div>

</template>

<script>

    import AppTable from '../../components/ui/AppTable'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import Modal from '../../components/ui/Modal'
    import UploadedLogo from '../../components/UploadedLogo'
    import Avatar from '../../components/Avatar'

    export default {
        components: {
            AppTable,
            AppButton,
            IconButton,
            Modal,
            UploadedLogo,
            Avatar
        },
        props: {
            companyId: Number
        },
        data() {
            return {
                importModalOpen: false,
                loading: false,
                uploadsPath: null,
                users: [],
                searchKeyword: ''
            }
        },
        methods: {
            csvFileInputChanged(e) {
                const file = e.target.files[0];
                let formData = new FormData();
                formData.append('csv', file);
                formData.append('companyId', this.companyId);
                this.$store.dispatch('userImport', formData).then((success) => {
                    if(success) {
                        this.getUsers();
                        this.$fire({
                            title: "Users imported",
                            text: "",
                            type: "success",
                            timer: 3000
                        });
                    }
                })
                .catch(() => {
                    this.importModalOpen = false;
                    e.target.files = null;
                });
                this.$refs.csvFile.value = null;
            },
            deleteUser(userId) {
                this.$confirm('Are you sure you want to delete this user?').then(() => {
                    const data = {companyId: this.companyId, userId: userId };
                    this.$store.dispatch('userDelete', data).then((success) => {
                        this.getUsers();
                        this.$fire({
                            title: "User Deleted",
                            text: "",
                            type: "success",
                            timer: 3000
                        });
                    });
                });
            },
            search() {
                console.log('search ', this.searchKeyword);
                this.getUsers();
            },
            getUsers() {

                this.$store.dispatch('userList', {
                    companyId: this.companyId,
                    searchKeyword: this.searchKeyword
                }).then((data) => {
                    this.users = data.users;
                    this.uploadsPath = data.uploadsPath;
                });

            }
        }
    }

</script>

<style lang="css" scoped>

    code {
        background: #e8e8e8;
        padding: 10px 16px;
        display: inline-block;
        border-radius: 5px;
        margin-top: 10px;
        font-size: 12px;
    }

</style>
