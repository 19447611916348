<template lang="html">
    <div class="prog-bar">
        <div :class="classNames"
             :style="{ width: percent + '%' }">{{ percent }}%</div>
    </div>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: 'secondary-dark'
            },
            percent: {
                type: Number,
                default: 35
            }
        },
        computed: {
            classNames() {
                let names = 'prog-bar--inner text-white text-center';
                names += ' bg-' + this.color;
                if(this.percent > 97) {
                    names += ' prog-bar--complete';
                }
                return names;
            }
        }
    }
</script>

<style lang="scss">

.prog-bar {
    width: 100%;
    height: 35px;
    border-radius: 5px 30px 30px 5px;
    padding: 5px;
    border: solid 1px #bcbcbc;
    background: #d7d7d7;
    &--inner {
        border-radius: 5px 10px 10px 5px;
        height: 25px;
        font-weight: 100;
        text-shadow: 1px 1px 0px #000;
        font-size: 13px;
    }
    &--complete {
        border-radius: 30px !important;
    }
}

</style>
