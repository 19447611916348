import axios from 'axios';

const state = {
    loading: {
        list: false
    }
};

const getters = {

};

const mutations = {
    setServiceTierLoading(state, data) {
        state.loading[data.section] = data.loading;
    }
};

const actions = {
    serviceTierList({commit, rootState}, searchKeyword) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/service-tier/list', {searchKeyword})
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                    }

                    resolve(res.data.data);
                    commit('setLoading', false);

                })
                .catch((e) => {
                    console.log('e.response ', e.response);
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    },
    serviceTierGet({commit, rootState}, id) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/service-tier/id', { id })
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(res.data.data[0]);
                    }

                    resolve(res.data.data);
                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    },
    serviceTierAdd({commit, rootState}, data) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/service-tier/create', data)
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(true);
                    }

                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
        /*

        billing_price: "43"
        description: "fsdfsdz"
        name: "dsfsd"
        no_of_users: "34"

        */
    },
    serviceTierUpdate({commit, rootState}, data) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/service-tier/update', data)
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(true);
                    }

                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    },
    serviceTierDelete({commit, rootState}, id) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/service-tier/delete', { id })
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(true);
                    }

                    commit('setServiceTierLoading', { section: 'list', loading: false });

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setServiceTierLoading', { section: 'list', loading: false });
                });
        });
    }
};

export default {
	state,
	getters,
	mutations,
	actions
}
