<template>
    <header class="bg-white p-4 flex justify-between items-center" :class="{ isHeaderOpen: this.$store.state.menuIsOpen }">
        <img :src="$store.getters.userCompanyLogo" class="logo" />
        <font-awesome-icon :icon="( this.$store.state.menuIsOpen ? 'times' : 'bars' )" class="text-black burger-icon cursor-pointer md:hidden" @click="toggleMenu" />
    </header>
</template>

<script>

    export default {
        name: 'MainHeader',
        methods: {
            toggleMenu() {
                this.$store.commit('toggleMenu');
            }
        }
    }

</script>
<style scoped>

    header {
        box-shadow: 0px 0px 10px #0000001f;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 5;
    }

    .logo {
        width: 100px;
        height: 40px;
        object-fit: contain;
        object-position: center;
    }

    .burger-icon {
        font-size: 32px;
    }

    .isHeaderOpen {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
    }

</style>
