<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Companies', href: '/companies' },
            { name: company.company_name }
        ]" />

        <div :class="{ 'loading-white' : loading }">

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">{{ company.company_name }} Details</h1>
            </div>

            <detail-panel :company="company" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">{{ company.company_name }} Management</h1>
            </div>

            <div class="company-panel">

                <tabs :headings="['Sites', 'Users', 'Policies', 'Tests', 'Process Maps']" :activeIndex="activeTab" @changed="tabChanged" />
                <div class="p-4" :class="{ hidden: (activeTab !== 0) }">

                    <sites-tab :companyId="company.id" ref="sitesTab" />

                </div>
                <div class="p-4" :class="{ hidden: (activeTab !== 1) }">

                    <users-tab :companyId="company.id" ref="usersTab" />

                </div>
                <div class="p-4" :class="{ hidden: (activeTab !== 2) }">

                    <policies-tab :companyId="company.id" ref="policiesTab" />

                </div>
                <div class="p-4" :class="{ hidden: (activeTab !== 3) }">

                    <tests-tab :companyId="company.id" ref="testsTab" />

                </div>
                <div class="p-4" :class="{ hidden: (activeTab !== 4) }">

                    <process-map-tab :companyId="company.id" ref="processMapTab" />

                </div>

            </div>

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../components/layout/AppLayout'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import Tabs from '../../components/ui/Tabs'
import DetailPanel from '../../components/companies/DetailPanel'
import SitesTab from '../../components/companies/SitesTab'
import UsersTab from '../../components/companies/UsersTab'
import PoliciesTab from '../../components/companies/PoliciesTab'
import TestsTab from '../../components/companies/TestsTab'
import ProcessMapTab from '../../components/companies/ProcessMapTab'

import helpers from '../../lib/general'

export default {
    components: {
        AppLayout,
        BreadCrumbs,
        Tabs,
        DetailPanel,
        SitesTab,
        UsersTab,
        PoliciesTab,
        TestsTab,
        ProcessMapTab
    },
    data() {
        return {
            activeTab: 0,
            company: {
                company_name: null
            },
            loading: false
        };
    },
    methods: {
        tabChanged(i) {
            this.activeTab = i;
        }
    },
    mounted() {

        const companyId = this.$route.params.id;

        this.loading = true;

        Promise.all([
            this.$store.dispatch('companiesGet', companyId)
        ])
        .then((data) => {

            this.company = data[0];

            this.$nextTick(function () {

                this.$refs.sitesTab.getSites();
                this.$refs.usersTab.getUsers();
                this.$refs.policiesTab.getPolicies();
                this.$refs.testsTab.getTests();
                this.$refs.processMapTab.getProcessMaps();

                this.loading = false;

            });

        });

    }
}

</script>
<style scoped>

.hdln-2 {
    font-size: 18px;
    background: #f55c47;
    padding: 6px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: bold;
    border-radius: 3px 3px 0px 0px;
}

.company-panel {
    box-shadow: 0px 3px 8px #dbdbdb;
    outline: solid 1px #d7d7d7;
    margin-bottom: 48px;
    position: relative;
    background: #fff;
}

.border-secondary {
    border-bottom: solid 2px #4aa96c;
}

</style>
