<template>
    <app-layout>

        <div :class="{ 'loading-white': $store.state.loading }">

            <div class="flex flex-col mb-8">
                <h1 class="hdln-1">Policy Library</h1>
                <p class="mt-4 p-2 bg-primary text-white rounded ">
                    <b>{{ totalCompanyPolicies }}</b> policies are available at your subscription level. You currently have <b>{{ assignedPolicies }}</b> policies in your policy profile.
                </p>
            </div>

            <panel class="p-4 mb-4" v-for="policy in policies">
                <div class="flex flex-col md:flex-row justify-between items-center">
                    <circle-icon v-if="policy.assign_to_user" icon="check" background="secondary" class="md:mr-4" />
                    <circle-icon v-else icon="times" background="grey-lighter" class="md:mr-4" />
                    <div class="md:text-left md:mr-auto">
                        <b>{{ policy.policy_title }}</b>
                        <p>{{ policy.company_lessons_count }} lessons</p>
                    </div>
                    <app-button v-if="policy.assign_to_user" color="danger" @click="removeFromProfile(policy)">Remove Profile</app-button>
                    <app-button v-else color="secondary" @click="addToProfile(policy)">Add To Profile</app-button>
                </div>
            </panel>

        </div>

    </app-layout>
</template>
<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import AppButton from '../../../components/ui/AppButton'
    import Panel from '../../../components/ui/Panel'
    import CircleIcon from '../../../components/ui/CircleIcon'

    export default {
        components: {
            AppLayout,
            AppButton,
            Panel,
            CircleIcon
        },
        data() {
            return {
                assignedPolicies: 0,
                totalCompanyPolicies: 0,
                policies: []
            }
        },
        methods: {
            addToProfile(policy) {
                const data = {
                    company_policy_id: policy.id,
                    user_id: this.$store.state.user.user.id
                };
                this.$store.dispatch('addPolicyToProfile', data).then((res) => {
                    this.loadPolicies();
                });
            },
            removeFromProfile(policy) {
                const data = {
                    company_policy_id: policy.id,
                    user_id: this.$store.state.user.user.id
                };
                this.$store.dispatch('removePolicyFromProfile', data).then((res) => {
                    this.loadPolicies();
                });
            },
            loadPolicies() {
                this.$store.dispatch('getCompanyPolicy').then((res) => {
                    this.policies = res.data;
                    this.assignedPolicies = res.assign_policies;
                    this.totalCompanyPolicies = res.total_company_policies;
                });
            }
        },
        mounted() {

            this.$store.dispatch('getUser').then((user) => {
                this.loadPolicies();
            });

        }
    }

</script>
