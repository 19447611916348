import ProcessMaps from '../views/ProcessMaps'
import ProcessMapsAdd from '../views/ProcessMaps/Add'
import ProcessMapsEdit from '../views/ProcessMaps/Edit'

export default {
    routes: [
        {
            path: '/process-maps',
            name: 'ProcessMaps',
            component: ProcessMaps,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/process-maps/add',
            name: 'ProcessMapsAdd',
            component: ProcessMapsAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/process-maps/:id/edit',
            name: 'ProcessMapsEdit',
            component: ProcessMapsEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        }
    ]
}
