<template>

    <label class="text-primary block mb-2">
        <slot></slot>
        <span v-if="required">*</span>
    </label>

</template>

<script>
export default {
    props: {
        required: Boolean
    }
}
</script>

<style lang="css" scoped>
</style>
