<template>
  <div class="w-full relative flex flex-col">
    <MessagesFeed :messages="messages" />
    <MessageComposer :thread="thread" @send="sendMessage" />
  </div>
</template>

<script>
import MessagesFeed from "./MessagesFeed";
import MessageComposer from "./MessageComposer";
export default {
  props: {
    messages: {
      type: Array,
    },
    thread: {
      type: Object,
    },
  },
  methods: {
    sendMessage(text) {
      if (!this.thread) {
        return;
      }
      this.$store
        .dispatch("sendMessage", { text: text, thread_id: this.thread.id })
        .then((data) => {
          this.$emit("new", data.data);
        });
    },
  },
  components: {
    MessagesFeed,
    MessageComposer,
  },
};
</script>