<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white': pageLoading }">

            <bread-crumbs :links="[
                { name: 'Master Test Sets', href: '/master-test-sets' },
                { name: testSet.name + ' Exams', href: '/master-test-sets/' + testSet.id + '/exams' },
                { name: exam.exam_title, href: '/master-test-sets/' + testSet.id + '/exams/' + exam.id + '/questions' },
                { name: 'Add Question' }
            ]" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">Add Question to "{{ exam.exam_title }}"</h1>
            </div>

            <app-form ref="questionForm" @onSubmit="submit" :class="{ 'loading-white': formLoading }" />

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../../../components/layout/AppLayout'
import Panel from '../../../../components/ui/Panel'
import BreadCrumbs from '../../../../components/ui/BreadCrumbs'
import AppButton from '../../../../components/ui/AppButton'
import IconButton from '../../../../components/ui/IconButton'
import AppTable from '../../../../components/ui/AppTable'
import AppForm from '../../../../components/exams/QuestionForm'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        IconButton,
        AppTable,
        AppForm
    },
    data() {
        return {
            pageLoading: false,
            formLoading: false,
            testSet: {
                name: '',
                id: null
            },
            exam: {
                exam_title: null,
                id: null
            }
        };
    },
    methods: {
        submit(data) {

            this.formLoading = true;
            this.$store.dispatch('addQuestion', data).then((success) => {
                if(success) {

                    this.$fire({
                        title: "Question Added",
                        text: success.message,
                        type: "success",
                        timer: 3000
                    });

                    this.formLoading = false;
                    this.$router.push('/master-test-sets/' + this.testSet.id + '/exams/' + this.exam.id + '/questions');

                }
            }).catch(() => { this.formLoading = false; });

        }
    },
    mounted() {

        this.pageLoading = true;

        Promise.all([
            this.$store.dispatch('getMasterTestSet', this.$route.params.id),
            this.$store.dispatch('getExam', this.$route.params.examId)
        ]).then((data) => {

            this.testSet = data[0];
            this.exam = data[1];

            this.pageLoading = false;

        });

    }
}

</script>
