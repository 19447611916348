<template>
    <div class="md:flex text-center">
        <div class="md:w-1/2">
            <hero />
        </div>
        <div class="md:w-1/2">
            <div class="p-4 md:h-screen flex justify-center items-center">
                <div class="w-3/5" :class="{ 'loading-white': loading }">

                    <h1 class="text-secondary text-3xl font-bold mb-8">FORGOT PASSWORD</h1>

                    <form-group icon="user-circle" label="Email ID">
                        <input class="w-full py-2 bg-white border-b-2 border-grey-dark focus:border-secondary outline-none"
                                type="email"
                                placeholder="Enter your email..."
                                v-model="email" />
                    </form-group>

                    <div class="mt-2">
                        <app-button color="secondary" class="w-full" @click="submit()">Submit</app-button>
                        <router-link to="/login" class="text-grey mt-4 block">Back to login?</router-link>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Hero from '../../components/logged-out/Hero'
    import FormGroup from '../../components/logged-out/FormGroup'
    import AppButton from '../../components/ui/AppButton'

    export default {
        components: {
            Hero,
            FormGroup,
            AppButton
        },
        data() {
    		return {
    			email: '',
    			showErrors: false,
                loading: false
    		}
    	},
        methods: {
            submit() {
                if(!this.email) {
                    this.$fire({ title: 'email required', type: 'error', timer: 3000 });
                }else{
                    this.loading = true;
                    this.$store.dispatch('forgotPassword', this.email).then((data) => {

                        this.$fire({
                            title: data.message,
                            type: 'success',
                            timer: 3000
                        });

                        this.$router.push('/login');
                        this.loading = false;
                    }).catch((e) => {
                        this.$fire({
                            title: e,
                            type: 'error',
                            timer: 3000
                        });
                        this.loading = false;
                    });
                }
            }
        }
    }
</script>

<style lang="css" scoped>
</style>
