<template>
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">{{ company.company_name }}</h1>
            </div>

            <detail-panel :company="company" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">{{ company.company_name }} Management</h1>
            </div>

            <div class="company-panel">

                <tabs :headings="['Sites', 'Users']" :activeIndex="activeTab" @changed="tabChanged" />
                <div class="p-4" :class="{ hidden: (activeTab !== 0) }">

                    <sites-tab :companyId="company.id" ref="sitesTab" />

                </div>
                <div class="p-4" :class="{ hidden: (activeTab !== 1) }">

                    <users-tab :companyId="company.id" ref="usersTab" />

                </div>

            </div>

        </div>

    </app-layout>
</template>
<script>

    import AppLayout from '../../components/layout/AppLayout'
    import DetailPanel from '../../components/companies/DetailPanel'
    import SitesTab from '../../components/companies/SitesTab'
    import UsersTab from '../../components/companies/UsersTab'
    import PoliciesTab from '../../components/companies/PoliciesTab'
    import ProcessMapTab from '../../components/companies/ProcessMapTab'
    import Modal from '../../components/ui/Modal'
    import Tabs from '../../components/ui/Tabs'

    export default {
        components: {
            AppLayout,
            DetailPanel,
            SitesTab,
            UsersTab,
            PoliciesTab,
            ProcessMapTab,
            Modal,
            Tabs
        },
        data() {
            return {
                activeTab: 0,
                loading: false,
                company: {
                    company_name: null
                }
            }
        },
        methods: {
            tabChanged(i) {
                this.activeTab = i;
            },
            getCompany(companyId, done) {
                this.$store.dispatch('companiesGet', companyId).then((company) => {
                    this.company = company;
                    done();
                });
            }
        },
        mounted() {

            this.loading = true;

            this.$store.dispatch('getUser').then((data) => {
                const companyId = this.$store.state.user.currentCompany.id;
                // const companyId = data.data.company_id;
                this.getCompany(companyId, () => {

                    this.$nextTick(function () {

                        this.$refs.sitesTab.getSites();
                        this.$refs.usersTab.getUsers();

                        this.loading = false;

                    });

                });
            });

        }
    }

</script>
<style scoped>

.hdln-2 {
    font-size: 18px;
    background: #f55c47;
    padding: 6px;
    margin-bottom: 0px;
    color: #fff;
    font-weight: bold;
    border-radius: 3px 3px 0px 0px;
}

.company-panel {
    box-shadow: 0px 3px 8px #dbdbdb;
    outline: solid 1px #d7d7d7;
    margin-bottom: 48px;
    position: relative;
    background: #fff;
}

.border-secondary {
    border-bottom: solid 2px #4aa96c;
}

</style>
