import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    placeholderList({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/lesson-placeholder/list',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    placeholderShow({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/lesson-placeholder/id',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    placeholderAdd({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/lesson-placeholder/create',
                data: {
                    placeholder_key: data.get('placeholder_key'),
                    placeholder_value: data.get('placeholder_value')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    placeholderUpdate({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/lesson-placeholder/update',
                data: {
                    id: data.get('id'),
                    placeholder_key: data.get('placeholder_key'),
                    placeholder_value: data.get('placeholder_value')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    placeholderDelete({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/lesson-placeholder/delete',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
	actions
}
