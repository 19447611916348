<template>

    <div class="py-4 overflow-y-auto app-scrollbar">

        <div class="menu-item" v-for="(link, i) in links">

            <div v-if="link.href">
                <item :link="link" />
            </div>
            <div v-else @click="$emit('toggleLink', i)">
                <item :link="link" />
                <transition name="slide">
                    <div v-if="link.open">
                        <div class="sub-item" v-for="subLink in link.subLinks">
                            <router-link class="pl-11 py-2 bg-secondary-dark sub-link block" :to="subLink.href">{{ subLink.name }}</router-link>
                        </div>
                    </div>
                </transition>
            </div>

        </div>

    </div>

</template>
<script>

    import Item from './Item'

    export default {
        name: 'Menu',
        components: {
            Item
        },
        props: {
            links: Array
        },
        data() {
            return {
                open: false
            }
        },
        methods: {
            toggleLink(i) {
                this.links[i].open = !this.links[i].open;
            }
        }
    }

</script>
<style>

.sub-link:hover {
    background: #86c733;
    /* background: #4f9769; */
}

.is-active,
.is-active:hover {
    /* background: #d5ffe5 !important;
    color: #3b8756; */
    background: #e6ffc6 !important;
    color: #496e19;
}

.slide-enter-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: ease-in;
   -webkit-transition-timing-function: ease-in;
   -o-transition-timing-function: ease-in;
   transition-timing-function: ease-in;
}

.slide-leave-active {
   -moz-transition-duration: 0.3s;
   -webkit-transition-duration: 0.3s;
   -o-transition-duration: 0.3s;
   transition-duration: 0.3s;
   -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
   transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
   max-height: 100px;
   overflow: hidden;
}

.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

</style>
