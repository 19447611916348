<template>
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Compliance Library</h1>
        </div>

        <panel :class="{ 'loading-white' : loading }">

            <div class="flex compliance-library">

                <tabs class="flex-col" :headings="tabs" :activeIndex="activeTab" @changed="tabChanged" />

                <div class="w-full">
                    <div class="p-4 flow-root" v-if="activeTab === 0">
                        <doc-section :docs="documents.audit_tools">Audit Tools</doc-section>
                    </div>
                    <div class="p-4" v-if="activeTab === 1">
                        <doc-section :docs="documents.risk_assesment">Risk Assessments</doc-section>
                    </div>
                    <div class="p-4" v-if="activeTab === 2">
                        <doc-section :docs="documents.recruitment">Recruitment/HR Templates</doc-section>
                    </div>
                    <div class="p-4" v-if="activeTab === 3">
                        <doc-section :docs="documents.clinical_care">Clinical & Care Templates</doc-section>
                    </div>
                    <div class="p-4" v-if="activeTab === 4">
                        <doc-section :docs="documents.accidents">Accidents, Incidents & Emergency Planning</doc-section>
                    </div>
                </div>

            </div>

        </panel>

    </app-layout>
</template>
<script>

import AppLayout from '../../components/layout/AppLayout'
import IconButton from '../../components/ui/IconButton'
import Panel from '../../components/ui/Panel'
import Tabs from '../../components/ui/Tabs'
import DocSection from './DocSection'

export default {
    name: 'Companies',
    components: {
        AppLayout,
        IconButton,
        Panel,
        Tabs,
        DocSection
    },
    data() {
        return {
            loading: false,
            tabs: ['Audit Tools', 'Risk Assessments', 'Recruitment/HR Templates', 'Clinical & Care Templates', 'Accidents, Incidents & Emergency Planning'],
            activeTab: 0,
            documents: {
                audit_tools: [],
                risk_assesment: [],
                recruitment: [],
                clinical_care: [],
                accidents: []
            }
        };
    },
    methods: {
        tabChanged(tabIndex) {
            this.activeTab = tabIndex;
        }
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch('getComplianceLibrary').then((documents) => {
            this.documents.audit_tools = documents.audit_tools;
            this.documents.risk_assesment = documents.risk_assesment;
            this.documents.recruitment = documents.recruitment;
            this.documents.clinical_care = documents.clinical_care;
            this.documents.accidents = documents.accidents;
            this.loading = false;
        });
    }
}

</script>
<style>

    .compliance-library .tabs {
        border-bottom: none;
        width: 220px;
        text-align: left;
        background: #d1d1d1;
        color: #294209;
    }

    .compliance-library .tab {
        border-bottom: solid 1px #b9b9b9;
        font-size: 14px;
    }

    .compliance-library .tabs .tab-active {
        /* background: #ffffff;
        color: #5c9512; */
        background: #898989;
        color: #ffffff;
        border: none;
        border-right: solid 2px #fff;
        margin-right: -2px;
    }

</style>
