<template>

    <div class="text-left">
        <slot></slot>
    </div>

</template>

<script>

export default {

}

</script>

<style lang="css" scoped>
</style>
