<template>

    <table class="w-full text-left bg-white app-table" :class="{ 'app-table-plain': this.theme === 'plain' }">
        <thead :class="theadStyles">
            <slot name="thead"></slot>
        </thead>
        <tbody>
            <slot name="tbody"></slot>
        </tbody>
    </table>

</template>

<script>

export default {
    props: {
        theme: String
    },
    computed: {
        theadStyles() {
            if(this.theme === 'plain') {
                return 'bg-white';
            }
            return 'bg-primary';
        }
    }
}

</script>

<style lang="css">

    .app-table {
        box-shadow: 0px 3px 8px #dbdbdb;
        outline: solid 1px #d7d7d7;
    }

    .app-table th {
        padding: 10px 12px;
    }

    .app-table td {
        padding: 6px 12px;
    }

    .app-table tbody tr {
        background: #fff;
        border-top: solid 1px #e6e6e6;
    }

    .app-table-plain th {
        font-weight: 500;
    }

    .app-table-small th {
        padding: 8px 8px;
    }

    /* .app-table-small th,
    .app-table-small td {
        padding: 4px 8px;
    } */

    .app-table-small td {
        font-size: 14px;
        padding: 4px 8px;
    }

    /* .app-table tbody tr:nth-child(even) {
        background: #f2f2f2;
    } */

</style>
