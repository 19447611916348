<template>
  <div
    v-if="threads"
    class="overflow-y-scroll chatscroll w-1/4 bg-light-blue rounded-br-md"
  >
    <div
      v-for="thread in threads"
      :key="thread.id"
      class="w-full flex flex-row py-4 p-2 items-baseline hover:bg-secondary transition ease-in-out delay-15 active:bg-secondary-lighter justify-center relative"
    >
      <div
        class="h-3 w-3 bg-primary rounded-full absolute top-0 left-0 m-px"
        v-if="thread.isUnread"
      ></div>
      <button @click="selectThread(thread)" class="flex-1">
        <div class="w-full">
          <div class="text-xs font-semibold">{{ getFullName(thread) }}</div>
        </div>
      </button>

      <button
        @click="deleteThread(thread)"
        class="absolute top-0 right-0 w-5 text-xs hover:text-primary"
      >
        &#x2715;
      </button>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
export default {
  props: {
    threads: {
      type: Array,
      default: [],
    },
    contact: {
      type: Object,
    },
  },
  data() {
    return {
      selected: null,
      thread: null,
    };
  },

  methods: {
    selectThread(thread) {
      if(!thread) {
        return
      }
      this.selected = thread;
      this.thread = thread;
      this.thread.isUnread = false;
      this.$emit("selected", thread);
    },
    getFullName(thread) {
      if (thread.users.length > 1 || thread.users.length == 0) {
        return thread.subject;
      }
      return thread.users[0].first_name + " " + thread.users[0].last_name;
    },
    deleteThread(thread) {
      swal({
        title: "Are you sure?",
        text:
          "This will remove you from the message thread",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((removeThread) => {
        if (!removeThread) {
          return
        }
          this.$emit("delete", thread);
      });
      return;
    },
  },
  watch: {
    contact() {
      this.selectThread(this.contact);
    },
  },
};
</script>
