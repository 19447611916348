import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

// extra routes
import serviceTiers from './serviceTiers'
import businessTypes from './businessTypes'
import companies from './companies'
import masterPolicySets from './masterPolicySets'
import masterTestSets from './masterTestSets'
import placeholders from './placeholders'
import processMaps from './processMaps'
import reports from './reports'
import companyAdmin from './companyAdmin'
import myPolicyProfile from './myPolicyProfile'
import staff from './staff'
import complianceLibrary from './complianceLibrary'

// pages
import Dashboard from '../views/Dashboard'
import Profile from '../views/Profile'
import Test from '../views/Test'

import Login from '../views/Login'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'

Vue.use(VueRouter)

function userHasRoles(roles) {
    return store.getters.userHasRoles(roles);
}

let routes = [
    {
        path: '/',
        meta: {
            allowAnonymous: false,
            loggedOut: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            allowAnonymous: true,
            loggedOut: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {
            allowAnonymous: true,
            loggedOut: true
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            allowAnonymous: true,
            loggedOut: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            allowAnonymous: false
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            allowAnonymous: false
        }
    },
    {
        path: '/test',
        name: 'Test',
        component: Test,
        meta: {
            allowAnonymous: false,
            jobRoles: ['super_admin']
        }
    },
]

routes = routes.concat(serviceTiers.routes);
routes = routes.concat(businessTypes.routes);
routes = routes.concat(companies.routes);
routes = routes.concat(masterPolicySets.routes);
routes = routes.concat(masterTestSets.routes);
routes = routes.concat(placeholders.routes);
routes = routes.concat(processMaps.routes);
routes = routes.concat(reports.routes);
routes = routes.concat(companyAdmin.routes);
routes = routes.concat(myPolicyProfile.routes);
routes = routes.concat(staff.routes);
routes = routes.concat(complianceLibrary.routes);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkExactActiveClass: 'is-active',
    routes
})

router.beforeEach((to, from, next) => {

    if(to.meta.loggedOut && store.getters.isLoggedIn) {
        router.push({
            path: '/dashboard',
            query: { redirect: to.fullPath }
        }).catch(err => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if(err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                // But print any other errors to the console
                logError(err);
            }
        });
    }

    if(to.meta.jobRoles) {
        if(!userHasRoles(to.meta.jobRoles)) {
            router.push({
                path: '/dashboard',
                query: { redirect: to.fullPath }
            }).catch(err => {
                if(err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                    logError(err);
                }
            });
        }
    }

    // not allowed for anyone && user isnt logged in
    if (!to.meta.allowAnonymous && !store.getters.isLoggedIn) {
        router.push({
            path: '/login',
            query: { redirect: to.fullPath }
        }).catch(err => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if(err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                // But print any other errors to the console
                logError(err);
            }
        });
    }else {
        next();
    }

})

export default router
