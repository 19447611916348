<template lang="html">
    <div class="bread-crumbs text-primary flex items-center mb-8" :class="{ 'opacity-0': $store.state.loading }">
        <div v-for="(link, i) in links" class="crumb hover:text-primary-lighter">
            <router-link v-if="link.href" :to="link.href" class="mr-2">{{ link.name }}</router-link>
            <span v-else>{{ link.name }}</span>
            <span class="mr-2" icon="chevron-right" v-if="i < links.length - 1">/</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            company: Object,
            page: String
        },
        computed: {
            links() {
                const isSuperAdmin = this.$store.getters.userHasRoles(['super_admin']);
                const companyLink = this.$store.getters.viewCompanyLink(this.company.id);
                if(isSuperAdmin) {
                    return [
                       { name: 'Companies', href: '/companies' },
                       { name: this.company.company_name, href: companyLink },
                       { name: this.page }
                   ];
               }else{
                   return [
                      { name: this.company.company_name, href: companyLink },
                      { name: this.page }
                  ];
               }
            }
        }
    }
</script>

<style lang="css">

    .bread-crumbs {
        font-size: 16px;
    }

</style>
