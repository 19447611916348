import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {

    getDashboardStats({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/stats',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    usersReport({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/reports/users',
                data: {
                    companyId: data.companyId
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    usersReportCsv({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/reports/users/csv',
                data: {
                    userId: data.userId,
                    companyId: data.companyId
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    userPoliciesReport({commit, rootState}, userId) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/reports/user-policies',
                data: { userId },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    userPoliciesReportCsv({commit, rootState}, userId) {
        console.log('userID ', userId);
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/reports/user-policies/csv',
                data: { userId },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    policiesReport({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/reports/policies',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    policyReport({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/reports/policy',
                data: {
                    policyId: data.policyId,
                    companyId: data.companyId
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    policyCsv({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/reports/policy/csv',
                data: { policyId: data.policyId, companyId: data.companyId },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }

    // procedureStatistics({commit, rootState}, data) {
    //     return new Promise((resolve, reject) => {
    //         apiServer.post(commit, rootState, {
    //             url: '/manager-dashboard/policy-statistics',
    //             data: {
    //                 company_id: data.companyId,
    //                 job_role: data.jobRole
    //             },
    //             success(data) { resolve(data.data) },
    //             error(err) { reject() }
    //         });
    //     });
    // },
    // policyDetailStatistics({commit, rootState}, companyId) {
    //     return new Promise((resolve, reject) => {
    //         apiServer.post(commit, rootState, {
    //             url: '/manager-dashboard/policy-detail-statistics',
    //             data: {
    //                 company_id: companyId
    //             },
    //             success(data) { resolve(data.data) },
    //             error(err) { reject() }
    //         });
    //     });
    // },
    // overallPolicyReport({commit, rootState}, data) {
    //
    // },
    // clientStaffReport({commit, rootState}, data) {
    //     return new Promise((resolve, reject) => {
    //         apiServer.post(commit, rootState, {
    //             url: '/manage-reports/get-client-staff-report',
    //             data: {
    //                 company_id: data.companyId,
    //                 job_role: data.jobRole
    //             },
    //             success(data) { resolve(data.data) },
    //             error(err) { reject() }
    //         });
    //     });
    // }
};

export default {
    actions
}
