<template>
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <bread-crumbs :links="[
                { name: 'Reports', href: '/reports' },
                { name: 'Policies for ' + company.company_name, href: '/reports/' + company.id + '/by-policy' },
                { name: policy.policy_title }
            ]" />

            <div class="flex justify-between items-center mb-8">
                <h1 class="hdln-1">{{ policy.policy_title }}</h1>
                <app-button @click="downloadCsv">Download CSV</app-button>
            </div>

            <stat-table>
                <template #thead>
                    <tr>
                        <th class="p-2">User Name</th>
                        <th class="p-2 text-center">Read Policy</th>
                        <th class="p-2 text-center">Passed Test</th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="user in report">
                        <td class="p-2">{{ user.first_name }} {{ user.last_name }}</td>
                        <td class="p-2 text-center">
                            <font-awesome-icon v-if="readPolicy(user)" icon="check" class="text-greenbright" />
                            <font-awesome-icon v-else icon="times" class="text-danger" />
                        </td>
                        <td class="p-2 text-center">
                            <font-awesome-icon v-if="user.test_passed" icon="check" class="text-greenbright" />
                            <font-awesome-icon v-else icon="times" class="text-danger" />
                        </td>
                    </tr>
                </template>
            </stat-table>

            <!-- <div class="md:flex md:flex-wrap">

                <div class="md:w-1/2" v-for="(policy, i) in policies">

                    <div :class="'p-4 rounded  bg-white mb-8 ' + ( isEven(i) ? 'mr-3' : 'ml-3' )">

                        <div class="flex justify-between items-center mb-2 pb-2 border-b-2 border-secondary">
                            <p class="text-xl">{{ policy.policy_title }}</p>
                        </div>

                        <div class="text-right mt-4">
                            <router-link :to="'/reports/' + $route.params.companyId + '/by-policy/' + policy.id">
                                <app-button color="greenbright">View Details</app-button>
                            </router-link>
                        </div>

                    </div>

                </div>

            </div> -->

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import ProgBar from '../../components/ui/ProgBar'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import AppButton from '../../components/ui/AppButton'
import StatTable from '../../components/StatTable'
import PercentageCircle from 'vue-css-percentage-circle'

export default {
    components: {
        AppLayout,
        Panel,
        ProgBar,
        BreadCrumbs,
        AppButton,
        StatTable,
        PercentageCircle
    },
    data() {
        return {
            policy: {
                policy_title: ''
            },
            report: [],
            company: {
                company_name: ''
            },
            loading: false
        };
    },
    methods: {
        readPolicy(user) {
            if(user.policyProfile) {
                if(user.policyProfile.log_percent === 100) {
                    return true;
                }
            }
            return false;
        },
        // passedTest(user) {
        //     if(user.policyProfile) {
        //         if(user.policyProfile.log_percent === 100) {
        //             return true;
        //         }
        //     }
        //     return false;
        // },
        isEven(value) {
        	if (value%2 == 0)
        		return true;
        	else
        		return false;
        },
        downloadCsv() {
            const data = {
                policyId: this.$route.params.policyId,
                companyId: this.$route.params.companyId
            };
            this.$store.dispatch('policyCsv', data).then((data) => {
                window.location.href = data.download_link;
            });
        }
    },
    mounted() {
        this.loading = true;
        Promise.all([
            this.$store.dispatch('companiesGet', this.$route.params.companyId),
            this.$store.dispatch('policyReport', { policyId: this.$route.params.policyId, companyId: this.$route.params.companyId })
        ]).then((data) => {
            this.company = data[0];

            console.log(data[1].report);

            this.policy = data[1].policy;
            this.report = data[1].report;
            this.loading = false;
        });
    }
}

</script>
