<template>
    <div v-if="showBar">

        <div class="text-center p-2 fixed w-full md:w-3/5 lg:w-4/5 right-0 company-notice">
            <div class="flex justify-center items-center" v-if="!$store.state.user.loading.company">
                <p>You're currently managing <b class="underline">{{ companyName }}</b></p>
                <select class="ml-2" @change="switchCompany" v-model="company">
                    <option value="">Switch</option>
                    <option :value="company.id" v-for="company in companies">{{ company.name }}</option>
                </select>
            </div>
            <p v-else>switching company...</p>
        </div>

    </div>
</template>
<script>

    export default {
        data() {
            return {
                listOpen: false,
                company: ''
            }
        },
        computed: {
            companyName() {
                if(this.$store.state.user.currentCompany) {
                    return this.$store.state.user.currentCompany.company_name;
                }
                return '';
            },
            companies() {
                return this.$store.state.user.companies;
            },
            showBar() {
                return this.$store.getters.showCompanyBar(this.$route.path);
            }
        },
        methods: {
            switchCompany() {
                if(this.company !== '') {
                    this.$store.commit('setCompanyLoading', true);
                    this.$store.dispatch('companiesGet', this.company).then((company) => {
                        this.$store.commit('switchUserCompany', company);

                        if(this.$route.path === '/dashboard') {
                            window.location.reload();
                        }else{
                            this.$router.push('/dashboard');
                        }

                    })
                    .catch((e) => {
                        console.log('e ', e);
                        this.$store.commit('setCompanyLoading', false);
                    });
                }
            }
        }
    }

</script>
<style>

    .companyList {
        display: none;
    }
    .companyList.listOpen {
        display: block;
    }

    .company-notice {
        background: #E85118;
        color: #fff;
        z-index: 9;
        top: 72px;
    }

    .company-notice select {
        color: #4b4b4b;
        background: #fff;
        border: solid 2px #fff;
        border-radius: 3px;
    }

    .company-button {
        border: solid 2px #fff;
        background: #fff;
        color: #333;
        padding: 4px 8px;
        margin-left: 16px;
        border-radius: 5px;
        cursor: pointer;
    }

</style>
