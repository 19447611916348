import axios from 'axios';
import apiServer from './../lib/apiServer';

const state = {
    loading: {
        policyLessons: false
    },
    modules: [
        { title: '', description: '' }
    ]
};

const mutations = {
    addLessonModule(state, module) {
        state.modules.push({ title: '', description: '' });
    },
    setModuleTitle(state, data) {
        state.modules[data.index].title = data.title;
    },
    setModuleDescription(state, data) {
        state.modules[data.index].description = data.description;
    },
    removeLessonModule(state, i) {
        state.modules.splice(i, 1);
    },
    setModules(state, modules) {
        state.modules = [];
        modules.forEach((m, i) => {
            state.modules.push({
                title: m.lesson_module_title,
                description: m.description
            });
        });
    },
    initModules(state) {
        state.modules = [
            { title: '', description: '' }
        ];
    }
};

const getters = {
    lessonModulesData(state) {
        let modules = [];
        state.modules.forEach((m, i) => {
            modules.push({
                lesson_module_title: m.title,
                lesson_module_description: m.description
            });
        });
        return modules;
    }
};

const actions = {
    lessonCreate({commit, rootState, getters}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/course-lesson/create',
                data: {
                    description: data.get('description'),
                    expected_duration: data.get('expected_duration'),
                    lessonModule: getters.lessonModulesData,
                    policy_id: data.get('policy_id'),
                    sort_order: data.get('sort_order'),
                    status: data.get('status'),
                    title: data.get('title')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    lessonUpdate({commit, rootState, getters}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/course-lesson/update',
                data: {
                    id: data.get('id'),
                    description: data.get('description'),
                    expected_duration: data.get('expected_duration'),
                    lessonModule: getters.lessonModulesData,
                    policy_id: data.get('policy_id'),
                    sort_order: data.get('sort_order'),
                    status: data.get('status'),
                    title: data.get('title')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    lessonShow({commit, rootState, getters}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/course-lesson/detail',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    lessonList({commit, rootState, getters}, policyId) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/course-lesson/lesson-list-by-policy',
                disableLoading: true,
                data: { policy_id: policyId },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    lessonDelete({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/course-lesson/delete',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
    state,
    mutations,
    getters,
    actions
}
