<template lang="html">
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Master Policy Sets', href: '/master-policy-sets' },
            { name: 'Copy Master Policy Set' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Copy Master Policy Set ( {{ policySetTitle }} )</h1>
        </div>

        <panel class="p-8 px-8 mb-8 w-full md:w-2/3" :class="{ 'loading-white': $store.state.loading }">

            <form-group class="mb-4">
                <form-label :required="true">Master Policy Set Name</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('title') && showErrors }"
                       v-model="form.title.value" />
                <validation-message :show="showErrors && !isValid('title')">name required</validation-message>
            </form-group>

            <form-group class="mb-4">
                <form-label :required="true">Description</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('description') && showErrors }"
                       v-model="form.description.value" />
                <validation-message :show="showErrors && !isValid('description')">description required</validation-message>
            </form-group>

            <div class="flex mt-8 mb-2">
                <app-button class="mr-2" @click="submit">Copy Master Policy Set</app-button>
                <router-link to="/master-policy-sets">
                    <app-button color="danger">Cancel</app-button>
                </router-link>
            </div>

        </panel>

    </app-layout>
</template>

<script>

    import AppLayout from '../../components/layout/AppLayout'
    import Panel from '../../components/ui/Panel'
    import AppButton from '../../components/ui/AppButton'
    import AppTable from '../../components/ui/AppTable'
    import IconButton from '../../components/ui/IconButton'
    import BreadCrumbs from '../../components/ui/BreadCrumbs'
    import FormGroup from '../../components/forms/FormGroup'
    import FormSection from '../../components/forms/FormSection'
    import FormLabel from '../../components/forms/FormLabel'
    import FakeCheckbox from '../../components/forms/FakeCheckbox'
    import ValidationMessage from '../../components/forms/ValidationMessage'

    import formHelpers from '../../lib/form-helpers'

    export default {
        components: {
            AppLayout,
            Panel,
            AppButton,
            AppTable,
            IconButton,
            BreadCrumbs,
            FormSection,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            ValidationMessage
        },
        data() {
            return {
                showErrors: false,
                policySetTitle: '',
                form: {
                    id: { value: null, required: true },
                    title: { value: '', required: true },
                    description: { value: '', required: true }
                }
            }
        },
        methods: {
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            submit() {

                const self = this;

                if(formHelpers.isFormValid(this.form)) {

                    const data = formHelpers.getFormData(this.form, true);
                    this.$store.dispatch('masterPolicySetDuplicate', data).then((success) => {
                        if(success) {

                            this.$fire({
                                title: "Master Policy Set Copied",
                                text: success.message,
                                type: "success",
                                timer: 3000
                            });

                            self.$router.push('/master-policy-sets');
                        }
                    });
                }else{
                    this.showErrors = true;
                }

            }
        },
        mounted() {
            this.form.id.value = this.$route.params.id;
            this.$store.dispatch('masterPolicySetGet', this.$route.params.id).then((policySet) => {
                this.policySetTitle = policySet.title;
                // this.form.title.value = 'Copy of ' + policySet.title;
                // this.form.description.value = policySet.description;
            });
        }
    }

</script>

<style lang="css">



</style>
