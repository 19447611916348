<template lang="html">
    <div :class="{ hidden: !loaded }">
        <div class="flex flex-wrap">
            <stat-box type="number" :loading="!staffEnrolledLoaded" :amount="staffEnrolled">Staff Enrolled</stat-box>
            <stat-box type="number" :loading="!numberOfCompanyPoliciesLoaded" :amount="numberOfCompanyPolicies">Number of policies the company has</stat-box>
            <stat-box type="percentage" :loading="!staffCompletedAllPoliciesLoaded" :amount="staffCompletedAllPolicies">Staff who completed all allocated policies</stat-box>
            <stat-box type="percentage" :loading="!staffCompletedAllTestsLoaded" :amount="staffCompletedAllTests">Staff who have completed all required tests</stat-box>
        </div>

    </div>
</template>

<script>

    import StatBox from './StatBox'

    export default {
        components: {
            StatBox
        },
        data() {
            return {
                loaded: false,
                // staff enrolled for the company in the system
                staffEnrolled: 0,
                staffEnrolledLoaded: false,
                // number of policies the company have
                numberOfCompanyPolicies: 0,
                numberOfCompanyPoliciesLoaded: false,
                // number of policies coming up for review in the next month
                policiesForReview: 0,
                policiesForReviewLoaded: false,
                // Percentage of Staff who have completed all allocated policies
                staffCompletedAllPolicies: 0,
                staffCompletedAllPoliciesLoaded: false,
                // Percentage of staff who have completed all required tests
                staffCompletedAllTests: 0,
                staffCompletedAllTestsLoaded: false,
                // Percentage of Policies that have been completed by all staff
                policiesCompletedByAllStaff: 0,
                policiesCompletedByAllStaffLoaded: false
            }
        },
        methods: {
            getData(done) {
                this.$store.dispatch('getAdminStatsStaffEnrolled').then((data) => {
                    this.staffEnrolled = data.staffEnrolled;
                    this.staffEnrolledLoaded = true;
                    done();
                });
                this.$store.dispatch('getAdminStatsNumberOfCompanyPolicies').then((data) => {
                    this.numberOfCompanyPolicies = data.numberOfCompanyPolicies;
                    this.numberOfCompanyPoliciesLoaded = true;
                    done();
                });
                this.$store.dispatch('getAdminStatsStaffCompletedAllPolicies').then((data) => {
                    this.staffCompletedAllPolicies = data.staffCompletedAllPolicies;
                    this.staffCompletedAllPoliciesLoaded = true;
                    done();
                });
                this.$store.dispatch('getAdminStatsStaffCompletedAllTests').then((data) => {
                    this.staffCompletedAllTests = data.staffCompletedAllTests;
                    this.staffCompletedAllTestsLoaded = true;
                    done();
                });
                this.loaded = true;
            }
        }
    }

</script>

<style lang="css">



</style>
