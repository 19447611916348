<template>
    <app-layout>

        <h1 class="hdln-1 mb-8">Relevant information to your account</h1>

        <div class="md:flex">
            <div class="md:w-1/4 md:pr-4">
                <panel class="p-4" :class="{ 'loading-white': $store.state.user.loading.image }">
                    <div class="avatar-full">
                        <img :src="this.$store.getters.userProfilePicture" />
                    </div>
                    <p class="mb-2">Change your Profile Picture</p>
                    <label for="avatar">
                        <input id="avatar" class="hidden" type="file" value="Click here to browse" @change="changeAvatar" />
                        <div class="bg-secondary cursor-pointer text-white rounded p-2">Click here to browse</div>
                    </label>
                </panel>
            </div>
            <div class="md:w-3/4 md:px-4">
                <panel :class="{ 'loading-white': $store.state.user.loading.profile }">

                    <tabs :headings="tabs" :activeIndex="activeTab" @changed="tabChanged" />

                    <div class="p-4 flow-root" v-if="activeTab === 0">
                        <personal-information-form />
                    </div>

                    <div class="p-4" v-if="activeTab === 1">
                        <change-password-form />
                    </div>

                </panel>
            </div>
        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import Tabs from '../../components/ui/Tabs'
import AppButton from '../../components/ui/AppButton'
import FormGroup from '../../components/forms/FormGroup'
import FormLabel from '../../components/forms/FormLabel'
import ValidationMessage from '../../components/forms/ValidationMessage'
import PersonalInformationForm from './PersonalInformationForm'
import ChangePasswordForm from './ChangePasswordForm'

export default {
    name: 'Dashboard',
    components: {
        AppLayout,
        Panel,
        Tabs,
        FormGroup,
        FormLabel,
        AppButton,
        ValidationMessage,
        PersonalInformationForm,
        ChangePasswordForm
    },
    data() {
        return {
            tabs: ['Personal information', 'Change Password'],
            activeTab: 0
        };
    },
    methods: {
        tabChanged(i) {
            this.activeTab = i;
        },
        changeAvatar(e) {
            const file = e.target.files[0];
            let formData = new FormData();
            formData.append('user_image', file);
            this.$store.dispatch('changeProfilePicture', formData);
        },
        // updateProfile() {
        //
        //     console.log('updateProfile');
        //
        //     let valid = true;
        //
        //     if(!this.profile.first_name.value) {
        //         valid = false;
        //     }
        //
        //     if(!this.profile.last_name.value) {
        //         valid = false;
        //     }
        //
        //     if(!this.profile.email.value) {
        //         valid = false;
        //     }
        //
        //     if(!this.profile.phone_no.value) {
        //         valid = false;
        //     }
        //
        //     if(!valid) {
        //         console.log('.....');
        //         this.profile.showErrors = true;
        //         return;
        //     }
        //
        //     this.$store.dispatch('updateProfile', this.profile);
        //
        // },
        // updatePassword() {
        //
        // }
    }
}

</script>

<style lang="css" scoped>

    .avatar-full {
        overflow: hidden;
        max-height: 180px;
        max-width: 180px;
        text-align: center;
        margin: 0px auto 10px auto;
        border-radius: 100%;
    }

    .avatar-full img {
        height: 180px;
        width: 180px;
        object-fit: cover;
        object-position: center;
        margin: 0px auto;
    }

</style>
