<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Business Types', href: '/business-types' },
            { name: 'Add Business Type' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Add Business Type</h1>
        </div>

        <panel class="p-4" :class="{ 'loading-white': $store.state.loading }">

            <form-group class="w-full md:pr-2 mb-4">
                <form-label :required="true">Business Type Name</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('title') && showErrors }"
                       v-model="form.title.value" />
                <validation-message :show="showErrors && !isValid('title')">business type name required</validation-message>
            </form-group>

            <div class="flex">
                <app-button class="mr-2" @click="submit">Save</app-button>
                <router-link to="/business-types">
                    <app-button color="danger">Cancel</app-button>
                </router-link>
            </div>

        </panel>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import AppButton from '../../components/ui/AppButton'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import FormGroup from '../../components/forms/FormGroup'
import FormLabel from '../../components/forms/FormLabel'
import ValidationMessage from '../../components/forms/ValidationMessage'

import formHelpers from '../../lib/form-helpers'

export default {
    components: {
        AppLayout,
        Panel,
        AppButton,
        BreadCrumbs,
        FormGroup,
        FormLabel,
        ValidationMessage
    },
    data() {
        return {
            showErrors: false,
            form: {
                title: { value: '', required: true }
            }
        };
    },
    methods: {
        isValid(field) {
            return formHelpers.isValid(this.form, field);
        },
        submit() {

            const self = this;

            if(formHelpers.isFormValid(this.form)) {
                const formData = formHelpers.getFormData(this.form);
                this.$store.dispatch('businessTypeAdd', formData).then((success) => {
                    if(success) {
                        self.$router.push('/business-types');
                    }
                });
            }else{
                this.showErrors = true;
            }

        }
    }
}

</script>

<style lang="css">

</style>
