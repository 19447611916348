<template lang="html">
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Manage Placeholders</h1>
            <router-link to="/placeholders/add">
                <app-button>Add Placeholder</app-button>
            </router-link>
        </div>

        <app-table :class="{ 'loading-white': $store.state.loading }">
            <template #thead>
                <tr class="text-white">
                    <th>Place Holder Key</th>
                    <th>Place Holder Value</th>
                    <th></th>
                </tr>
            </template>
            <template #tbody>
                <tr v-for="placeholder in placeholders">
                    <td>{{ placeholder.placeholder_key }}</td>
                    <td>{{ placeholder.placeholder_value }}</td>
                    <td>
                        <div class="flex justify-end items-center">
                            <router-link :to="'/placeholders/' + placeholder.id + '/edit'">
                                <icon-button icon="edit" color="secondary" />
                            </router-link>

                            <!-- <icon-button icon="trash-alt" color="danger" class="ml-2" @click="areYouSureOpen = true; currentDeleteId = placeholder.id" /> -->
                            <icon-button icon="trash-alt" color="danger" class="ml-2" @click="deletePlaceholder(placeholder.id)" />
                        </div>
                    </td>
                </tr>
            </template>
        </app-table>

    </app-layout>
</template>

<script>

    import AppLayout from '../../components/layout/AppLayout'
    import Panel from '../../components/ui/Panel'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import AppTable from '../../components/ui/AppTable'

    export default {
        components: {
            AppLayout,
            Panel,
            AppButton,
            IconButton,
            AppTable
        },
        data() {
            return {
                areYouSureOpen: false,
                placeholders: []
            }
        },
        methods: {
            deletePlaceholder(id) {
                this.$confirm("Are you sure you want to delete this placeholder?").then(() => {

                    this.$store.dispatch('placeholderDelete', id).then(() => {
                        this.loadPlaceholders();
                        this.$fire({
                            title: "Placeholder deleted",
                            text: "",
                            type: "success",
                            timer: 3000
                        });
                    });

                });
            },
            loadPlaceholders() {
                this.$store.dispatch('placeholderList').then((placeholders) => {
                    this.placeholders = placeholders.original.data;
                });
            }
        },
        mounted() {
            this.loadPlaceholders();
        }
    }

</script>

<style lang="css">



</style>
