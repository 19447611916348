<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Service Tiers', href: '/service-tiers' },
            { name: 'Update Service Tier' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Update Service Tier</h1>
        </div>

        <panel class="p-4" :class="{ 'loading-white': $store.state.loading }">

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Service Tier Name</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('name') && showErrors }"
                           v-model="form.name.value" />
                    <validation-message :show="showErrors && !isValid('name')">name required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">No of users</form-label>
                    <input type="number"
                           class="form-input"
                           :class="{ invalid: !isValid('no_of_users') && showErrors }"
                           v-model="form.no_of_users.value" />
                    <validation-message :show="showErrors && !isValid('no_of_users')">number of users required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Description</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('description') && showErrors }"
                           v-model="form.description.value" />
                    <validation-message :show="showErrors && !isValid('description')">description required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Billing Price</form-label>
                    <input type="number"
                           class="form-input"
                           :class="{ invalid: !isValid('billing_price') && showErrors }"
                           v-model="form.billing_price.value" />
                    <validation-message :show="showErrors && !isValid('billing_price')">billing price required</validation-message>
                </form-group>

            </div>

            <div class="flex">
                <app-button class="mr-2" @click="submit">Save</app-button>
                <router-link to="/service-tiers">
                    <app-button color="danger">Cancel</app-button>
                </router-link>
            </div>

        </panel>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import AppButton from '../../components/ui/AppButton'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import FormGroup from '../../components/forms/FormGroup'
import FormLabel from '../../components/forms/FormLabel'
import ValidationMessage from '../../components/forms/ValidationMessage'

import formHelpers from '../../lib/form-helpers'

export default {
    components: {
        AppLayout,
        Panel,
        AppButton,
        BreadCrumbs,
        FormGroup,
        FormLabel,
        ValidationMessage
    },
    data() {
        return {
            showErrors: false,
            form: {
                name: { value: '', required: true },
                billing_price: { value: '', required: true },
                description: { value: '', required: true },
                no_of_users: { value: '', required: true }
            }
        };
    },
    methods: {
        isValid(field) {
            return formHelpers.isValid(this.form, field);
        },
        submit() {

            const self = this;

            if(formHelpers.isFormValid(this.form)) {
                const formData = formHelpers.getFormData(this.form);
                formData.append('id', this.$route.params.id);
                this.$store.dispatch('serviceTierUpdate', formData).then((success) => {
                    if(success) {
                        self.$router.push('/service-tiers');
                    }
                });
            }else{
                this.showErrors = true;
            }

        }
    },
    mounted() {
        this.$store.dispatch('serviceTierGet', this.$route.params.id).then((serviceTier) => {
            this.form.name.value = serviceTier.name;
            this.form.billing_price.value = serviceTier.billing_price;
            this.form.description.value = serviceTier.description;
            this.form.no_of_users.value = serviceTier.no_of_users;
        });
    }
}

</script>

<style lang="css">

</style>
