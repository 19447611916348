<template>
    <div class="p-4 md:h-screen md:flex justify-center items-center bg-primary hero relative">
        <div class="text-white z-10">
            <img src="./../../assets/img/insequa-logo.png" class="mb-10" />
            <h4 class="text-2xl font-bold">Evidenced Compliance</h4>
            <p>Quality Management System</p>
        </div>
    </div>
</template>

<script>



    export default {

    }

</script>

<style lang="css" scoped>

    .hero {
        background: url('../../assets/img/login-bg.jpg') center/cover no-repeat;
    }

    .hero::after {
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba(245, 92, 71, .85);
        position: absolute;
        top: 0;
        left: 0;
    }

</style>
