<template>
    <table :class="{ 'loading-white': loading }" class="stat-table  bg-white w-full">
        <thead class="border-b-2 border-secondary-dark text-secondary-dark text-left">
            <slot name="thead"></slot>
        </thead>
        <tbody>
            <slot name="tbody"></slot>
        </tbody>
    </table>
</template>

<script>

    export default {
        props: {
            loading: Boolean
        }
    }

</script>

<style lang="css">

    .stat-table tbody tr {
        background: #fff;
    }

    .stat-table tbody tr:nth-child(odd) {
        background: #f1fae4;
    }

</style>
