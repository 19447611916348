<template>
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Test</h1>
        </div>

        <div :class="{ 'loading-white' : loading }">
            <app-button @click="send">Send Test Email</app-button>
            <p class="successMessage" v-if="sent">Test email sent successfully</p>
        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import AppButton from '../../components/ui/AppButton'

export default {
    components: {
        AppLayout,
        AppButton
    },
    data() {
        return {
            loading: false,
            sent: false
        };
    },
    methods: {
        send() {

            this.loading = true;

            this.$store.dispatch('testMail', {}).then((success) => {

                console.log(success);

                this.loading = false;

                if(success === 'ok') {
                    this.sent = true;
                }

            });

        }
    }
}

</script>

<style scoped>

    .successMessage {
        margin-top: 20px;
        color: #59bf40;
    }

</style>
