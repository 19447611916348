import MyPolicyProfile from '../views/MyPolicyProfile'
import MyPolicyProfileRead from '../views/MyPolicyProfile/Read'
import MyPolicyProfileProcessMaps from '../views/MyPolicyProfile/ProcessMaps'
import MyPolicyProfileTest from '../views/MyPolicyProfile/Test'

export default {
    routes: [
        {
            path: '/my-policy-profile',
            name: 'MyPolicyProfile',
            component: MyPolicyProfile,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin', 'staff']
            }
        },
        {
            path: '/my-policy-profile/:policyId/process-maps',
            name: 'MyPolicyProfileProcessMaps',
            component: MyPolicyProfileProcessMaps,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin', 'staff']
            }
        },
        {
            path: '/my-policy-profile/:id/read',
            name: 'MyPolicyProfileRead',
            component: MyPolicyProfileRead,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin', 'staff']
            }
        },
        {
            path: '/my-policy-profile/test/:testId',
            name: 'MyPolicyProfileTest',
            component: MyPolicyProfileTest,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin', 'staff']
            }
        }
    ]
}
