<template lang="html">
    <div class="flex justify-center items-center text-center modal-outer" :class="{ hidden: !isOpen }">

        <div class="bg-white p-4 rounded modal-inner flex flex-col justify-center">

            <div class="steps flex justify-center items-center">
                <div class="stepNumber bg-blue flex items-center justify-center">1</div>
                <div class="bar" :class="{ 'bg-blue': (currentStep > 1) }"></div>
                <div class="stepNumber flex items-center justify-center" :class="{ 'bg-blue': (currentStep > 1) }">2</div>
                <!-- <div class="bar" :class="{ 'bg-blue': (currentStep > 2) }"></div>
                <div class="stepNumber flex items-center justify-center" :class="{ 'bg-blue': (currentStep > 2) }">3</div> -->
            </div>

            <div class="p-4 my-2">

                <transition name="fade">
                    <div class="step" v-if="currentStep === 1">

                        <h2 class="text-xl font-bold mb-2">Read the instructions carefully</h2>
                        <p>
                            You will have two attempts to pass this test, if you fail on the second attempt, you will need to read the policy again.
                        </p>

                    </div>
                </transition>

                <transition name="fade">
                    <div class="step" v-if="currentStep === 2">
                        <p>Do not refresh the page or your test will automatically be submitted.</p>
                    </div>
                </transition>

                <!-- <transition name="fade">
                    <div class="step" v-if="currentStep === 3">
                        <p>Do not refresh the page otherwise your test will be automatically submitted.</p>
                    </div>
                </transition> -->

            </div>

            <div class="flex justify-center items-center">
                <app-button @click="next"
                            color="blue"
                            class="mr-4"
                            v-if="currentStep < 2">Next</app-button>
                <router-link v-else :to="'/my-policy-profile/test/' + testId">
                    <app-button color="blue"
                                class="mr-4">Start Test</app-button>
                </router-link>
                <app-button @click="close" color="grey">close</app-button>
            </div>

        </div>

    </div>
</template>

<script>

    import AppButton from './ui/AppButton'

    export default {
        components: {
            AppButton
        },
        props: {
            isOpen: Boolean,
            testId: Number
        },
        data() {
            return {
                currentStep: 1
            };
        },
        methods: {
            next() {
                if(this.currentStep < 2) {
                    this.currentStep++;
                }
            },
            close() {
                this.currentStep = 1;
                this.$emit('close');
            }
        }
    }

</script>

<style lang="css" scoped>

    .steps {

    }

    .stepNumber {
        color: #fff;
        width: 34px;
        height: 34px;
        border-radius: 100%;
        background: #c6daff;
    }

    .bar {
        width: 16px;
        height: 8px;
        background: #c6daff;
    }

    .modal-outer {
        background: rgba(0,0,0,0.65);
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 10;
        overflow: auto;
    }

    .modal-inner {
        width: 90%;
        max-width: 600px;
        min-height: 300px;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    .bg-blue {
        background: #5b8be1;
    }

</style>
