<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white': pageLoading }">

            <bread-crumbs :links="[
                { name: 'Tests', href: '/admin/tests' },
                { name: 'Test ' + exam.exam_title }
            ]" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">
                    <span>Test {{ exam.exam_title }} </span>
                    <span class="text-sm">( total marks: {{ exam.exam_marks }} )</span>
                </h1>
                <router-link :to="'/admin/tests/' + exam.id + '/questions/add'">
                    <app-button>Add New Question</app-button>
                </router-link>
            </div>

            <div class="flex flex-col md:flex-row" v-for="(question, qIndex) in questions">

                <div class="md:w-1/4">
                    <p class="font-bold mt-4">{{ question.question.replace(/(<([^>]+)>)/gi, "") }}</p>
                    <p class="text-sm text-grey">Marks ({{ question.questionMarks }})</p>
                </div>

                <div class="md:w-3/4">
                    <div class=" bg-white p-4 mb-4 rounded text-left">
                        <div class="flex justify-between mb-4">
                            <p>{{ question.question_type }}</p>
                            <div class="flex justify-between">
                                <router-link :to="'/admin/tests/' + exam.id + '/questions/' + question.id + '/edit'">
                                    <icon-button icon="edit" color="secondary" class="mr-2" />
                                </router-link>
                                <icon-button icon="trash-alt" color="danger" @click="deleteQuestion(question.id)" />
                            </div>
                        </div>
                        <div>
                            <div v-for="(answer, i) in question.questionAnswers">
                                <div class="flex" :class="{ 'mb-2': i < question.questionAnswers.length - 1 }">
                                    <fake-radio :checked="(answer.correct_answer === 1 ? true : false)" />
                                    <p class="ml-4">{{ answer.answer }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </app-layout>
</template>
<script>
//
import AppLayout from '../../../../components/layout/AppLayout'
import Panel from '../../../../components/ui/Panel'
import BreadCrumbs from '../../../../components/ui/BreadCrumbs'
import AppButton from '../../../../components/ui/AppButton'
import IconButton from '../../../../components/ui/IconButton'
import AppTable from '../../../../components/ui/AppTable'
import FormSection from '../../../../components/forms/FormSection'
import FakeRadio from '../../../../components/forms/FakeRadio'
//
export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        IconButton,
        AppTable,
        FormSection,
        FakeRadio
    },
    methods: {
        deleteQuestion(questionId) {
            this.$confirm('Are you sure you want to delete this question?').then(() => {
                this.pageLoading = true;
                this.$store.dispatch('deleteCompanyQuestion', questionId).then(() => {
                    this.$store.dispatch('companyTestQuestions', this.$route.params.examId).then((data) => {
                        this.$fire({
                            title: "Question Deleted",
                            type: "success",
                            timer: 3000
                        });
                        this.questions = data.results;
                        this.pageLoading = false;
                    }).catch(() => { this.pageLoading = false; });
                }).catch(() => { this.pageLoading = false; });
            });
        }
    },
    data() {
        return {
            pageLoading: false,
            exam: {
                exam_title: '',
                id: null
            },
            questions: []
        };
    },
    mounted() {

        this.pageLoading = true;

        Promise.all([
            this.$store.dispatch('getCompanyTest', this.$route.params.examId),
            this.$store.dispatch('companyTestQuestions', this.$route.params.examId)
        ]).then((data) => {

            this.exam = data[0];
            this.questions = data[1].results;

            this.pageLoading = false;

        });

    }
}

</script>
