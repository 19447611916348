<template lang="html">
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Master Policy Sets', href: '/master-policy-sets' },
            { name: masterPolicySet.title, href: '/master-policy-sets/' + $route.params.id + '/policies' },
            { name: 'Add Lesson' }
        ]" />

        <h1 class="hdln-1 mb-8">Add Lesson</h1>

        <div class="flex flex-col md:flex-row" :class="{ 'loading-white': $store.state.loading }">

            <div class="w-full xl:w-3/4">

                <panel class="p-4 mb-8">

                    <form-group class="w-full mb-4">
                        <form-label :required="true">Lesson Title</form-label>
                        <input type="text"
                               class="form-input"
                               :class="{ invalid: !isValid('title') && showErrors }"
                               v-model="form.title.value" />
                        <validation-message :show="showErrors && !isValid('title')">lesson title required</validation-message>
                    </form-group>

                    <form-group class="w-full mb-4">
                        <form-label :required="true">Description</form-label>
                        <textarea class="form-input"
                               :class="{ invalid: !isValid('description') && showErrors }"
                               v-model="form.description.value"></textarea>
                        <validation-message :show="showErrors && !isValid('description')">description required</validation-message>
                    </form-group>

                    <div class="flex flex-col md:flex-row">

                        <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                            <form-label :required="true">Expected Duration[Minutes]</form-label>
                            <input type="text"
                                   class="form-input"
                                   :class="{ invalid: !isValid('expected_duration') && showErrors }"
                                   v-model="form.expected_duration.value" />
                            <validation-message :show="showErrors && !isValid('expected_duration')">expected duration required</validation-message>
                        </form-group>

                        <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                            <form-label :required="true">Sort order</form-label>
                            <input type="text"
                                   class="form-input"
                                   :class="{ invalid: !isValid('sort_order') && showErrors }"
                                   v-model="form.sort_order.value" />
                            <validation-message :show="showErrors && !isValid('sort_order')">sort order required</validation-message>
                        </form-group>

                    </div>

                </panel>

                <lesson-module v-for="(module, i) in modules"
                               :module="module"
                               :index="i" />

                <app-button color="secondary" class="float-right" @click="addModule">Add Module</app-button>

                <div class="flex mt-16">
                    <app-button class="mr-2" @click="submit">Add Lesson</app-button>
                    <router-link :to="'/master-policy-sets/' + $route.params.id + '/policies'">
                        <app-button color="danger">Cancel</app-button>
                    </router-link>
                </div>

            </div>

        </div>

    </app-layout>
</template>

<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import Panel from '../../../components/ui/Panel'
    import BreadCrumbs from '../../../components/ui/BreadCrumbs'
    import AppButton from '../../../components/ui/AppButton'
    import AppTable from '../../../components/ui/AppTable'
    import FormGroup from '../../../components/forms/FormGroup'
    import FormSection from '../../../components/forms/FormSection'
    import FormLabel from '../../../components/forms/FormLabel'
    import FakeCheckbox from '../../../components/forms/FakeCheckbox'
    import ValidationMessage from '../../../components/forms/ValidationMessage'
    import LessonModule from '../../../components/LessonModule'

    import formHelpers from '../../../lib/form-helpers'

    export default {
        components: {
            AppLayout,
            Panel,
            BreadCrumbs,
            AppButton,
            AppTable,
            // IconButton,
            FormSection,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            ValidationMessage,
            LessonModule
        },
        data() {
            return {
                showErrors: false,
                masterPolicySet: {
                    id: null,
                    title: null
                },
                form: {
                    description: { value: '', required: true },
                    expected_duration: { value: '', required: true, validation: 'number' },
                    policy_id: { value: this.$route.params.policyId, required: true },
                    sort_order: { value: '', required: true, validation: 'number' },
                    status: { value: 1, required: true },
                    title: { value: '', required: true },
                }
            }
        },
        computed: {
            modules() {
                return this.$store.state.lesson.modules;
            }
        },
        methods: {
            addModule() {
                this.$store.commit('addLessonModule');
            },
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            submit() {

                if(formHelpers.isFormValid(this.form)) {

                    const data = formHelpers.getFormData(this.form, true);
                    this.$store.dispatch('lessonCreate', data).then((success) => {
                        if(success) {

                            this.$fire({
                                title: "Lesson added",
                                text: success.message,
                                type: "success",
                                timer: 3000
                            });

                            this.$router.push('/master-policy-sets/' + this.$route.params.id + '/policies');
                        }
                    });

                }else{
                    this.showErrors = true;
                }

            }
        },
        mounted() {

            this.$store.commit('initModules');

            this.$store.dispatch('masterPolicySetGet', this.$route.params.id).then((masterPolicySet) => {
                this.masterPolicySet = masterPolicySet;
            });

        }
    }

</script>

<style lang="css">

    .lesson-module {
        border: solid 2px #d1d1d1;
        border-radius: 3px;
        background: #fff;
    }

    .lesson-module--header {
        border-bottom: solid 2px #4aa96c;
    }

</style>
