<template>
    <p class="text-danger font-bold pt-1" :class="{ hidden: !show }">
        <slot></slot>
    </p>
</template>

<script>
    export default {
        props: {
            show: Boolean
        }
    }
</script>

<style lang="css">
</style>
