<template lang="html">

    <router-link :to="this.$store.getters.viewCompanyLink(companyId)">
        <app-button color="danger">Cancel</app-button>
    </router-link>

</template>

<script>

    import AppButton from '../ui/AppButton'

    export default {
        components: {
            AppButton
        },
        props: {
            companyId: Number
        }
    }

</script>

<style lang="css">
</style>
