<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white': pageLoading }">

            <bread-crumbs :links="[
                { name: 'Master Test Sets', href: '/master-test-sets' },
                { name: testSet.name + ' Exams', href: '/master-test-sets/' + testSet.id + '/exams' },
                { name: 'Exam ' + exam.exam_title }
            ]" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">Exam {{ exam.exam_title }}</h1>
                <router-link :to="'/master-test-sets/' + testSet.id + '/exams/' + exam.id + '/questions/add'">
                    <app-button>Add New Question</app-button>
                </router-link>
            </div>

            <div class="flex flex-col md:flex-row" v-for="(question, qIndex) in questions">

                <div class="md:w-1/4">
                    <p class="font-bold mt-4">{{ outputQuestion(question.question) }}</p>
                    <p class="text-sm text-grey">Marks ({{ question.questionMarks }})</p>
                </div>

                <div class="md:w-3/4">
                    <div class=" bg-white p-4 mb-4 rounded text-left">
                        <div class="flex justify-between mb-4">
                            <p>{{ question.question_type }}</p>
                            <div class="flex justify-between">
                                <router-link :to="'/master-test-sets/' + testSet.id + '/exams/' + exam.id + '/questions/' + question.id + '/edit'">
                                    <icon-button icon="edit" color="secondary" class="mr-2" />
                                </router-link>
                                <icon-button icon="trash-alt" color="danger" @click="deleteQuestion(question.id)" />
                            </div>
                        </div>
                        <div>
                            <div v-for="(answer, i) in question.questionAnswers">
                                <div class="flex" :class="{ 'mb-2': i < question.questionAnswers.length - 1 }">
                                    <fake-radio :checked="(answer.correct_answer === 1 ? true : false)" />
                                    <p class="ml-4">{{ answer.answer }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <!-- <form-section :title="question.question" >

                <div class=" bg-white p-4 mb-4 rounded text-left">
                    <p class="text-sm text-grey">{{ question.question_type }}</p>
                </div>

            </form-section> -->

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../../../components/layout/AppLayout'
import Panel from '../../../../components/ui/Panel'
import BreadCrumbs from '../../../../components/ui/BreadCrumbs'
import AppButton from '../../../../components/ui/AppButton'
import IconButton from '../../../../components/ui/IconButton'
import AppTable from '../../../../components/ui/AppTable'
import FormSection from '../../../../components/forms/FormSection'
import FakeRadio from '../../../../components/forms/FakeRadio'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        IconButton,
        AppTable,
        FormSection,
        FakeRadio
    },
    methods: {
        deleteQuestion(questionId) {
            this.$confirm('Are you sure you want to delete this question?').then(() => {
                this.pageLoading = true;
                this.$store.dispatch('deleteQuestion', questionId).then(() => {
                    this.$store.dispatch('getQuestions', this.$route.params.examId).then((data) => {
                        this.$fire({
                            title: "Question Deleted",
                            type: "success",
                            timer: 3000
                        });
                        this.questions = data.results;
                        this.pageLoading = false;
                    });
                });
            });
        },
        isHTML(str) {
            var a = document.createElement('div');
            a.innerHTML = str;
            for (var c = a.childNodes, i = c.length; i--; ) {
                if (c[i].nodeType == 1) return true;
            }
            return false;
        },
        outputQuestion(question) {

            if(this.isHTML(question)) {
                return new DOMParser().parseFromString(question, 'text/html').body.textContent;
            }else{
                return question;
            }

        }
    },
    data() {
        return {
            pageLoading: false,
            testSet: {
                name: '',
                id: null
            },
            exam: {
                exam_title: null,
                id: null
            },
            questions: []
        };
    },
    mounted() {

        this.pageLoading = true;

        Promise.all([
            this.$store.dispatch('getMasterTestSet', this.$route.params.id),
            this.$store.dispatch('getExam', this.$route.params.examId),
            this.$store.dispatch('getQuestions', this.$route.params.examId)
        ]).then((data) => {

            this.testSet = data[0];
            this.exam = data[1];
            this.questions = data[2].results;

            console.log('questions ', data[2]);

            this.pageLoading = false;

        });

    }
}

</script>
