<template>
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Manage Business Types</h1>
            <router-link to="/business-types/add">
                <app-button>Add Business Types</app-button>
            </router-link>
        </div>

        <app-table :class="{ 'loading-white': $store.state.loading }">
            <template #thead>
                <tr class="text-white">
                    <th>Name</th>
                    <th>
                        <input class="bg-primary border-b placeholder-white focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search" />
                        <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
                    </th>
                </tr>
            </template>
            <template #tbody>
                <tr v-for="businessType in businessTypes">
                    <td>{{ businessType.title }}</td>
                    <td>
                        <div class="flex justify-end items-center">
                            <router-link :to="'/business-types/edit/' + businessType.id">
                                <icon-button icon="edit" color="secondary" />
                            </router-link>
                            <icon-button icon="trash-alt" color="danger" class="ml-2" @click="areYouSureOpen = true; currentDeleteId = businessType.id" />
                        </div>
                    </td>
                </tr>
            </template>
        </app-table>

        <pop-up-confirm message="Are you sure you want to delete this business type?"
                        :isOpen="areYouSureOpen"
                        :id="currentDeleteId"
                        @canceled="areYouSureOpen = false"
                        @confirmed="deleteBusinessType" />

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import AppButton from '../../components/ui/AppButton'
import IconButton from '../../components/ui/IconButton'
import AppTable from '../../components/ui/AppTable'
import PopUpConfirm from '../../components/PopUpConfirm'

export default {
    components: {
        AppLayout,
        Panel,
        AppButton,
        IconButton,
        AppTable,
        PopUpConfirm
    },
    data() {
        return {
            businessTypes: [],
            searchKeyword: '',
            areYouSureOpen: false,
            currentDeleteId: null
        };
    },
    methods: {
        deleteBusinessType() {

            this.areYouSureOpen = false;

            this.$store.dispatch('businessTypeDelete', this.currentDeleteId).then((success) => {
                if(success) {
                    this.searchKeyword = '';
                    this.search();
                }
            });
        },
        search() {
            this.$store.dispatch('businessTypeList', this.searchKeyword).then((businessTypes) => {
                this.businessTypes = businessTypes;
            });
        }
    },
    mounted() {
        this.search();
    }
}

</script>

<style lang="css">

</style>
