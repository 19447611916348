import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    getSuperAdminStats({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/super-admin',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getAdminStatsNumberOfCompanyPolicies({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/admin/' + rootState.user.currentCompany.id + '/numberofcompanypolicies',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getAdminStatsCompleted({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/admin/' + rootState.user.currentCompany.id + '/policiescompletedbyallstaff',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getAdminStatsStaffCompletedAllTests({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/admin/' + rootState.user.currentCompany.id + '/staffcompletedalltests',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getAdminStatsStaffEnrolled({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/admin/' + rootState.user.currentCompany.id + '/staffenrolled',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getAdminStatsStaffCompletedAllPolicies({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/admin/' + rootState.user.currentCompany.id + '/staffcompletedallpolicies',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getAdminStatsPoliciesForReview({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/admin/' + rootState.user.currentCompany.id + '/policiesforreview',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getStaffStats({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/dashboard/staff',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
    actions
}
