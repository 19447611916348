import axios from 'axios';

let currentCompany = JSON.parse(sessionStorage.getItem('current_company'));
if(!currentCompany) {
    currentCompany = {
        id: null,
        company_name: null
    };
}

const state = {
    user: null,
    user_role: sessionStorage.getItem('user_role') || null,
    ip: null,
    companies: [], // objects of id, name and img
    currentCompany: currentCompany, // object of id, name and img
    // managing_company: null,
    // company_logo: null,
    // company_name: null,
    loading: {
        profile: false,
        image: false,
        company: false
    }
};

const getters = {
    userProfilePicture(state, getters, rootState) {
        if(state.user) {
            if(state.user.profile_picture) {
                return rootState.baseUrl + '/uploads/' + state.user.profile_picture + '?date=' + Date.now();
            }
        }
        return require('./../assets/img/profile-picture-placeholder.png');
    },
    userCompanyLogo(state, getters, rootState) {
        return require('./../assets/img/EC_Logo_Colour.jpg');
    },
    userName(state) {
        if(!state.user) {
            return '';
        }
        return state.user.first_name + ' ' + state.user.last_name;
    },
    userRole(state) {
        let role = 0;
        switch ((state.user ? state.user.job_title_id : 0)) {
            case 1:
                role = 'super_admin';
                break;
            case 2:
                role = 'company_admin';
                break;
            case 3:
                role = 'division_manager';
                break;
            case 4:
                role = 'site_manager';
                break;
            case 5:
                role = 'staff';
                break;
        };
        return role;
    },
    userHasRoles(state, getters) {
        return (roles) => {
            return roles.includes(getters.userRole);
        };
    },
    currentCompany(state, getters) {
        if(getters.userRole === "company_admin") {
            return state.currentCompany.id;
        }
        return state.user.company_id;
    }
}

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setUserRole(state, roleId) {
        sessionStorage.setItem('user_role', roleId);
        state.user_role = roleId;
    },
    setUserCompanies(state, companies) {
        state.companies = companies;
    },
    switchUserCompany(state, company) {
        if(company) {
            // sessionStorage.setItem('current_company_id', company.id);
            // sessionStorage.setItem('current_company_name', company.company_name);
            sessionStorage.setItem('current_company', JSON.stringify(company));
            state.currentCompany = company;
        }else{
            state.currentCompany = { id: null, company_name: '' };
        }

    },
    setProfilePicture(state, image) {
        state.user.profile_picture = image;
    },
    setProfileLoading(state, data) {
        state.loading[data.section] = data.loading;
    },
    setCompanyLoading(state, loading) {
        state.loading.company = loading;
    }
}

const actions = {
    getUser({commit, rootState, dispatch}) {

        commit('setCompanyLoading', false);
        commit('setProfileLoading', { section: 'profile', loading: true });
        commit('setError', []);

        return new Promise((resolve) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/user/logged-user')
                .then((res) => {

                    if(res.data.status) {

                        commit('setUser', res.data.data);
                        // if user is company admin
                        if(res.data.data.job_title_id === 2) {

                            commit('setUserCompanies', res.data.companies);

                            if(!rootState.user.currentCompany.id) {
                                // currentCompany is null so need to set to primary company
                                commit('switchUserCompany', res.data.primary_company);
                            }else{
                                // company not null, does state match sessionStorage?
                                if(rootState.user.currentCompany.id !== JSON.parse(sessionStorage.getItem('current_company')).id) {
                                    // it does not match so update state from sessionStorage
                                    commit('switchUserCompany', JSON.parse(sessionStorage.getItem('current_company')));
                                }
                            }
                        }

                    }else{
                        commit('setError', res.data.message);

                        if(res.data.message === 'Token is Expired') {
                            dispatch('logout')
                				.then(() => {
                                    commit('logout');
                				});
                        }

                    }

                    commit('setProfileLoading', { section: 'profile', loading: false });

                    resolve(res.data);

                })
                .catch((e) => {
                    console.log('e.response ', e.response);
                    commit('setError', e);
                    commit('setProfileLoading', { section: 'profile', loading: false });
                });

        });

    },
	changeProfilePicture({commit, rootState}, formData) {
		commit('setProfileLoading', { section: 'image', loading: true });
        commit('setError', []);
        return new Promise((resolve) => {
    		axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
    		axios.post(rootState.apiUrl + '/user/update/profile-picture', formData)
    			.then((res) => {

                    if(res.data.status) {
                        commit('setProfilePicture', res.data.data.user_image);
                    }else{
                        commit('setError', res.data.message);
                    }

                    commit('setProfileLoading', { section: 'image', loading: false });
                    resolve();

    			})
    			.catch((e) => {
    				console.log(e);
    				var eData = e.response.data;
    				commit('setError', eData);
    				commit('setProfileLoading', { section: 'image', loading: false });
    			});
        });
	},
    updateProfile({commit, rootState}, data) {
        commit('setProfileLoading', { section: 'profile', loading: true });
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
    		axios.post(rootState.apiUrl + '/user/update-profile', data)
    			.then((res) => {

                    if(res.data.status) {
                        commit('setUser', res.data.data);
                        commit('setSuccess', res.data.message);
                        resolve(res.data.message);
                    }else{
                        commit('setError', res.data.message);
                        reject();
                    }

                    commit('setProfileLoading', { section: 'profile', loading: false });

    			})
    			.catch((e) => {
    				var eData = e.response.data;
    				commit('setError', eData);
    				commit('setProfileLoading', { section: 'profile', loading: false });
    			});
        });
    },
    changePassword({commit, rootState}, data) {
        commit('setProfileLoading', { section: 'profile', loading: true });
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
    		axios.post(rootState.apiUrl + '/user/change-password', data)
    			.then((res) => {

                    if(res.data.status) {
                        commit('setSuccess', res.data.message);
                        resolve(res.data.message);
                    }else{
                        commit('setError', res.data.message);
                        reject();
                    }

                    commit('setProfileLoading', { section: 'profile', loading: false });

    			})
    			.catch((e) => {
    				var eData = e.response.data;
    				commit('setError', eData);
    				commit('setProfileLoading', { section: 'profile', loading: false });
    			});
        });
    },
    forgotPassword({rootState}, email) {
        // POST /user/forgot-password
        // data: { email }
        return new Promise((resolve, reject) => {
    		axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
    		axios.post(rootState.apiUrl + '/user/forgot-password', { email })
    			.then((res) => {
            resolve(res.data);
    			})
    			.catch((e) => {
            console.log(e);
    				reject(e);
    			});
        });
    },
    resetPassword({rootState}, data) {
        return new Promise((resolve, reject) => {
    		axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
    		axios.post(rootState.apiUrl + '/user/reset-password', data)
    			.then((res) => {

                    if(res.data.status) {
                        resolve(res.data);
                    }else{
                        reject(res.data);
                    }

    			})
    			.catch((e) => {
    				reject(e);
    			});
        });
    },
};

export default {
	state,
	getters,
	mutations,
	actions
}
