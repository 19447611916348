import Reports from '../views/Reports'
import ReportsByUser from '../views/Reports/Users';
import ReportsCompanyUser from '../views/Reports/User';
import ReportsByPolicy from '../views/Reports/Policies';
import ReportsCompanyPolicy from '../views/Reports/Policy';

import AdminReports from '../views/Admin/Reports';
import AdminReportsPolicies from '../views/Admin/Reports/Policies';
import AdminReportsPoliciesView from '../views/Admin/Reports/Policy';
import AdminReportsUsers from '../views/Admin/Reports/Users';
import AdminReportsUsersView from '../views/Admin/Reports/User';

export default {
    routes: [
        // super_admin
        {
            path: '/reports',
            name: 'Reports',
            component: Reports,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/reports/:companyId/by-user',
            name: 'ReportsByUser',
            component: ReportsByUser,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/reports/:companyId/by-user/:userId',
            name: 'ReportsCompanyUser',
            component: ReportsCompanyUser,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/reports/:companyId/by-policy',
            name: 'ReportsByPolicy',
            component: ReportsByPolicy,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/reports/:companyId/by-policy/:policyId',
            name: 'ReportsCompanyPolicy',
            component: ReportsCompanyPolicy,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },


        // {
        //     path: '/reports/companies/:companyId/:userId',
        //     name: 'ReportsCompanyUsers',
        //     component: ReportsCompanyUsers,
        //     meta: {
        //         allowAnonymous: false,
        //         jobRoles: ['super_admin']
        //     }
        // },
        // company_admin
        {
            path: '/admin/reports',
            name: 'AdminReports',
            component: AdminReports,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/reports/policies',
            name: 'AdminReportsPolicies',
            component: AdminReportsPolicies,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/reports/policies/:policyId',
            name: 'AdminReportsPoliciesView',
            component: AdminReportsPoliciesView,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/reports/users',
            name: 'AdminReportsUsers',
            component: AdminReportsUsers,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/reports/users/:id',
            name: 'AdminReportsUsersView',
            component: AdminReportsUsersView,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        }
        // {
        //     path: '/reports/procedure-statistics',
        //     name: 'ProcedureStatistics',
        //     component: ProcedureStatistics,
        //     meta: {
        //         allowAnonymous: false,
        //         jobRoles: ['company_admin', 'division_manager', 'site_manager']
        //     }
        // },
        // {
        //     path: '/reports/policy-details',
        //     name: 'PolicyDetails',
        //     component: PolicyDetails,
        //     meta: {
        //         allowAnonymous: false,
        //         jobRoles: ['company_admin', 'division_manager', 'site_manager']
        //     }
        // },
        // {
        //     path: '/reports/user-statistics',
        //     name: 'UserStatistics',
        //     component: UserStatistics,
        //     meta: {
        //         allowAnonymous: false,
        //         jobRoles: ['company_admin', 'division_manager', 'site_manager']
        //     }
        // },
        // {
        //     path: '/reports/overall-report',
        //     name: 'OverallReport',
        //     component: OverallReport,
        //     meta: {
        //         allowAnonymous: false,
        //         jobRoles: ['company_admin', 'division_manager', 'site_manager']
        //     }
        // },
        // {
        //     path: '/reports/client-staff-report',
        //     name: 'ClientStaffReport',
        //     component: ClientStaffReport,
        //     meta: {
        //         allowAnonymous: false,
        //         jobRoles: ['super_admin']
        //     }
        // }
    ]
}
