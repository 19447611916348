<template>
    <div class="mb-4  rounded-t">

        <div class="bg-primary flex justify-between items-center p-2 pl-4 rounded-t">
            <p class="text-white">Module</p>
            <icon-button icon="edit" class="ml-auto mr-2" @click="editOpen = true" />
            <icon-button icon="trash-alt" @click="deleteClicked" />
        </div>

        <div class="p-4 bg-white">
            <div :class="{ hidden: ( !title && !content ) }">
                <form-group>
                    <form-label>Title</form-label>
                    <p>{{ title }}</p>
                </form-group>
                <hr class="my-4 text-grey-lighter" />
                <form-group>
                    <div class="flex justify-between mb-2">
                        <form-label>Description</form-label>
                    </div>
                    <div class="module-preview--description font-policy font-light">
                        <div v-html="content" />
                    </div>
                </form-group>
            </div>
            <div v-if="!title && !content">
                <p class="text-center">No content</p>
            </div>
        </div>

        <modal header="Edit Module"
               :isOpen="editOpen"
               @closed="editOpen = false"
               @onSave="save">

               <form-group>
                   <form-label>Title</form-label>
                   <input type="text" class="form-input" v-model="title" />
               </form-group>

               <hr class="my-4 text-grey-lighter" />

               <summer-note-editor :index="index" :value="content" ref="summerNote" />

        </modal>

    </div>
</template>

<script>

    import IconButton from './ui/IconButton'
    import FormGroup from './forms/FormGroup'
    import FormLabel from './forms/FormLabel'
    import Modal from './policies/Modal'

    import SummerNoteEditor from './SummerNoteEditor'

    export default {
        components: {
            IconButton,
            FormGroup,
            FormLabel,
            Modal,
            SummerNoteEditor
        },
        props: {
            index: Number,
            module: Object
        },
        data() {
            return {
                editOpen: false
            };
        },
        computed: {
            title: {
                get() {
                    return this.module.title;
                },
                set(value) {
                    this.$store.commit('setModuleTitle', {
                        index: this.index,
                        title: value
                    });
                }
            },
            content() {
                return this.module.description;
            }
        },
        methods: {
            save() {

                const content = this.$refs.summerNote.getHtml();

                this.$store.commit('setModuleDescription', {
                    index: this.index,
                    description: content
                });
                this.editOpen = false;

            },
            deleteClicked() {
                this.$store.commit('removeLessonModule', this.index);
            }
        }
    }

</script>

<style lang="css">

    .module-preview--description {
        height: 250px;
        overflow-y: scroll;
        box-shadow: inset 3px 3px 8px #e3e3e3;
        border: solid 1px #c7c7c7;
        border-radius: 3px;
        padding: 10px;
    }

    .edit-modal {
        position: fixed;
        background: rgba(0,0,0,0.6);
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        overflow-y: scroll;
    }

    .edit-modal .inner {
        background: #fff;
        margin: 100px auto 100px auto;
        width: 90%;
        max-width: 800px;
    }

</style>
