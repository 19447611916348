import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    getExam({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/exam/detail',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getExams({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/get-exam-list-by-test-set',
                data: {
                    id: data.testSetId,
                    searchKeyword: data.searchKeyword
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getExamByPolicySet({commit, rootState}, policy_set_id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/get-exams-by-policySet',
                data: { policy_set_id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    addExam({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/exam/create',
                data: {
                    test_set_id: data.test_set_id,
                    exam_title: data.exam_title,
                    policy_id: data.policy_id,
                    exam_description: data.exam_description,
                    expected_duration: data.expected_duration,
                    expiration_date: data.expiration_date,
                    exam_marks: data.exam_marks,
                    pass_score: data.pass_score
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    updateExam({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/exam/update',
                data: {
                    id: data.id,
                    test_set_id: data.test_set_id,
                    exam_title: data.exam_title,
                    policy_id: data.policy_id,
                    exam_description: data.exam_description,
                    expected_duration: data.expected_duration,
                    expiration_date: data.expiration_date,
                    exam_marks: data.exam_marks,
                    pass_score: data.pass_score
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    generateExams({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-policy/generate-exam',
                data: {
                    company_id: data.companyId,
                    exam_ids: data.examIds
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getQuestions({commit, rootState}, examId) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/question/list',
                data: {
                    exam_id: examId
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getQuestion({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/question/detail',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    addQuestion({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/question/create',
                data: {
                    exam_id: data.exam_id,
                    question: [{
                        question_type: data.question_type,
                        question: data.question,
                        questionMarks: data.question_marks,
                        questionAnswers: data.answers
                    }]
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    updateQuestion({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/question/update',
                data: {
                    id: data.id,
                    exam_id: data.exam_id,
                    question_type: data.question_type,
                    question: data.question,
                    questionMarks: data.question_marks,
                    questionAnswers: data.answers
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    deleteQuestion({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/question/delete',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
    actions
}
