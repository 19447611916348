<template>
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <bread-crumbs :links="[
                { name: 'Reports', href: '/reports' },
                { name: 'Users', href: '/reports/' + this.$route.params.companyId + '/by-user' },
                { name: userName }
            ]" />

            <div class="flex justify-between items-center mb-8">
                <h1 class="hdln-1">Statistics for {{ userName }}</h1>
                <app-button @click="downloadCsv">Download CSV</app-button>
            </div>

            <policy-stats :stat="policy" v-for="policy in policies" />

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import AppButton from '../../components/ui/AppButton'
import StatTable from '../../components/StatTable'
import PolicyStats from '../../components/reports/PolicyStats'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        StatTable,
        PolicyStats
    },
    computed: {
        userName() {
            return this.user.first_name + ' ' + this.user.last_name;
        }
    },
    data() {
        return {
            user: {
                first_name: '',
                last_name: ''
            },
            policies: [],
            loading: false
        };
    },
    methods: {
        downloadCsv() {
            this.$store.dispatch('userPoliciesReportCsv', this.$route.params.userId).then((data) => {
                window.location.href = data.download_link;
            });
        },
    },
    mounted() {

        this.loading = true;

        const data = {
            userId: this.$route.params.userId,
            companyId: this.$route.params.companyId
        };

        Promise.all([
            this.$store.dispatch('userGet', data),
            this.$store.dispatch('userPoliciesReport', this.$route.params.userId)
        ]).then((data) => {

            this.user = data[0].user;
            this.policies = data[1].policies;

            // this.$store.dispatch('userPoliciesReport', this.$route.params.id).then((data) => {
            //     this.user = data.user;
            //     this.policies = data.policies;
            //     this.loading = false;
            // });
            this.loading = false;
        });



    }
}

</script>
