<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Companies', href: '/companies' },
            { name: company.company_name, href: '/companies/view/' + company.id },
            { name: 'Company Tests' }
        ]" />

        <div :class="{ 'loading-white' : loading }">

            <div class="flex justify-between items-center">
                <h1 class="hdln-1 mb-8">Company Tests</h1>
                <div class="flex justify-between items-center">
                    <app-button color="secondary" @click="generateTests">Generate Tests</app-button>
                    <router-link :to="'/companies/view/' + company.id">
                        <app-button color="danger" class="ml-4">Back</app-button>
                    </router-link>
                </div>
            </div>

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Tests</th>
                        <th>
                            <div class="flex justify-end cursor-pointer">
                                <fake-checkbox id="tests_all" :checked="isAllSelected" @changed="toggleSelectAll" :is_light="true">
                                    Select All
                                </fake-checkbox>
                            </div>
                        </th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="test in tests">
                        <td>{{ test.exam_title }}</td>
                        <td>
                            <div class="flex justify-end">
                                <fake-checkbox :id="'test_' + test.id" :checked="isSelected(test.id)" @changed="toggleTest(test.id)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import Modal from '../../../components/ui/Modal'
import AppButton from '../../../components/ui/AppButton'
import IconButton from '../../../components/ui/IconButton'
import AppTable from '../../../components/ui/AppTable'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import FakeCheckbox from '../../../components/forms/FakeCheckbox'
import FormGroup from '../../../components/forms/FormGroup'
import FormLabel from '../../../components/forms/FormLabel'
import ValidationMessage from '../../../components/forms/ValidationMessage'

import helpers from '../../../lib/general'

export default {
    components: {
        AppLayout,
        Panel,
        Modal,
        AppButton,
        IconButton,
        AppTable,
        BreadCrumbs,
        FakeCheckbox,
        FormGroup,
        FormLabel,
        ValidationMessage
    },
    data() {
        return {
            loading: false,
            company: {
                company_name: null
            },
            tests: [],
            selectedTests: []
        };
    },
    computed: {
        isAllSelected() {
            return (this.selectedTests.length === this.tests.length);
        }
    },
    methods: {
        toggleTest(id) {
            if(this.isSelected(id)) {
                var index = this.selectedTests.indexOf(id);
                if (index !== -1) {
                    this.selectedTests.splice(index, 1);
                }
            }else{
                this.selectedTests.push(id);
            }
        },
        isSelected(id) {
            return this.selectedTests.includes(id);
        },
        toggleSelectAll() {
            if(this.isAllSelected) {
                this.deselectAll();
            }else{
                this.selectAll();
            }
        },
        selectAll() {
            this.selectedTests = [];
            this.tests.forEach((t, i) => {
                this.selectedTests.push(t.id);
            });
        },
        deselectAll() {
            this.selectedTests = [];
        },
        generateTests() {

            if(this.selectedTests.length > 0) {
                this.loading = true;
                const data = {
                    companyId: this.$route.params.companyId,
                    examIds: JSON.stringify(this.selectedTests)
                };
                this.$store.dispatch('generateExams', data).then((data) => {
                    this.$fire({
                        title: "Tests Generated",
                        text: "",
                        type: "success",
                        timer: 3000
                    });
                    this.$router.push('/companies/view/' + this.$route.params.companyId);
                    this.loading = false;
                });
            }else{
                this.$store.commit('setError', 'Select at least one test to generate');
            }

        }
    },
    mounted() {

        this.loading = true;

        this.$store.dispatch('companiesGet', this.$route.params.companyId).then((company) => {
            this.company = company;
            this.$store.dispatch('getExamByPolicySet', company.master_policies_set_id).then((data) => {
                console.log('exams ', data);
                if(data.exams) {
                    this.tests = data.exams;
                }
                this.loading = false;
            });
        });

    }
}

</script>
