<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Reports', href: '/admin/reports' },
            { name: 'Policies' }
        ]" />

        <h1 class="hdln-1 mb-8">Policies</h1>

        <div class="md:flex md:flex-wrap" :class="{ 'loading-white' : loading }">

            <div class="md:w-1/2" v-for="(policy, i) in policies">

                <div :class="'p-4 rounded  bg-white mb-8 ' + ( isEven(i) ? 'mr-3' : 'ml-3' )">

                    <div class="flex justify-between items-center mb-2 pb-2 border-b-2 border-secondary">
                        <p class="text-xl">{{ policy.policy_title }}</p>
                    </div>

                    <div class="text-right mt-4">
                        <router-link :to="'/admin/reports/policies/' + policy.id">
                            <app-button color="greenbright">View Details</app-button>
                        </router-link>
                    </div>

                </div>

            </div>

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import AppButton from '../../../components/ui/AppButton'
import StatTable from '../../../components/StatTable'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        StatTable
    },
    data() {
        return {
            policies: [],
            company: {
                company_name: ''
            },
            loading: false
        };
    },
    methods: {
        isEven(value) {
        	if (value%2 == 0)
        		return true;
        	else
        		return false;
        }
    },
    mounted() {
        this.loading = true;

        this.$store.dispatch('getUser').then((data) => {

            const companyId = this.$store.state.user.currentCompany.id; // data.data.company_id;

            Promise.all([
                this.$store.dispatch('companiesGet', companyId),
                this.$store.dispatch('getCompanyPolicies', { company_id: companyId })
            ]).then((data) => {
                this.company = data[0];
                this.policies = data[1].ListPolicy;
                this.loading = false;
            });

        });
    }
}

</script>
