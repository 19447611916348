<template>
  <div class="bg-white">
    <main-header />

    <company-drop-down />

    <div
      class="flex justify-end app-container"
      :class="{
        'app-user-admin': $store.getters.showCompanyBar(this.$route.path),
      }"
    >
      <side-nav />

      <main
        class="w-full md:w-3/5 lg:w-4/5"
        :class="{ 'loading-white': $store.state.user.loading.company }"
      >
        <slot name="topnav"></slot>
        <div class="py-8 px-4" :class="getSizeClasses">
          <slot></slot>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import MainHeader from "../../components/layout/MainHeader";
import SideNav from "../../components/layout/SideNav/SideNav";
import CompanyDropDown from "../../components/layout/SideNav/CompanyDropDown";

export default {
  data: function() {
    return {
      events: ["click", "mousemove", "scroll", "keypress","ontouchstart", "load"],
      logoutTimer: null,
      logoutTime: process.env.VUE_APP_LOGOUT_TIMEOUT,
    };
  },
    props: {
    size: {
      type: String,
      default: "normal", // can be wide
    },
  },


  methods: {
    resetTimer: function() {
      clearTimeout(this.logoutTimer);
      this.startLogoutTimer();
    },
    startLogoutTimer: function() {
      this.logoutTimer = setTimeout(this.autoLogout, this.logoutTime * 60 * 1000);
    },
    autoLogout: function() {
      clearTimeout(this.logoutTimer);
      this.$store.dispatch("logout").then(() => {
        this.$store.commit("logout");
        this.$store.commit("setError", []);
        this.$router.push("/login");
      });
    },
  },


  computed: {
    getSizeClasses() {
      if (this.size === "wide") {
        return "container-full lg:px-10";
      }
      return "container lg:px-20";
    },
  },
    beforeDestroy() {
    clearTimeout(this.logoutTimer);
    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimer);
    });
  },
  mounted() {
    this.$store.dispatch("getUser");
    this.events.forEach((event) => {
      window.addEventListener(event, this.resetTimer);
    });
    this.startLogoutTimer();
  },
    components: {
    MainHeader,
    SideNav,
    CompanyDropDown,
  },
};
</script>

<style lang="css" scoped>
main {
    padding-top: 72px;
}

.container {
    width: 90%;
    max-width: 1200px;
    margin: 0px auto;
}

.container-full {
    width: 100%;
}

.app-container {
    min-height: calc(100vh - 72px);
}

.app-container main {
    padding-bottom: 160px;
}

.app-user-admin {
    padding-top: 52px;
}
</style>
