<template>

    <div class="tabs flex border-grey-light">
        <div v-for="(tab, i) in headings"
             class="tab"
             @click="$emit('changed', i)"
             :class="{ 'tab-active': activeIndex === i }">{{ tab }}</div>
    </div>

</template>

<script>

export default {
    props: {
        headings: Array,
        activeIndex: Number
    }
}

</script>

<style lang="css" scoped>

    .tabs {
        border-style: solid;
        border-bottom-width: 2px;
    }

    .tab {
        padding: 12px;
        cursor: pointer;
    }

    .tab:hover {
        color: #5e5e5e;
    }

    .tab-active,
    .tab-active:hover {
        /* border-bottom: solid 2px #4aa96c;
        margin-bottom: -2px;
        color: #4aa96c;
        font-weight: bold; */
        border-bottom: solid 2px #5c9513;
        margin-bottom: -2px;
        color: #5c9513;
        font-weight: bold;
    }

</style>
