<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Master Policy Sets', href: '/master-policy-sets' },
            { name: masterPolicySet.title, href: '/master-policy-sets/' + $route.params.id + '/policies' },
            { name: 'Edit Policy' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Edit Policy for {{ masterPolicySet.title }}</h1>
        </div>

        <panel class="p-4" :class="{ 'loading-white': $store.state.loading }">

            <div class="md:flex">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Policy Title</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('policy_title') && showErrors }"
                           v-model="form.policy_title.value" />
                    <validation-message :show="showErrors && !isValid('policy_title')">policy title required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="true">Description</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('description') && showErrors }"
                           v-model="form.description.value" />
                    <validation-message :show="showErrors && !isValid('description')">description required</validation-message>
                </form-group>

            </div>

            <div class="md:flex">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Version</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('version') && showErrors }"
                           v-model="form.version.value" />
                    <validation-message :show="showErrors && !isValid('version')">version required ( must be a number )</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="true">Expiration Date</form-label>
                    <datepicker :value="form.expiration_date.value"
                                name="expiration_date"
                                :class="{ invalid: !isValid('expiration_date') && showErrors }"
                                format="d-MM-yy"
                                @selected="expirationDateChoose"></datepicker>
                    <validation-message :show="showErrors && !isValid('expiration_date')">expiration date required</validation-message>
                </form-group>

            </div>

            <div class="flex">
                <app-button class="mr-2" @click="submit">Update Policy</app-button>
                <router-link :to="'/master-policy-sets/' + $route.params.id + '/policies'">
                    <app-button color="danger">Cancel</app-button>
                </router-link>
            </div>

        </panel>

    </app-layout>
</template>
<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import AppButton from '../../components/ui/AppButton'
import FormGroup from '../../components/forms/FormGroup'
import FormLabel from '../../components/forms/FormLabel'
import ValidationMessage from '../../components/forms/ValidationMessage'
import Datepicker from 'vuejs-datepicker';

import formHelpers from '../../lib/form-helpers'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        FormGroup,
        FormLabel,
        ValidationMessage,
        Datepicker
    },
    data() {
        return {
            today: new Date(),
            masterPolicySet: {
                id: null,
                title: ''
            },
            showErrors: false,
            form: {
                id: { value: this.$route.params.policyId, require: true },
                policy_title: { value: '', required: true },
                description: { value: '', required: true },
                version: { value: '', required: true, validation: 'number' },
                policy_set_id: { value: this.$route.params.id, required: true },
                expiration_date: { value: '', required: true, validation: 'date' }
            }
        };
    },
    methods: {
        expirationDateChoose(e) {
            const day = e.getDate();
            const month = e.getMonth() + 1;
            const year = e.getFullYear();
            this.form.expiration_date.value = year + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day);
        },
        isValid(field) {
            return formHelpers.isValid(this.form, field);
        },
        submit() {

            if(formHelpers.isFormValid(this.form)) {
                const formData = formHelpers.getFormData(this.form);
                this.$store.dispatch('masterPolicyUpdate', formData).then((success) => {
                    if(success) {
                        this.$fire({
                            title: "Policy Updated",
                            text: success.message,
                            type: "success",
                            timer: 3000
                        });
                        this.$router.push('/master-policy-sets/' + this.$route.params.id + '/policies');
                    }
                });
            }else{
                this.showErrors = true;
            }

        }
    },
    mounted() {

        Promise.all([
            this.$store.dispatch('masterPolicySetGet', this.$route.params.id),
            this.$store.dispatch('masterPolicyGet', this.$route.params.policyId)
        ]).then((data) => {

            const policy = data[1];
            this.masterPolicySet = data[0];
            this.form.policy_title.value = policy.policy_title;
            this.form.description.value = policy.description;
            this.form.version.value = policy.version;
            this.form.expiration_date.value = policy.expiration_date;

        });

    }
}

</script>
