import ServiceTiers from '../views/ServiceTiers'
import ServiceTiersAdd from '../views/ServiceTiers/Add'
import ServiceTiersEdit from '../views/ServiceTiers/Edit'

export default {
    routes: [
        {
            path: '/service-tiers',
            name: 'ServiceTiers',
            component: ServiceTiers,
            meta: {
                allowAnonymous: false
            }
        },
        {
            path: '/service-tiers/add',
            name: 'ServiceTiersAdd',
            component: ServiceTiersAdd,
            meta: {
                allowAnonymous: false
            }
        },
        {
            path: '/service-tiers/edit/:id',
            name: 'ServiceTiersEdit',
            component: ServiceTiersEdit,
            meta: {
                allowAnonymous: false
            }
        }
    ]
};
