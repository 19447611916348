import BusinessTypes from '../views/BusinessTypes'
import BusinessTypesAdd from '../views/BusinessTypes/Add'
import BusinessTypesEdit from '../views/BusinessTypes/Edit'

export default {
    routes: [
        {
            path: '/business-types',
            name: 'BusinessTypes',
            component: BusinessTypes,
            meta: {
                allowAnonymous: false
            }
        },
        {
            path: '/business-types/add',
            name: 'BusinessTypesAdd',
            component: BusinessTypesAdd,
            meta: {
                allowAnonymous: false
            }
        },
        {
            path: '/business-types/edit/:id',
            name: 'BusinessTypesEdit',
            component: BusinessTypesEdit,
            meta: {
                allowAnonymous: false
            }
        }
    ]
}
