<template>
    <img :class="'avatar-img ' + 'avatar-img-' + size"
         :src="getImageSrc"
         v-if="imageExists" />
    <font-awesome-icon v-else icon="user-circle"
                       :class="'avatar-placeholder avatar-placeholder-' + size + ' text-grey-light'" />
</template>

<script>
    export default {
        props: {
            imageSrc: String,
            size: {
                type: String,
                default: 'md'
            }
        },
        computed: {
            getImageSrc() {
                if(this.isUrl(this.imageSrc)) {
                    return this.imageSrc;
                }
                return this.$store.state.baseUrl + '/uploads/users/' + this.imageSrc;
            },
            imageExists() {
                if(this.imageSrc !== undefined && this.imageSrc !== 'undefined' && this.imageSrc !== null && this.imageSrc !== '') {
                    return true;
                }
                return false;
            }
        },
        methods: {
            isUrl(string) {
                var pattern = /^((http|https|ftp):\/\/)/;
                if(!pattern.test(string)) {
                    return false;
                }
                return true;
            }
        }
    }
</script>

<style lang="css" scoped>

.avatar-img {
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
    border: solid 2px #aeaeae;
    padding: 2px;
    box-shadow: 2px 2px 0px #d3d3d3;
}
.avatar-img-sm {
    width: 48px;
    height: 48px;
}
.avatar-img-md {
    width: 60px;
    height: 60px;
}
.avatar-img-lg {
    width: 100px;
    height: 100px;
}

.avatar-placeholder {
    display: block;
}
.avatar-placeholder-sm {
    font-size: 48px;
}
.avatar-placeholder-md {
    font-size: 60px;
}
.avatar-placeholder-lg {
    font-size: 100px;
}

</style>
