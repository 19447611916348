<template>
    <app-layout>

        <div :class="{ 'loading-white': $store.state.loading }">

            <div class="flex flex-col mb-8">

                <div class="flex justify-between items-center mb-8">
                    <h1 class="hdln-1">My Policy Profile</h1>
                    <div>
                        <input class="bg-white border-b placeholder-black focus:outline-none" v-model="searchedKeyword" type="search" placeholder="Search Policy" />
                        <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
                    </div>
                </div>

                <!-- <div id="hilitorTest">
                    <p>Here are some words and things...</p>
                </div> -->

                <div id="policies">
                    <div class="md:flex md:flex-wrap">
                        <div class="md:w-1/2" v-for="(policy, policyIndex) in policies">
                            <div :class="'p-4 rounded  bg-white mb-8 ' + ( isEven(policyIndex) ? 'mr-3' : 'ml-3' )">

                                <div class="flex justify-between items-center mb-2 pb-2 border-b-2 border-secondary">
                            <p class="text-xl">{{ policy.policy_title }}</p>
                        </div>

                            <div class="md:flex">
                                <div class="md:w-1/3">
                                    <div class="flex justify-center items-center flex-col md:border-r border-grey-lighter h-full md:mr-4">
                                        <p class="text-center mb-2">Chapters</p>
                                        <div class="w-20 h-20 bg-grey rounded-full flex justify-center items-center">
                                            <div class="text-white text-4xl">{{ policy.lesson_count }}</div>
                                        </div>
                                        <p class="text-center mb-2"><small>Read Time: {{ policy.read_time}} mins</small></p>
                                    </div>
                                </div>
                                <div class="md:w-2/3">
                                    <div class="mb-12">
                                        <p>Completed: {{ Math.ceil(policy.log_percent) }}%</p>
                                        <prog-bar color="greenbright" :percent="policy.log_percent" />
                                    </div>
                                    <app-button class="mb-1" v-if="showTestButton(policy)" color="secondary" @click="startTest(policy)">
                                        <font-awesome-icon icon="play" class="mr-2" />
                                        <span>Start Test</span>
                                    </app-button>
                                    <div>
                                    <router-link :to="'/my-policy-profile/' + policy.id + '/read'">
                                        <app-button class="mb-1" color="secondary">
                                            <span>{{ hasPassedTest(policy) ? 'Review' : 'Start' }} Policy</span>
                                        </app-button>
                                    </router-link>
                                    &nbsp;
                                    <router-link :to="'/my-policy-profile/' + policy.id + '/process-maps'">
                                        <app-button color="secondary">View Process Map</app-button>
                                    </router-link>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    

                </div>

            </div>

        </div>

        <!-- <modal header="View Process Map"
               :isOpen="true"
               v-if="true"
               @closed="documentOpen = false">
           <iframe :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + currentProcessMap.document" style="width: 100%; height: 500px;" />
        </modal> -->

        <test-intro :isOpen="testIntroOpen" @close="testIntroOpen = false" :testId="currentTestId" />

    </app-layout>
</template>
<script>

    import AppLayout from '../../components/layout/AppLayout'
    import AppButton from '../../components/ui/AppButton'
    import Panel from '../../components/ui/Panel'
    import Modal from '../../components/ui/Modal'
    import ProgBar from '../../components/ui/ProgBar'
    import ProgBarPill from '../../components/ui/ProgBarPill'
    import CircleIcon from '../../components/ui/CircleIcon'
    import PercentageCircle from '../../components/ui/PercentageCircle'
    import TestIntro from '../../components/TestIntro'

    import Hilitor from '../../lib/hilitor'

    export default {
        components: {
            AppLayout,
            AppButton,
            Panel,
            Modal,
            ProgBar,
            ProgBarPill,
            CircleIcon,
            PercentageCircle,
            TestIntro
        },
        data() {
            return {
                loadingPanel: -1,
                policies: [],
                userStatics: null,
                documentOpen: false,
                currentProcessMap: null,
                urlArea: null,
                testIntroOpen: false,
                currentTestId: null,
                searchKeyword: '',
                searchedKeyword: ''
            };
        },
        computed: {
            isCompanyAdmin() {
                return this.$store.getters.userHasRoles('company_admin');
            }
        },
        methods: {
            showAll(policyIndex, id) {
                this.loadingPanel = policyIndex;
                this.$store.dispatch('getCompanyLessonsByCompanyPolicy', id).then((data) => {
                    this.policies[policyIndex].company_lessons = data.ListLesson;
                    this.loadingPanel = -1;
                })
                .catch(() => { this.loadingPanel = -1; });
            },
            hideExtra(policyIndex, id) {
                const lessonOne = this.policies[policyIndex].company_lessons[0];
                const lessonTwo = this.policies[policyIndex].company_lessons[1];
                this.policies[policyIndex].company_lessons = [lessonOne, lessonTwo];
            },
            toggleExtraLessons(policyIndex, id) {
                if(this.policies[policyIndex].company_lessons.length > 2) {
                    this.hideExtra(policyIndex, id);
                }else{
                    this.showAll(policyIndex, id);
                }
            },
            viewProcessMap(policy) {
                 this.processMapLoading = true;
                 const processMapId = '';
                 this.$store.dispatch('getCompanyProcessMap', processMapId).then((data) => {
                     this.processMapLoading = false;
                 })
                 .catch((e) => {
                     this.processMapLoading = false;
                 });
            },
            loadPolicies(done) {
                this.$store.dispatch('getPolicyProfile', this.searchKeyword).then((res) => {

                    // console.log('this.searchKeyword ', this.searchKeyword);

                    this.policies = res.DataList;
                    this.userStatics = res.userStatics;

                    if(done) {
                        done();
                    }

                });
            },
            hasPassedTest(policy) {
                // console.log('hasPassedTest ', policy.user_exam_details);
                if(policy.log_percent == 100) {
                    return true;
                }
                return false;
            },
            showTestButton(policy) {

                // passed
                if(policy.log_percent === 100) {
                    return true;
                }
                return false;
            },
            startTest(policy) {
                if(policy.company_test.length > 0) {
                    this.currentTestId = policy.company_test[0].id;
                    this.testIntroOpen = true;
                }else{
                    this.$fire({
                        type: 'error',
                        title: 'No Test Set',
                        timer: 3000
                    });
                }
            },
            search() {

                const keyword = this.searchKeyword.toLowerCase();
                this.searchedKeyword = '';

                this.loadPolicies(() => {

                    if(keyword !== '') {
                        let filteredPolicies = [];
                        this.policies.forEach((policy, i) => {
                            let policyAdded = false;
                            // policy title
                            if( policy.policy_title.toLowerCase().includes(keyword) ) {
                                filteredPolicies.push(policy);
                                policyAdded = true;
                            }
                            // lesson title & description
                            policy.company_lessons.forEach((lesson, i) => {
                                if( lesson.description.toLowerCase().includes(keyword) ) {
                                    if(!policyAdded) {
                                        filteredPolicies.push(policy);
                                        policyAdded = true;
                                    }
                                }
                                if( lesson.title.toLowerCase().includes(keyword) ) {
                                    if(!policyAdded) {
                                        filteredPolicies.push(policy);
                                        policyAdded = true;
                                    }
                                }
                            });
                        });
                        this.policies = filteredPolicies;

                        this.searchedKeyword = this.searchKeyword;

                    }

                });

            },
            testText(tests) {
                if(tests === 1) {
                    return 'Test';
                }
                return 'Tests';
            },
            isEven(value) {
                return (value % 2 == 0);
            },
        },
        mounted() {

            this.$store.dispatch('getUser').then(() => {

                if(this.$store.state.user.user.job_role.job_title === 'Company Admin') {
                    this.urlArea = 'admin';
                }else{
                    this.urlArea = 'staff';
                }

                this.loadPolicies();
            });

        }
    }

</script>
<style scoped>

/* .pie {
    width: 100px; height: 100px;
    border-radius: 50%;
    background: yellowgreen;
    background-image: linear-gradient(to right, transparent 50%, #655 0);
}

.pie:before {
    content: '';
    display: block;
    margin-left: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%;
    background: #655;
    transform-origin: left;
    transform: rotate(180deg);
} */

.passed {
    background: #ffe426;
    color: #b8a100;
}

.profile-company {
    background: #216f86;
    border: solid 2px #0e6863;
    /* #322370 */
    padding: 4px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 200;
    letter-spacing: 0.05em;
}

</style>
