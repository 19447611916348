<template>
    <div :class="'bg-' + color + ' hover:bg-' + color + '-lighter rounded text-white px-4 py-2 cursor-pointer inline-block'"
         @click="$emit('click')">
        <slot></slot>
    </div>
</template>

<script>

    export default {
        name: 'AppButton',
        props: {
            color: {
                type: String,
                default: 'primary'
            }
        }
    }

</script>

<style lang="css" scoped>

</style>
