<template>
    <app-layout :class="{ 'loading-white': $store.state.loading }">

        <bread-crumbs :links="[
            { name: ( isSuperAdmin ? 'Companies' : 'Company' ), href: ( isSuperAdmin ? '/companies' : '/admin/company' ) },
            { name: 'Update Company' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Update Company</h1>
        </div>

        <form-section title="Company Details">

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Company Name</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('company_name') && showErrors }"
                           v-model="form.company_name.value" />
                    <validation-message :show="showErrors && !isValid('company_name')">company name required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4" v-if="isSuperAdmin">
                    <form-label :required="true">Choose Service Tier</form-label>
                    <select class="form-select"
                            :class="{ invalid: !isValid('service_tier_id') && showErrors }"
                            v-model="form.service_tier_id.value">
                        <option value="">Choose Service Tier</option>
                        <option v-for="tier in serviceTiers" :value="tier.id">{{ tier.name }}</option>
                    </select>
                    <validation-message :show="showErrors && !isValid('service_tier_id')">service tier required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col" v-if="isSuperAdmin">

                <form-group class="w-full">
                    <form-label :required="true">Choose Master Policy Set</form-label>
                    <select class="form-select"
                            :class="{ invalid: !isValid('master_policies_set_id') && showErrors }"
                            v-model="form.master_policies_set_id.value">
                        <option value="">Choose Master Policy Set</option>
                        <option v-for="policy in masterPolicySets" :value="policy.id">{{ policy.title }}</option>
                    </select>
                    <validation-message :show="showErrors && !isValid('master_policies_set_id')">master policies set required</validation-message>
                </form-group>

                <form-group class="w-full mt-8">
                    <fake-checkbox :checked="boolVal(form.company_enable.value)"
                                   id="company_enabled"
                                   @changed="enabledCheckboxChanged">
                        Enabled
                   </fake-checkbox>
                </form-group>

            </div>

        </form-section>

        <form-section title="Billing Details" v-if="isSuperAdmin">

            <div class="flex flex-col">

                <form-group class="w-full">
                    <form-label :required="true">Choose Billing Period</form-label>
                    <select class="form-select"
                            :class="{ invalid: !isValid('billing_type') && showErrors }"
                            v-model="form.billing_type.value">
                        <option value="">Choose Billing Period</option>
                        <option value="1">Monthly</option>
                        <option value="2">Annually</option>
                    </select>
                    <validation-message :show="showErrors && !isValid('billing_type')">billing type required</validation-message>
                </form-group>

                <form-group class="w-full mt-8">
                    <fake-checkbox :checked="overrideBilling"
                                   id="overrideBilling"
                                   @changed="overrideBilling = !overrideBilling; form.billing_price.value = null">
                         Override Default Service Tier Billing Price?
                   </fake-checkbox>
                </form-group>

                <form-group class="w-full mt-4" :class="{ hidden: !overrideBilling }">
                    <form-label :required="true">Billing Price</form-label>
                    <input type="number"
                           class="form-input w-24"
                           :class="{ invalid: !isValid('billing_price') && showErrors }"
                           v-model="form.billing_price.value" />
                    <validation-message :show="showErrors && !isValid('billing_price')">billing price required</validation-message>
                </form-group>

            </div>

        </form-section>

        <form-section title="Contact Details">

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Address Line One</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('address_line_1') && showErrors }"
                           v-model="form.address_line_1.value" />
                    <validation-message :show="showErrors && !isValid('address_line_1')">address line one required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="true">Address Line Two</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('address_line_2') && showErrors }"
                           v-model="form.address_line_2.value" />
                    <validation-message :show="showErrors && !isValid('address_line_2')">address line two required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">City</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('city') && showErrors }"
                           v-model="form.city.value" />
                    <validation-message :show="showErrors && !isValid('city')">City required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="true">Country</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('country') && showErrors }"
                           v-model="form.country.value" />
                    <validation-message :show="showErrors && !isValid('country')">country required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Postcode</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('postal_code') && showErrors }"
                           v-model="form.postal_code.value" />
                    <validation-message :show="showErrors && !isValid('postal_code')">postcode required</validation-message>
                </form-group>

            </div>

        </form-section>

        <form-section title="Company Logo">

            <img v-if="logoPreview" :src="logoPreview" class="mx-auto mt-2 mb-4 logo-preview" />
            <div v-else>
                <font-awesome-icon icon="image" class="logo-icon" />
            </div>

            <label for="company_logo">
                <input id="company_logo" class="hidden" type="file" value="Click here to browse" @change="logoChanged" />
                <div class="bg-secondary cursor-pointer text-white rounded p-2 w-60 mx-auto mb-2">{{ logoFileUploadText }}</div>
            </label>

        </form-section>

        <form-section title="Branding">

            <label for="branding_primary">
                <span>Current: {{ form.branding_primary.value }}</span>
            <form-group class="w-full text-center">
                <input type="color" 
                        id="branding_primary" 
                        name="branding_primary" 
                        value="#7D3880" 
                        class="color-rounded mb-2" 
                        v-model="form.branding_primary.value">
            </form-group>
            </label>
            <div> 
                <button class="bg-secondary cursor-pointer text-white rounded p-2 w-60 mx-auto mb-2" @click="resetPrimaryColor()">Reset</button>
            </div>
            <div> 
                <button class="bg-secondary cursor-pointer text-white rounded p-2 w-60 mx-auto mb-2" @click="previewOpen = true">Preview</button>
            </div>

        </form-section>

        <form-section title="Signature Image">

            <img v-if="signaturePreview" :src="signaturePreview" class="mx-auto mt-2 mb-4 logo-preview" />
            <div v-else>
                <font-awesome-icon icon="image" class="logo-icon" />
            </div>

            <label for="signature_image">
                <input id="signature_image" class="hidden" type="file" value="Click here to browse" @change="signatureChanged" />
                <div class="bg-secondary cursor-pointer text-white rounded p-2 w-60 mx-auto mb-2">{{ signatureFileUploadText }}</div>
            </label>

        </form-section>

        <form-section title="Default Password">

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="false">Default Password</form-label>
                    <input type="password"
                           class="form-input"
                           v-model="form.default_password.value" />

                <div class="text-sm text-grey">Password for new user accounts</div>
                </form-group>

            </div>

        </form-section>

        <div class="flex mt-8">
            <app-button class="mr-2" @click="submit">Update Company</app-button>
            <router-link :to=" ( isSuperAdmin ? '/companies' : '/admin/company' ) ">
                <app-button color="danger">Cancel</app-button>
            </router-link>
        </div>

        <modal header="Preview Branding"
               :isOpen="previewOpen"
               @closed="previewOpen = false">

            <table class="w-full" style="color: #000000">
                <tbody>
                    <tr>
                        <td :style="{'background-color':this.form.branding_primary.value}" colspan="2">
                            <h5 style="text-align: right; "><font color="#ffffff">August 2021</font></h5>
                            <p>&nbsp;</p>
                            <p>&nbsp;</p>
                            <h1><strong><font class="text-2xl font-bold" color="#ffffff">Example Policy</font></strong></h1><h1><strong><font color="#ffffff"><br></font></strong></h1>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <h3><b><span class="font-bold" style="color: #E75118;">1. Heading</span></b></h3>
                            <p class="text-sm">This is an example of how the branding will look</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table style="color: #000000">
                <tbody>
                    <tr>
                        <td style="background-color: #7ab929;" width="308">
                            <p class="p-2 font-bold text-sm" style="text-align: center;"><b>Sub heading</b></p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </modal>
    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import AppButton from '../../components/ui/AppButton'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import FormGroup from '../../components/forms/FormGroup'
import FormSection from '../../components/forms/FormSection'
import FormLabel from '../../components/forms/FormLabel'
import FakeCheckbox from '../../components/forms/FakeCheckbox'
import ValidationMessage from '../../components/forms/ValidationMessage'
import Modal from '../../components/ui/Modal.vue'

import formHelpers from '../../lib/form-helpers'

export default {
    components: {
        AppLayout,
        AppButton,
        BreadCrumbs,
        FormSection,
        FormGroup,
        FormLabel,
        FakeCheckbox,
        ValidationMessage,
        Modal,
    },
    computed: {
        isSuperAdmin() {
            return this.$store.getters.userHasRoles(['super_admin']);
        }
    },
    data() {

        const vm = this;

        return {
            previewOpen: false,
            showErrors: false,
            overrideBilling: false,
            primaryColor: null,
            logoFileUploadText: 'Click here to browse',
            logoPreview: null,
            signatureFileUploadText: 'Click here to browse',
            signaturePreview: null,
            form: {
                company_name: { value: '', required: true },
                service_tier_id: { value: '', required: true },
                master_policies_set_id: { value: '', required: true },
                billing_type: { value: '', required: true },
                billing_price: {
                    value: null,
                    required: true,
                    validation: () => {
                        if(!vm.overrideBilling) {
                            return true;
                        }
                        if(vm.form.billing_price.value) {
                            return true;
                        }
                        return false;
                    }
                },
                address_line_1: { value: '', required: true },
                address_line_2: { value: '', required: true },
                city: { value: '', required: true },
                country: { value: '', required: true },
                postal_code: { value: '', required: true },
                company_logo: { value: '', required: false },
                signature_image: { value: '', required: false },
                company_enable: { value: 0, required: true, validation: 'checkbox' },
                branding_primary: { value: '#000000', required: false },
                default_password: { value: '', required: false },
            },
            serviceTiers: [],
            masterPolicySets: []
        };
    },
    methods: {
        boolVal(value) {
            return ( value === 1 ? true : false );
        },
        enabledCheckboxChanged() {
            if(this.form.company_enable.value === 1) {
                this.form.company_enable.value = 0;
            } else {
                this.form.company_enable.value = 1;
            }
        },
        isValid(field) {
            return formHelpers.isValid(this.form, field);
        },
        logoChanged(e) {
            if(e.target.files.length > 0) {
                const file = e.target.files[0];
                this.logoFileUploadText = file.name;
                this.form.company_logo.value = file;
                this.logoPreview = URL.createObjectURL(file);
            }
        },
        signatureChanged(e) {
            if(e.target.files.length > 0) {
                const file = e.target.files[0];
                this.signatureFileUploadText = file.name;
                this.form.signature_image.value = file;
                this.signaturePreview = URL.createObjectURL(file);
            }
        },
        resetPrimaryColor() {
            this.form.branding_primary.value = this.primaryColor;
        },
        submit() {

            const self = this;

            if(formHelpers.isFormValid(this.form)) {
                let data = formHelpers.getFormData(this.form, true);
                data.append('id', this.$route.params.id);
                this.$store.dispatch('companiesUpdate', data).then((success) => {
                    if(success) {

                        this.$fire({
                            title: "Company Updated",
                            text: success.message,
                            type: "success",
                            timer: 3000
                        });

                        if(this.isSuperAdmin) {
                            self.$router.push('/companies');
                        }

                    }
                });
            }else{
                this.showErrors = true;
            }

        }
    },
    mounted() {

        Promise.all([
            this.$store.dispatch('serviceTierList'),
            this.$store.dispatch('masterPolicySetList'),
            this.$store.dispatch('companiesGet', this.$route.params.id)
        ])
        .then((data) => {

            this.serviceTiers = data[0];
            this.masterPolicySets = data[1];

            const company = data[2];

            this.primaryColor = company.branding ? company.branding['primary'] : '#7D3880';
            this.form.branding_primary.value = this.primaryColor;
            this.form.company_name.value = company.company_name;
            this.form.service_tier_id.value = company.service_tier_id;
            this.form.master_policies_set_id.value = company.master_policies_set_id;
            this.form.billing_type.value = company.billing_type;
            this.form.billing_price.value = company.billing_price;
            this.form.address_line_1.value = company.address_line_1;
            this.form.address_line_2.value = company.address_line_2;
            this.form.city.value = company.city;
            this.form.country.value = company.country;
            this.form.postal_code.value = company.postal_code;
            this.form.company_logo.value = company.company_logo;
            this.form.signature_image.value = company.signature_image;
            this.form.company_enable.value = company.company_enable;
            this.form.default_password.value = company.default_password;

            if(this.form.billing_price.value !== null && this.form.billing_price.value !== undefined) {
                this.overrideBilling = true;
            }

            console.log('logo: ', company.company_logo);
            console.log('signature: ', company.signature_image);

            this.logoPreview = company.company_logo;
            this.signaturePreview = company.signature_image;

        });

    }
}

</script>

<style lang="css" scoped>

    .logo-icon {
        font-size: 138px;
        color: #d6d6d6;
    }

    .logo-preview {
        width: 300px;
        height: 225px;
        object-fit: contain;
        object-position: center;
    }

</style>
