<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Process Maps', href: '/admin/process-maps' },
            { name: 'Update Process Map' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Update Process Map</h1>
        </div>

        <panel class="p-4" :class="{ 'loading-white': $store.state.loading }">

            <form-group class="w-full md:pr-2 mb-4">
                <form-label :required="true">Process Map Name</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('process_map_title') && showErrors }"
                       v-model="form.process_map_title.value" />
                <validation-message :show="showErrors && !isValid('process_map_title')">process map name required</validation-message>
            </form-group>

            <!-- <form-group class="w-full md:pr-2 mb-4">
                <form-label :required="true">Policy Name</form-label>
                <select class="form-input"
                        :class="{ invalid: !isValid('company_policy_id') && showErrors }"
                        v-model="form.company_policy_id.value">
                    <option value="">Choose Policy</option>
                    <option v-for="policy in policies" :value="policy.id">{{ policy.policy_title }}</option>
                </select>
                <validation-message :show="showErrors && !isValid('company_policy_id')">company policy required</validation-message>
            </form-group> -->

            <form-group class="w-full md:pr-2 mb-4">
                <form-label :required="true">Process Instructions</form-label>
                <textarea type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('instructions') && showErrors }"
                       v-model="form.instructions.value"></textarea>
                <validation-message :show="showErrors && !isValid('instructions')">instructions required</validation-message>
            </form-group>

            <div v-if="fileUpload.preview">
                <font-awesome-icon icon="check" class="logo-icon text-secondary" />
            </div>
            <div v-else>
                <font-awesome-icon icon="cloud-upload-alt" class="logo-icon" />
            </div>

            <label for="map_document">
                <input id="map_document" class="hidden" type="file" value="Click here to browse" @change="fileChanged" />
                <div class="bg-secondary cursor-pointer text-white rounded p-2 w-60 mx-auto mb-2">{{ fileUpload.text }}</div>
                <validation-message :show="showErrors && !isValid('document')">process map required</validation-message>
            </label>

            <div class="flex items-center">
                <app-button class="mr-2" @click="submit">Update Process Map</app-button>
                <router-link to="/admin/process-maps">
                    <app-button color="danger" class="mr-2">Cancel</app-button>
                </router-link>
            </div>

        </panel>

    </app-layout>
</template>
<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import AppButton from '../../../components/ui/AppButton'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import FormGroup from '../../../components/forms/FormGroup'
import FormLabel from '../../../components/forms/FormLabel'
import ValidationMessage from '../../../components/forms/ValidationMessage'

import formHelpers from '../../../lib/form-helpers'

export default {
    components: {
        AppLayout,
        Panel,
        AppButton,
        BreadCrumbs,
        FormGroup,
        FormLabel,
        ValidationMessage
    },
    data() {
        return {
            showErrors: false,
            policies: [],
            fileUpload: {
                preview: '',
                text: 'Upload Process Map'
            },
            form: {
                id: { value: '', required: true },
                company_policy_id: { value: '', required: true },
                company_id: { value: '', required: true },
                process_map_title: { value: '', required: true },
                instructions: { value: '', required: true },
                document: { value: '', required: true }
            }
        };
    },
    methods: {
        fileChanged(e) {
            if(e.target.files.length > 0) {
                const file = e.target.files[0];
                this.fileUpload.text = file.name;
                this.form.document.value = file;
                this.fileUpload.preview = URL.createObjectURL(file);
            }
        },
        isValid(field) {
            return formHelpers.isValid(this.form, field);
        },
        submit() {

            if(formHelpers.isFormValid(this.form)) {
                const formData = formHelpers.getFormData(this.form);
                this.$store.dispatch('updateCompanyProcessMap', formData).then((success) => {

                    this.$fire({
                        title: "Process Map Updated",
                        type: "success",
                        timer: 3000
                    });

                    this.$router.push('/admin/process-maps');

                });
            }else{
                this.showErrors = true;
            }

        }
    },
    mounted() {

        this.$store.dispatch('getCompanyProcessMap', this.$route.params.id).then((processMap) => {
            this.$store.dispatch('generatedCompanyPolicies', processMap.company_id).then((policies) => {

                this.policies = policies.ListPolicy;

                this.form.id.value = processMap.id;
                this.form.company_policy_id.value = processMap.company_policy_id;
                this.form.company_id.value = processMap.company_id;
                this.form.process_map_title.value = processMap.process_map_title;
                this.form.instructions.value = processMap.instructions;
                this.form.document.value = processMap.document;

                if(processMap.document) {
                    this.fileUpload.preview = processMap.document;
                    this.fileUpload.text = processMap.document;
                }

            });
        });
    }
}

</script>
