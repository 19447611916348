<template>
    <div id="app" class="font-body text-black">
        <router-view/>
        <error-box :errors="$store.state.errors" />
    </div>
</template>
<script>

import ErrorBox from './components/ui/ErrorBox'

export default {
    name: 'App',
    components: {
        ErrorBox
    },
    mounted() {
        // this.$store.dispatch('getIpAddress');
        window.addEventListener("storage", e => {

            // console.log('window sotrages...');
            //
            // if (e.key !== "vuex") return;
            //
            // // exit if no change
            // if (e.newValue === JSON.stringify(this.$store.state)) return;
            //
            // const persistedData = JSON.parse(e.newValue);
            //
            // this.$store.commit("setAll", persistedData);



        });
    }
}

</script>
<style>

    /* .container {
        width: 90%;
        max-width: 1000px;
        margin: 0px auto;
    } */

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }

    .app-scrollbar::-webkit-scrollbar {
      width: 0.5em;
      padding: 10px;
    }

    .app-scrollbar::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      padding: 10px;
      background: transparent;
    }

    .app-scrollbar::-webkit-scrollbar-thumb {
        background-color: #4b6c20;
        border-radius: 20px;
    }

    .scrollbar::-webkit-scrollbar {
      width: 0.5em;
      padding: 10px;
    }

    .scrollbar::-webkit-scrollbar-track {
      /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      padding: 10px;
      background: transparent;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background-color: #333;
        border-radius: 20px;
    }

    .loading,
    .loadingSoft,
    .loading-white {
      position: relative;
    }
    .loading,
    .loading-white {
      min-height: 200px;
      outline: none !important;
      box-shadow: none !important;
      border: none;
    }
    .loading:before,
    .loading-white:before {
      content: '';
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      background-image: url('assets/loading.gif');
      /* background-color: rgba(255, 255, 255, 0.8); */
      background-color: #f0f0f0;
      background-repeat: no-repeat;
      background-size: 30px;
      background-position: center 100px;
      height: 100%;
      z-index: 3;
      text-align: center;
      color: #093a0b;
    }

    .loading-white:before {
        background-color: #fff !important;
    }

    .loadingSoft {
        opacity: 0;
    }

    .invalid {
        border: solid 1px #d72525 !important;
    }

    /* Base */

    .hdln-1 {
        font-size: 28px;
    }

    /* forms */

    .form-input,
    .form-textarea,
    .form-select,
    .vdp-datepicker input {
        width: 100%;
        background: #fff;
        padding: 8px 12px;
        border-radius: 3px;
        border: solid 1px #bebebe;
    }

    .form-input:focus,
    .form-textarea:focus {
        outline: none;
        box-shadow: 0 0 0pt 2pt #ffcfbf;
    }

    .logo-box {
        /* border: solid 2px #a2a2a2;
        padding: 5px;
        box-shadow: 3px 3px 0px #a2a2a2;
        border-radius: 3px; */
        border: solid 2px #aeaeae;
        padding: 5px;
        box-shadow: 3px 3px 0px #d3d3d3;
        border-radius: 3px;
    }

    . {
        box-shadow: 0px 3px 8px #dbdbdb;
        outline: solid 1px #d7d7d7;
    }

</style>
