// import MyPolicyProfile from '../views/MyPolicyProfile'

export default {
    routes: [
        // {
        //     path: '/staff/my-policy-profile',
        //     name: 'MyPolicyProfile',
        //     component: MyPolicyProfile,
        //     meta: {
        //         allowAnonymous: false,
        //         jobRoles: ['staff']
        //     }
        // }
    ]
}
