<template lang="html">
    <div class="w-12 h-12 rounded-full flex justify-center text-center items-center" :class="'bg-' + background">
        <font-awesome-icon :icon="icon" :class="'text-' + color" class="text-xl" />
    </div>
</template>

<script>

    export default {
        props: {
            icon: String,
            background: {
                type: String,
                default: 'primary'
            },
            color: {
                type: String,
                default: 'white'
            }
        }
    }
</script>

<style lang="css">



</style>
