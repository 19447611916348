<template lang="html">
    <app-layout>

        <div :class="{loading: $store.state.loading}">

            <bread-crumbs :links="[
                { name: 'Master Policy Sets', href: '/master-policy-sets' },
                { name: masterPolicySet.title }
            ]" />

            <div class="flex justify-between items-center mb-8">
                <h1 class="hdln-1">Master Policies of {{ masterPolicySet.title }}</h1>
                <div>
                    <input class="bg-white border-b placeholder-black focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search Policy" />
                    <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
                </div>
                <router-link :to="'/master-policy-sets/' + $route.params.id + '/policies/add'">
                    <app-button>Add New Policy</app-button>
                </router-link>
            </div>

            <div class="md:flex md:justify-between mb-8 bg-white"
                 style="border-bottom: solid 3px #c0c0c0;" v-for="(policy, policyIndex) in masterPolicies"
                 :class="{ 'loading-white': (loadingPanel === policyIndex) }">
                <div class="md:w-4/5 md:mr-4">

                    <div class="relative">
                        <app-table style="box-shadow: none; border: none;">
                            <template #thead>
                                <tr class="text-white">
                                    <th>
                                        <span>{{ policy.policy_title }} - version {{ policy.version }} ( {{ policy.expiration_date.split(' ')[0] | formatDate }} )</span>
                                        <span class="text-xs ml-4">{{ policy.lesson_count }} {{ ( policy.lesson_count === 1 ? 'Lesson' : 'Lessons' ) }} &amp; {{ policy.exam_count }} {{ testText(policy.exam_count) }}</span>
                                    </th>
                                    <th></th>
                                </tr>
                            </template>
                            <template #tbody>
                                <tr v-for="lesson in policy.lesson">
                                    <td>
                                        <div class="flex items-center">
                                            <font-awesome-icon icon="clipboard-list" class="text-grey-lighter text-5xl my-2 mr-4" />
                                            <div>
                                                <p class="text-lg">{{ lesson.title }}</p>
                                                <p class="text-sm text-grey-dark font-bold">{{ lesson.description }}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="flex justify-end">
                                            <router-link :to="'/master-policies/' + $route.params.id + '/policies/' + policy.id + '/lessons/' + lesson.id + '/edit'" class="ml-2 mb-2 bg-white text-primary hover:text-primary-lighter flex items-center">
                                                <icon-button icon="edit" color="secondary" class="mr-2" />
                                            </router-link>
                                            <icon-button icon="trash-alt" color="danger" class="ml-2" @click="deleteLesson(lesson.id)" />
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="policy.lesson_count > 2">
                                    <td class="text-center" colspan="2">
                                        <app-button @click="toggleExtraLessons(policyIndex, policy.id)">
                                            {{ ( masterPolicies[policyIndex].lesson.length > 2 ? 'Hide' : 'Show All' ) }}
                                        </app-button>
                                    </td>
                                </tr>
                            </template>
                        </app-table>
                    </div>

                </div>
                <div class="md:w-1/5 md:pb-4">

                    <!-- <div class="bg-primary p-2 font-bold hidden md:block" style="height: 44.5px;">actions</div> -->

                    <div class="flex flex-col items-start pt-2">
                        <router-link :to="'/master-policy-sets/' + $route.params.id + '/policies/' + policy.id + '/edit'" class="mr-4 mb-2 bg-white text-primary hover:text-primary-lighter flex items-center">
                            <font-awesome-icon icon="edit" class="mr-2" />
                            <p>Edit Policy</p>
                        </router-link>
                        <router-link :to="'/master-policy-sets/' + $route.params.id + '/policies/' + policy.id + '/lessons/add'" class="mr-4 mb-2 text-primary hover:text-primary-lighter flex items-center">
                            <font-awesome-icon icon="plus" class="mr-2" />
                            <p>Add Lesson</p>
                        </router-link>
                        <div @click="deletePolicy(policy.id)" class="mr-4 text-primary hover:text-primary-lighter flex items-center cursor-pointer">
                            <font-awesome-icon icon="trash-alt" class="mr-2" />
                            <p>Delete Policy</p>
                        </div>
                        <!-- <app-button color="blue" class="flex items-center mt-6" @click="generatePDF(policy.id)">
                            <font-awesome-icon icon="file-pdf" />
                            <p class="ml-2">PDF</p>
                        </app-button> -->
                    </div>

                </div>
            </div>

        </div>

    </app-layout>
</template>

<script>

    import AppLayout from '../../components/layout/AppLayout'
    import Panel from '../../components/ui/Panel'
    import AppButton from '../../components/ui/AppButton'
    import AppTable from '../../components/ui/AppTable'
    import IconButton from '../../components/ui/IconButton'
    import BreadCrumbs from '../../components/ui/BreadCrumbs'

    export default {
        components: {
            AppLayout,
            Panel,
            AppButton,
            AppTable,
            IconButton,
            BreadCrumbs
        },
        data() {
            return {
                loadingPanel: -1,
                searchKeyword: '',
                masterPolicySet: {
                    title: ''
                },
                masterPolicies: []
            }
        },
        methods: {
            search() {
                const data = {
                    master_policy_set_id: this.$route.params.id,
                    search: this.searchKeyword
                };
                this.$store.dispatch('masterPolicySetPolicies', data).then((res) => {
                    this.masterPolicies = res.ListPolicy;
                    this.masterPolicySet = res.policySetData;

                    console.log(this.masterPolicies);

                });
            },
            toggleExtraLessons(policyIndex, id) {
                if(this.masterPolicies[policyIndex].lesson.length > 2) {
                    this.hideExtra(policyIndex, id);
                }else{
                    this.showAll(policyIndex, id);
                }
            },
            showAll(policyIndex, id) {
                this.loadingPanel = policyIndex;
                this.$store.dispatch('lessonList', id).then((lessons) => {
                    this.masterPolicies[policyIndex].lesson = lessons;
                    this.loadingPanel = -1;
                })
                .catch(() => { this.loadingPanel = -1; });
            },
            hideExtra(policyIndex, id) {
                const lessonOne = this.masterPolicies[policyIndex].lesson[0];
                const lessonTwo = this.masterPolicies[policyIndex].lesson[1];
                this.masterPolicies[policyIndex].lesson = [lessonOne, lessonTwo];
            },
            generatePDF(policyId) {
                this.$store.dispatch('generarePolicyPDF', { policyId, companyId: null }).then((download_link) => {
                    window.location.href = download_link;
                });
            },
            deletePolicy(id) {
                this.$confirm("Are you sure you want to delete this policy?").then(() => {

                    this.$store.dispatch('masterPolicyDelete', id).then(() => {
                        this.search();
                        this.$fire({
                            title: "Policy deleted",
                            text: "",
                            type: "success",
                            timer: 3000
                        });
                    });

                });
            },
            deleteLesson(id) {
                this.$confirm("Are you sure you want to delete this lesson?").then(() => {
                    this.$store.dispatch('lessonDelete', id).then((success) => {
                        this.search();
                        this.$fire({
                            title: "Lesson Deleted",
                            type: "success",
                            timer: 3000
                        });
                    });
                });
            },
            testText(tests) {
                if(tests === 1) {
                    return 'Test';
                }
                return 'Tests';
            }
        },
        mounted() {
            this.search();
        }
    }

</script>

<style lang="css">



</style>
