<template>
  <div
    class="rounded-t-md px-5 py-5 w-full flex justify-between items-baseline bg-blue text-white"
  >
    <div class="font-semibold text-2xl w-full flex-1">Notification Centre</div>
    <div class="font-semibold max-w-lg">
      {{ selected ? getFullName : "Select Thread" }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getFullName() {
      if (this.selected.users.length > 1 || this.selected.users.length == 0) {
        return this.selected.subject;
      }
      return (
        this.selected.users[0].first_name +
        " " +
        this.selected.users[0].last_name
      );
    },
  },
};
</script>
