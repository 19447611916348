<template>
    <div class="md:flex text-center">
        <div class="md:w-1/2">
            <hero />
        </div>
        <div class="md:w-1/2">
            <div class="p-4 md:h-screen flex justify-center items-center">
                <div class="w-3/5" :class="{ 'loading-white': $store.state.loading }">

                    <h1 class="text-secondary text-3xl font-bold">LOGIN</h1>
                    <p class="text-lg mb-8">Welcome Back</p>

                    <form-group icon="user-circle" label="Email ID">
                        <input class="w-full py-2 bg-white border-b-2 border-grey-dark focus:border-secondary outline-none"
                                type="email"
                                placeholder="Email..."
                                v-model="email" />
                    </form-group>

                    <form-group icon="lock" label="Password" type="password" :value="password">
                        <input class="w-full py-2 bg-white border-b-2 border-grey-dark focus:border-secondary outline-none"
                                type="password"
                                placeholder="Password..."
                                v-model="password" />
                    </form-group>

                    <div class="mt-2">
                        <app-button color="secondary" class="w-full" @click="login()">Login</app-button>
                        <router-link to="/forgot-password" class="text-grey mt-4 block">forgot password?</router-link>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Hero from '../../components/logged-out/Hero'
import FormGroup from '../../components/logged-out/FormGroup'
import AppButton from '../../components/ui/AppButton'

export default {
    name: 'Login',
    components: {
        Hero,
        FormGroup,
        AppButton
    },
    data() {
		return {
			email: '',
			password: '',
			showErrors: false
		}
	},
    methods: {
		async login() {

			this.errors = [];
			if(!this.email) {
				// this.$store.commit('setError', 'email required');
                this.$fire({ title: 'email required', type: 'error', timer: 3000 });
				this.showErrors = true;
				return;
			}
			if(!this.password) {
				// this.$store.commit('setError', 'password required');
                this.$fire({ title: 'password required', type: 'error', timer: 3000 });
				this.showErrors = true;
				return;
			}
			var self = this;
			this.$store.dispatch('login', { email: this.email, password: this.password })
				.then(() => {
					self.$router.push('/dashboard');
				});

		},
		validate(name) {
			if(!this[name]) {
				return false;
			}
			return true;
		}
	}
}

</script>
