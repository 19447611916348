import axios from 'axios';
import apiServer from './../lib/apiServer';

const state = {
    loading: {
        generate: false
    }
};

const getters = {
    viewCompanyLink(state, getters) {
        return function(companyId) {
            if(getters.userHasRoles(['super_admin'])) {
                return '/companies/view/' + companyId;
            }
            return '/admin/company';
        }
    }
};

const actions = {
    companiesList({commit, rootState}) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.get(rootState.apiUrl + '/company/list')
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(res.data.data);
                    }

                    resolve(res.data.data);
                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    },
    companiesGet({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company/id',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companiesAdd({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company/create',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companiesUpdate({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company/update',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companiesDelete({commit, rootState}, companyId) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company/delete',
                data: { company_id: companyId },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companySiteList({commit, rootState}, companyId) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/company/' + companyId + '/sites',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companySiteGet({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/company/' + data.companyId + '/sites/' + data.siteId,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companySiteAdd({commit, rootState}, data) {
        // POST v2/company/{companyId}/sites
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/company/' + data.get('company_id') + '/sites',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companySiteUpdate({commit, rootState}, data) {
        // POST v2/company/{companyId}/sites/{siteId}
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/company/' + data.get('company_id') + '/sites/' + data.get('site_id'),
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companySiteDelete({commit, rootState}, data) {
        // DELETE v2/company/{companyId}/sites/{siteId}
        return new Promise((resolve, reject) => {
            apiServer.delete(commit, rootState, {
                url: '/v2/company/' + data.get('company_id') + '/sites/' + data.get('site_id'),
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    // generated policies
    generatedCompanyPolicies({commit, rootState}, companyId) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-policy/detail',
                data: {
                    company_id: companyId,
                    searchKeyword: ''
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companyPolicyDelete({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-policy/delete',
                data: {
                    company_id: data.companyId,
                    policy_id: data.policyId
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    upsertCompanyPlaceholder({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-placeholder/create',
                data: {
                    company_id: data.company_id,
                    id: data.id,
                    placeholder_key: data.placeholder_key,
                    placeholder_value: data.placeholder_value
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    generatePolicies({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-policy/generate-policy',
                data: {
                    company_id: data.company_id,
                    policy_ids: data.policy_ids // "[3001]"
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    //
    generatedProcessMaps({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-process-map/list',
                data: { searchKeyword: data.searchKeyword, company_id: data.companyId },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getCompanyProcessMap({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-process-map/detail',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    updateCompanyProcessMap({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-process-map/update',
                data: {
                    process_map_title: data.get('process_map_title'),
                    instructions: data.get('instructions'),
                    document: data.get('document'),
                    status: 1,
                    company_policy_id: data.get('company_policy_id'),
                    id: data.get('company_policy_id'),
                    company_id: data.get('company_id')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    generatedProcessMapDelete({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-process-map/delete',
                data: {
                    company_id: data.companyId,
                    id: data.id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    generateProcessMap({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-policy/generate-process-map',
                data: {
                    company_id: data.company_id,
                    process_map_ids: data.process_map_ids
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
    state,
    getters,
	actions
}
