import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    getMasterTestSet({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/detail',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getMasterTestSets({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/list',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    addMasterTestSet({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/create',
                data: {
                    description: data.description,
                    master_policy_set_id: data.master_policy_set_id,
                    name: data.name
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    updateMasterTestSet({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/update',
                data: {
                    id: data.id,
                    description: data.description,
                    master_policy_set_id: data.master_policy_set_id,
                    name: data.name
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    deleteMasterTestSet({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/delete',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    duplicateTestSet({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/test-set-duplicate',
                data: {
                    description: data.description,
                    id: data.id,
                    master_policy_set_id: data.master_policy_set_id,
                    name: data.name
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getPoliciesFromTestSet({commit, rootState}, testSetId) {
        // testSet/get-policies-from-test-set
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/testSet/get-policies-from-test-set',
                data: { id: testSetId },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
}

export default {
    actions
}
