<template>
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <bread-crumbs :links="[
                { name: 'Reports', href: '/reports' },
                { name: 'Policies for ' + company.company_name }
            ]" />

            <div class="flex justify-between items-center mb-8">
                <h1 class="hdln-1">Policies for {{ company.company_name }}</h1>
                <!-- <app-button @click="downloadCsv">Download CSV</app-button> -->
            </div>

            <div class="md:flex md:flex-wrap">

                <div class="md:w-1/2" v-for="(policy, i) in policies">

                    <div :class="'p-4 rounded  bg-white mb-8 ' + ( isEven(i) ? 'mr-3' : 'ml-3' )">

                        <div class="flex justify-between items-center mb-2 pb-2 border-b-2 border-secondary">
                            <p class="text-xl">{{ policy.policy_title }}</p>
                        </div>

                        <div class="text-right mt-4">
                            <router-link :to="'/reports/' + $route.params.companyId + '/by-policy/' + policy.id">
                                <app-button color="greenbright">View Details</app-button>
                            </router-link>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import ProgBar from '../../components/ui/ProgBar'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import AppButton from '../../components/ui/AppButton'
import StatTable from '../../components/StatTable'
import PercentageCircle from 'vue-css-percentage-circle'

export default {
    components: {
        AppLayout,
        Panel,
        ProgBar,
        BreadCrumbs,
        AppButton,
        StatTable,
        PercentageCircle
    },
    data() {
        return {
            policies: [],
            company: {
                company_name: ''
            },
            loading: false
        };
    },
    methods: {
        isEven(value) {
        	if (value%2 == 0)
        		return true;
        	else
        		return false;
        },
        // downloadCsv() {
            // const data = {
            //     userId: this.$store.state.user.user.id,
            //     companyId: this.$route.params.companyId
            // };
            // this.$store.dispatch('usersReportCsv', data).then((data) => {
            //     window.location.href = data.download_link;
            // });
        // }
    },
    mounted() {
        this.loading = true;
        Promise.all([
            this.$store.dispatch('companiesGet', this.$route.params.companyId),
            this.$store.dispatch('getCompanyPolicies', { company_id: this.$route.params.companyId })
        ]).then((data) => {
            this.company = data[0];
            this.policies = data[1].ListPolicy;
            this.loading = false;
        });
    }
}

</script>
