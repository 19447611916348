<template>
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <bread-crumbs :links="[
                { name: 'Reports', href: '/reports' },
                { name: 'Users for ' + company.company_name }
            ]" />

            <div class="flex justify-between items-center mb-8">
                <h1 class="hdln-1">Users for {{ company.company_name }}</h1>
                <app-button @click="downloadCsv">Download CSV</app-button>
            </div>

            <div class="md:flex md:flex-wrap">

                <div class="md:w-1/2" v-for="(user, i) in users">

                    <div :class="'p-4 rounded  bg-white mb-8 ' + ( isEven(i) ? 'mr-3' : 'ml-3' )">

                        <div class="flex justify-between items-center mb-2 pb-2 border-b-2 border-secondary">
                            <p class="text-xl">{{ user.first_name }} {{ user.last_name }}</p>
                        </div>

                        <div class="md:flex">
                            <div class="md:w-1/3">
                                <div class="flex justify-center items-center flex-col md:border-r border-grey-lighter h-full md:mr-4">
                                    <p class="text-center mb-2">Assigned Policies</p>
                                    <div class="w-20 h-20 bg-grey rounded-full flex justify-center items-center">
                                        <div class="text-white text-4xl">{{ user.read_policy.length }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="md:w-2/3">

                                <div class="mb-1.5">
                                    <p>Completed Policies: {{ user.completed_policy.length }}</p>
                                    <prog-bar color="greenbright" :percent="completedPolicyPercent(user)" />
                                </div>
                                <div class="mb-1.5">
                                    <p>Completed Tests: {{ testPassedCount(user) }}</p>
                                    <prog-bar color="greenbright" :percent="clearedTestPercentage(user)" />
                                </div>

                            </div>
                        </div>

                        <div class="text-right mt-4">
                            <router-link :to="'/reports/' + $route.params.companyId + '/by-user/' + user.id">
                                <app-button color="greenbright">View Details</app-button>
                            </router-link>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import ProgBar from '../../components/ui/ProgBar'
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import AppButton from '../../components/ui/AppButton'
import StatTable from '../../components/StatTable'
import PercentageCircle from 'vue-css-percentage-circle'

export default {
    components: {
        AppLayout,
        Panel,
        ProgBar,
        BreadCrumbs,
        AppButton,
        StatTable,
        PercentageCircle
    },
    data() {
        return {
            users: [],
            company: {
                company_name: ''
            },
            loading: false
        };
    },
    methods: {
        downloadCsv() {
            const data = {
                userId: this.$store.state.user.user.id,
                companyId: this.$route.params.companyId
            };
            this.$store.dispatch('usersReportCsv', data).then((data) => {
                window.location.href = data.download_link;
            });
        },
        isEven(value) {
        	if (value%2 == 0)
        		return true;
        	else
        		return false;
        },
        testPassedCount(user) {
            let passed = 0;
            user.cleared_test.forEach((test, i) => {
                if(test.exam_status > 0) {
                    passed++;
                }
            });
            return passed;
        },
        completedPolicyPercent(user) {
            if (user.completed_policy.length == 0) {
                return 0;
            }
            return Math.ceil((user.completed_policy.length * 100) / user.read_policy.length);
        },
        clearedTestPercentage(user) {
            if (user.read_policy.length == 0) {
                return 0;
            }
            const passed = this.testPassedCount(user);
            return Math.ceil((passed * 100) / user.read_policy.length);
        }
    },
    mounted() {
        this.loading = true;
        Promise.all([
            this.$store.dispatch('companiesGet', this.$route.params.companyId),
            this.$store.dispatch('usersReport', { companyId: this.$route.params.companyId })
        ]).then((data) => {
            this.company = data[0];
            this.users = data[1].users;
            this.loading = false;
        });
    }
}

</script>
