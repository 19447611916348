<template lang="html">

    <div :class="{loading}">

        <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg">Process Maps</h2>
            <div class="flex" v-if="$store.getters.userHasRoles(['super_admin'])">
                <router-link :to="'/company/' + companyId + '/process-maps/generate'">
                    <app-button>Generate Process Maps</app-button>
                </router-link>
            </div>
        </div>

        <div class="relative">

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Process Map</th>
                        <th></th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="map in processMaps">
                        <td>{{ map.process_map_title }}</td>
                        <td class="flex justify-end">
                            <icon-button size="sm" icon="trash-alt" color="danger" @click="deleteProcessMap(map.id)" />
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

    </div>

</template>

<script>

    import AppTable from '../../components/ui/AppTable'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import Modal from '../../components/ui/Modal'
    import UploadedLogo from '../../components/UploadedLogo'

    export default {
        components: {
            AppTable,
            AppButton,
            IconButton,
            Modal,
            UploadedLogo
        },
        props: {
            companyId: Number
        },
        data() {
            return {
                loading: false,
                processMaps: []
            }
        },
        methods: {
            deleteProcessMap(id) {
                this.$confirm("Are you sure you want to delete this policy?").then(() => {

                    const companyId = this.companyId;
                    const data = { companyId, id };

                    this.$store.dispatch('generatedProcessMapDelete', data).then(() => {
                        this.$store.dispatch('generatedProcessMaps', {companyId, searchKeyword: ''}).then((processMaps) => {
                            this.processMaps = processMaps;
                            this.$fire({
                                title: "Process map deleted",
                                text: "",
                                type: "success",
                                timer: 3000
                            });
                        });
                    });

                });
            },
            getProcessMaps() {
                this.loading = true;
                this.$store.dispatch('generatedProcessMaps', {companyId: this.companyId}).then((processMaps) => {
                    this.processMaps = processMaps;
                    this.loading = false;
                });
            }
        }
    }

</script>

<style lang="css">

</style>
