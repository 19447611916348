<template>
  <div class="overflow-y-scroll chatscroll w-1/4 bg-blue text-white rounded-bl-md"> 
    <ContactsSearch @search="filterContacts" />
    <button v-for="contact in contacts" @click="setInput(contact)" :key="contact.id" :class="{ 'bg-primary-lighter': contact == selected }" class="w-full flex flex-row py-4 p-2 hover:bg-primary active:bg-primary-lighter justify-center items-center" >
      <div class="w-full">
        <div class="text-xs font-semibold">{{ getFullName(contact) }}</div>
      </div>
    </button>
  </div>
</template>

<script>
import ContactsSearch from './ContactsSearch';
export default {
  data() {
    return {
      selected: null,
    };
  },
  props: {
    contacts: {
      type: Array,
    }
  },
  methods: {
    getFullName(contact) {
      return contact.first_name + ' ' + contact.last_name
    },
    setInput(value) {
      if(!this.selected || this.selected.id != value.id) {
        this.selected = value
        return
      }
      this.selected = {}
      let contact = value
      this.$emit('selected', contact)
    },
    filterContacts(item) {
      this.$emit('search', item);
    },
  },
  components: {
    ContactsSearch
  }
};
</script>