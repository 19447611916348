import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    processMapList({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/processMap/list',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    processMapListBySetId({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/processMap/getBySetId',
                data: {
                    policy_set_id: data.policySetId,
                    searchKeyword: data.searchKeyword
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    processMapAdd({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/processMap/create',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    processMapUpdate({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/processMap/update',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    processMapShow({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/processMap/detail',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    processMapDelete({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/processMap/delete',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getGeneratedProcessMapsByCompanyPolicyId({commit, rootState}, companyPolicyId) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-process-map/list-by-policy',
                data: { companyPolicyId },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
}

export default {
    actions
}
