<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Companies', href: '/companies' },
            { name: company.company_name, href: '/companies/view/' + company.id },
            { name: 'Company Policies' }
        ]" />

        <div :class="{ 'loading-white': ( $store.state.loading ? true : false ) }">

            <div class="flex justify-between items-center">
                <h1 class="hdln-1 mb-8">Company Policies</h1>
                <div class="flex justify-between items-center">
                    <app-button color="secondary" @click="genPoliciesOpen = true">Generate Policies</app-button>
                    <router-link :to="'/companies/view/' + company.id">
                        <app-button color="danger" class="ml-4">Back</app-button>
                    </router-link>
                </div>
            </div>

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Policies</th>
                        <th>
                            <div class="flex justify-end cursor-pointer">
                                <fake-checkbox id="policies_all" :checked="isAllSelected" @changed="toggleSelectAll" :is_light="true">
                                    Select All
                                </fake-checkbox>
                            </div>
                        </th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="policy in policies">
                        <td>{{ policy.policy_title }}</td>
                        <td>
                            <div class="flex justify-end">
                                <fake-checkbox :id="'policy_' + policy.id" :checked="isSelected(policy.id)" @changed="togglePolicy(policy.id)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

        <modal header="Placeholders"
               :isOpen="genPoliciesOpen" @closed="genPoliciesOpen = false" :loading="$store.state.loading">

            <form-group class="mb-4" v-for="(placeholder, i) in placeholders">
                <form-label :required="true">{{ placeholder.placeholder_key }}</form-label>
                <input type="text" v-model="placeholders[i].placeholder_value" class="form-input" />
            </form-group>

            <div class="flex">
                <app-button class="mr-4" @click="generatePolicies">Submit</app-button>
                <app-button class="mr-4" @click="genPoliciesOpen = false">Cancel</app-button>
            </div>

        </modal>

    </app-layout>
</template>
<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import Modal from '../../../components/ui/Modal'
import AppButton from '../../../components/ui/AppButton'
import IconButton from '../../../components/ui/IconButton'
import AppTable from '../../../components/ui/AppTable'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import FakeCheckbox from '../../../components/forms/FakeCheckbox'
import FormGroup from '../../../components/forms/FormGroup'
import FormLabel from '../../../components/forms/FormLabel'
import ValidationMessage from '../../../components/forms/ValidationMessage'

import helpers from '../../../lib/general'

export default {
    components: {
        AppLayout,
        Panel,
        Modal,
        AppButton,
        IconButton,
        AppTable,
        BreadCrumbs,
        FakeCheckbox,
        FormGroup,
        FormLabel,
        ValidationMessage
    },
    data() {
        return {
            genPoliciesOpen: false,
            company: {
                company_name: null
            },
            policies: [],
            selectedPolicies: [],
            placeholders: []
        };
    },
    computed: {
        isAllSelected() {
            return (this.selectedPolicies.length === this.policies.length);
        }
    },
    methods: {
        togglePolicy(id) {
            if(this.isSelected(id)) {
                var index = this.selectedPolicies.indexOf(id);
                if (index !== -1) {
                    this.selectedPolicies.splice(index, 1);
                }
            }else{
                this.selectedPolicies.push(id);
            }
        },
        isSelected(id) {
            return this.selectedPolicies.includes(id);
        },
        toggleSelectAll() {
            if(this.isAllSelected) {
                this.deselectAll();
            }else{
                this.selectAll();
            }
        },
        selectAll() {
            this.selectedPolicies = [];
            this.policies.forEach((p, i) => {
                this.selectedPolicies.push(p.id);
            });
        },
        deselectAll() {
            this.selectedPolicies = [];
        },
        generatePolicies() {

            const companyId = this.$route.params.companyId;
            const policyIds = JSON.stringify(this.selectedPolicies);

            let promises = [];

            this.placeholders.forEach((p, i) => {
                promises.push(this.$store.dispatch('upsertCompanyPlaceholder', {
                    company_id: companyId,
                    id: p.id,
                    placeholder_key: p.placeholder_key,
                    placeholder_value: p.placeholder_value
                }));
            });

            Promise.all(promises).then((data) => {
                this.genPoliciesOpen = false;
                this.$store.dispatch('generatePolicies', {company_id: companyId, policy_ids: policyIds}).then((data) => {
                    this.$fire({
                        title: "Policies Generated",
                        text: "",
                        type: "success",
                        timer: 3000
                    });
                    this.$router.push('/companies/view/' + companyId);
                });
            });

        }
    },
    mounted() {

        Promise.all([
            this.$store.dispatch('companiesGet', this.$route.params.companyId),
            this.$store.dispatch('placeholderList')
        ]).then((data) => {

            this.placeholders = data[1].original.data;

            this.company = data[0];
            this.$store.dispatch('masterPolicySetPolicies', { master_policy_set_id: data[0].master_policies_set_id }).then((policies) => {
                this.policies = policies.ListPolicy;
            });

        });

    }
}

</script>
