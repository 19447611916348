<template lang="html">

    <div class="relative">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="50" fill="#fff" />
          <circle cx="50" cy="50" r="43" fill="#fff" />
          <path class="circle"
                :stroke-dasharray="dashPercent + ', ' + circumference"
                :d="progressPath"
              />
        </svg>
        <div class="absolute top-0 center-slot rounded" :class="{'loading-white':loading}">
            <slot></slot>
        </div>
    </div>

</template>

<script>

    export default {
        props: {
            circumference: Number,
            percent: Number,
            loading: Boolean,
            type: String
        },
        computed: {
            dashPercent() {
                if (this.type !== 'percentage') {
                    return this.circumference
                }
                return ( this.circumference / 100 ) * this.percent;
            },
            progressPath() {

                let moves = '';

                const circlePadding = 3;
                const radius = this.circumference / ( 3.14159 * 2 );
                const diameter = (radius * 2);

                moves += 'M 50 3';
                moves += 'a '+ radius +' '+ radius +' 0 0 1 0 ' + diameter;
                moves += 'a '+ radius +' '+ radius +' 0 0 1 0 -' + diameter;

                return moves;

            }
        },
    }

</script>

<style scoped>

.center-slot {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

.circular-chart {
    display: block;
    margin: 10px auto;
    max-width: 80%;
    max-height: 250px;
}

.circle {
    stroke: #4CC790;
    fill: none;
    stroke-width: 2.8;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

</style>
