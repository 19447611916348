import MasterPolicySets from '../views/MasterPolicySets'
import MasterPolicySetsAdd from '../views/MasterPolicySets/Add'
import MasterPolicySetsEdit from '../views/MasterPolicySets/Edit'
import MasterPolicySetsCopy from '../views/MasterPolicySets/Duplicate'

import MasterPolicySetsPolicies from '../views/MasterPolicySets/Policies'
import MasterPolicySetsPoliciesAdd from '../views/MasterPolicies/Add'
import MasterPolicySetsPoliciesEdit from '../views/MasterPolicies/Edit'

import MasterPolicyLessonsAdd from '../views/MasterPolicies/Lessons/Add'
import MasterPolicyLessonsEdit from '../views/MasterPolicies/Lessons/Edit'

export default {
    routes: [
        {
            path: '/master-policy-sets',
            name: 'MasterPolicySets',
            component: MasterPolicySets,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-policy-sets/add',
            name: 'MasterPolicySetsAdd',
            component: MasterPolicySetsAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-policy-sets/:id/edit',
            name: 'MasterPolicySetsEdit',
            component: MasterPolicySetsEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-policy-sets/:id/duplicate',
            name: 'MasterPolicySetsCopy',
            component: MasterPolicySetsCopy,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        // master policies
        {
            path: '/master-policy-sets/:id/policies',
            name: 'MasterPolicySetsPolicies',
            component: MasterPolicySetsPolicies,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-policy-sets/:id/policies/add',
            name: 'MasterPolicySetsPoliciesAdd',
            component: MasterPolicySetsPoliciesAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-policy-sets/:id/policies/:policyId/edit',
            name: 'MasterPolicySetsPoliciesEdit',
            component: MasterPolicySetsPoliciesEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        // lessons
        {
            path: '/master-policy-sets/:id/policies/:policyId/lessons/add',
            name: 'MasterPolicyLessonsAdd',
            component: MasterPolicyLessonsAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-policies/:id/policies/:policyId/lessons/:lessonId/edit',
            name: 'MasterPolicyLessonsEdit',
            component: MasterPolicyLessonsEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        }
    ]
}
