import Vue from 'vue'
import App from './App.vue'
import VueSimpleAlert from "vue-simple-alert";
import TextHighlight from 'vue-text-highlight';


import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faBars,
    faUserCircle,
    faAward,
    faBook,
    faClipboardList,
    faClipboardCheck,
    faBuilding,
    faUserTag,
    faTachometerAlt,
    faAngleDown,
    faEnvelope,
    faSignOutAlt,
    faLock,
    faConciergeBell,
    faBriefcase,
    faSearch,
    faEdit,
    faTrashAlt,
    faChevronRight,
    faEye,
    faCheck,
    faImage,
    faCopy,
    faPlus,
    faFileImport,
    faDownload,
    faTimesCircle,
    faCloudUploadAlt,
    faShoePrints,
    faTimes,
    faRedoAlt,
    faAddressCard,
    faPlay,
    faEllipsisH,
    faMinus,
    faVial,
    faFilePdf,
    faFileWord
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faBars)
library.add(faUserCircle)
library.add(faAward)
library.add(faBook)
library.add(faClipboardList)
library.add(faClipboardCheck)
library.add(faBuilding)
library.add(faTachometerAlt)
library.add(faUserTag)
library.add(faAngleDown)
library.add(faEnvelope)
library.add(faSignOutAlt)
library.add(faLock)
library.add(faConciergeBell)
library.add(faBriefcase)
library.add(faSearch)
library.add(faEdit)
library.add(faTrashAlt)
library.add(faChevronRight)
library.add(faEye)
library.add(faCheck)
library.add(faImage)
library.add(faCopy)
library.add(faPlus)
library.add(faFileImport)
library.add(faDownload)
library.add(faTimesCircle)
library.add(faCloudUploadAlt)
library.add(faShoePrints)
library.add(faTimes)
library.add(faRedoAlt)
library.add(faAddressCard)
library.add(faPlay)
library.add(faEllipsisH)
library.add(faMinus)
library.add(faVial)
library.add(faFilePdf)
library.add(faFileWord)

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('text-highlight', TextHighlight);
Vue.use(VueSimpleAlert);

import moment from 'moment'

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
});

import router from './router'
import store from './store'
import './assets/css/index.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
