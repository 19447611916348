import ComplianceLibrary from '../views/ComplianceLibrary'

export default {
    routes: [
        {
            path: '/compliance-library',
            name: 'ComplianceLibrary',
            component: ComplianceLibrary,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        }
    ]
};
