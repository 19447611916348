<template>
    <div>

        <div class="flex flex-col md:flex-row">

            <form-group class="md:w-1/3 md:pr-2 mb-4">
                <form-label :required="true">Old Password</form-label>
                <input type="password"
                       class="form-input"
                       :class="{ invalid: !isValid('old') && showErrors }"
                       v-model="form.old.value" />
                <validation-message :show="showErrors && !isValid('old')">Please enter old password.</validation-message>
            </form-group>

            <form-group class="md:w-1/3 md:pr-2 mb-4">
                <form-label :required="true">New Password</form-label>
                <input type="password"
                       class="form-input"
                       :class="{ invalid: !isValid('new') && showErrors }"
                       v-model="form.new.value" />
                <validation-message :show="showErrors && !isValid('new')">Please enter new password.</validation-message>
                <validation-message :show="showErrors && (!isValidPassword('new') && form.new.value.length > 0)">Password should be at least 6 characters.</validation-message>
            </form-group>

            <form-group class="md:w-1/3 md:pr-2 mb-4">
                <form-label :required="true">Confirm Password</form-label>
                <input type="password"
                       class="form-input"
                       :class="{ invalid: !isValid('confirm') && showErrors }"
                       v-model="form.confirm.value" />
                <validation-message :show="showErrors && !isValid('confirm')">Please confirm password.</validation-message>
                <validation-message :show="showErrors && (form.new.value.length > 0 && form.new.value !== form.confirm.value)">Confirm password must be same as new password.</validation-message>
            </form-group>

        </div>

        <div class="clear-both text-left py-1 mt-4">
            <app-button @click="submit">Change Password</app-button>
        </div>

    </div>
</template>

<script>

    import AppButton from '../../components/ui/AppButton'
    import FormGroup from '../../components/forms/FormGroup'
    import FormLabel from '../../components/forms/FormLabel'
    import ValidationMessage from '../../components/forms/ValidationMessage'

    import formHelpers from '../../lib/form-helpers'

    export default {
        components: {
            AppButton,
            FormGroup,
            FormLabel,
            ValidationMessage
        },
        data() {
            return {
                showErrors: false,
                form: {
                    old: { value: '', required: true },
                    new: { value: '', required: true },
                    confirm: { value: '', required: true }
                }
            }
        },
        methods: {
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            isValidPassword(field) {
                return formHelpers.isValidPassword(this.form[field].value);
            },
            submit() {

                let valid = true;

                if(!this.form.old.value) {
                    valid = false;
                }

                if(!this.form.new.value) {
                    valid = false;
                }

                if(!this.isValidPassword('new')) {
                    valid = false;
                }

                if(this.form.new.value !== this.form.confirm.value) {
                    valid = false;
                }

                if(valid) {

                    this.$store.dispatch('changePassword', {
                        password: this.form.new.value,
                        old_password: this.form.old.value,
                        confirm_password: this.form.confirm.value
                    }).then((successMsg) => {

                        if(successMsg) {
                            this.$fire({
                                title: "Success",
                                text: successMsg,
                                type: "success",
                                timer: 3000
                            });
                        }

                    });

                } else {
                    this.showErrors = true;
                }

            }
        }
    }

</script>

<style lang="css">

</style>
