import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    generatedTests({commit, rootState}, companyId) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-test/get-exam-list-by-company-id',
                data: {
                    company_id: companyId
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companyTestDelete({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-test/delete',
                data: {
                    company_id: data.companyId,
                    test_id: data.testId
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getCompanyTest({commit, rootState}, test_id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-test/detail',
                data: { test_id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    companyTestQuestions({commit, rootState}, company_test_id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-question/list-company-question',
                data: {
                    company_test_id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getCompanyQuestion({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-question/get-company-question-by-id',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    addCompanyQuestion({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-question/add-company-question',
                data: {
                    company_id: data.company_id,
                    company_test_id: data.company_test_id,
                    question_type: data.question_type,
                    question: data.question,
                    questionMarks: data.question_marks,
                    questionAnswers: data.answers
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    updateCompanyQuestion({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-question/update-company-question',
                data: {
                    id: data.id,
                    company_id: data.company_id,
                    company_test_id: data.company_test_id,
                    question_type: data.question_type,
                    question: data.question,
                    questionMarks: data.question_marks,
                    questionAnswers: data.answers
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    deleteCompanyQuestion({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-question/delete-company-question',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    submitExam({commit, rootState}, data) {

        let companyId = rootState.user.user.company_id;

        if(rootState.user.user_role.toString() === "2") {
            companyId = rootState.user.currentCompany.id;
        }

        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/user-statistics/add-exam-response',
                data: {
                    answers: data.answers, // array of answer id's
                    company_id: companyId,
                    company_policy_id: data.company_policy_id,
                    company_test_id: data.company_test_id,
                    user_id: rootState.user.user.id
                },
                success(data) { resolve(data.data) },
                error(err) { reject(err) }
            });
        });
    }
};

export default {
    actions
}
