<template>
  <input
    v-model="message"
    @keydown.enter="send"
    class="w-full bg-grey-light py-4 px-3 rounded-t outline-none shadow-sm"
    type="text"
    :class="invalid ? 'border border-primary-dark' : ''"
    :placeholder="placeholderText"
    :disabled="invalid"
  />
</template>

<script>
export default {
  data() {
    return {
      message: "",
    };
  },
  props: {
    thread: null
  },
  methods: {
    send(e) {
      e.preventDefault();
      if (this.message == "") {
        return;
      }
      this.$emit("send", this.message);
      this.message = "";
    },
  },
  computed: {
    invalid() {
        if(!this.thread) {
            return true
        }
        if(this.isSuperAdmin){
            return false
        }
        if(this.thread.subject == 'All Staff') {
            return true
        }
        if(this.thread.subject == 'All Company Admin') {
            return true
        }
        if(this.thread.subject.startsWith('All Staff') && !this.isCompanyAdmin){
            return true
        }
        return false
    },
    getUser() {
      return this.$store.state.user.user;
    },
    isSuperAdmin() {
        return this.$store.getters.userHasRoles(['super_admin']);
    },
    isCompanyAdmin() {
        return this.$store.getters.userHasRoles(['company_admin']);
    },
    placeholderText() {
        if(!this.thread) {
            return 'Select a thread'
        }
        if(this.invalid) {
            return 'Thread is read only'
        }
        return 'Type your message here...'

    }
  }
};
</script>
