<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <div class="flex justify-between mb-8">
                <h1 class="hdln-1">{{ exam.exam_title }} ( Total Marks: {{ exam.exam_marks }} )</h1>
                <!-- <div class="timer text-danger font-bold">{{ minuets }}:{{ seconds }}</div> -->
            </div>

            <panel class="p-4">

                <div v-for="(question, qIndex) in questions" class="text-left" :class="{ 'mb-6': qIndex < questions.length - 1 }">

                    <p class="font-bold">{{ outputQuestion(question.question) }} Marks ( {{ question.questionMarks }} )</p>
                    <p>
                        Question type: {{ question.question_type }}
                        <!-- <span v-if="question.questionType === 'Multiple Answer'">There are</span> -->
                    </p>

                    <hr class="text-grey-lighter my-4" />

                    <div v-for="(answer, aIndex) in question.questionAnswers">
                        <div class="flex mt-2" @click="selectAnswer(qIndex, aIndex)">
                            <fake-radio :checked="answer.selected" class="mr-2" />
                            <p>{{ answer.answer }}</p>
                        </div>
                    </div>

                </div>

                <app-button @click="submit" color="greenbright">Submit Answers</app-button>

            </panel>

        </div>

    </app-layout>
</template>

<script>

    import AppLayout from '../../components/layout/AppLayout'
    import Panel from '../../components/ui/Panel'
    import BreadCrumbs from '../../components/ui/BreadCrumbs'
    import AppButton from '../../components/ui/AppButton'
    import AppTable from '../../components/ui/AppTable'
    import FormGroup from '../../components/forms/FormGroup'
    import FormSection from '../../components/forms/FormSection'
    import FormLabel from '../../components/forms/FormLabel'
    import FakeCheckbox from '../../components/forms/FakeCheckbox'
    import FakeRadio from '../../components/forms/FakeRadio'
    import ValidationMessage from '../../components/forms/ValidationMessage'

    import formHelpers from '../../lib/form-helpers'

    export default {
        components: {
            AppLayout,
            Panel,
            BreadCrumbs,
            AppButton,
            AppTable,
            FormSection,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            FakeRadio,
            ValidationMessage
        },
        data() {
            return {
                loading: false,
                exam: {
                    id: null,
                    exam_title: ''
                },
                questions: [],
                minuets: 9,
                seconds: 59
            }
        },
        computed: {

        },
        methods: {
            stopCounter() {
                clearInterval(this.counterInterval);
                this.seconds = 59;
                this.minuets = 9;
            },
            startCounter() {
                this.counterInterval = setInterval(() => {
                    if(this.seconds === 0) {
                        this.seconds = 59;
                        this.minuets -= 1;
                    }else{
                        this.seconds -= 1;
                    }
                    if(this.minuets === 0 && this.seconds === 0) {
                        this.stopCounter();
                        alert('Time Expired');
                    }
                }, 1000);
            },
            selectAnswer(i, j) {
                switch (this.questions[i].question_type) {
                    case 'Multiple Answer':
                        this.questions[i].questionAnswers[j].selected = !this.questions[i].questionAnswers[j].selected;
                        break;
                    case 'Single Answer':
                        this.questions[i].questionAnswers.forEach((answer, aIndex) => {
                            this.questions[i].questionAnswers[aIndex].selected = false;
                        });
                        this.questions[i].questionAnswers[j].selected = true;
                        break;
                    default:
                        this.questions[i].questionAnswers.forEach((answer, aIndex) => {
                            this.questions[i].questionAnswers[aIndex].selected = false;
                        });
                        this.questions[i].questionAnswers[j].selected = true;
                }
            },
            getSelectedAnswers() {
                let answers = [];
                this.questions.forEach((q, i) => {
                    this.questions[i].questionAnswers.forEach((a, j) => {
                        if(this.questions[i].questionAnswers[j].selected) {
                            answers.push(this.questions[i].questionAnswers[j].id);
                        }
                    });
                });
                return answers;
            },
            initQuestions(questions) {
                questions.forEach((q, i) => {
                    questions[i].questionAnswers.forEach((a, j) => {
                        questions[i].questionAnswers[j].selected = false;
                    });
                });
                return questions;
            },
            isHTML(str) {
                var a = document.createElement('div');
                a.innerHTML = str;
                for (var c = a.childNodes, i = c.length; i--; ) {
                    if (c[i].nodeType == 1) return true;
                }
                return false;
            },
            outputQuestion(question) {

                if(this.isHTML(question)) {
                    return new DOMParser().parseFromString(question, 'text/html').body.textContent;
                }else{
                    return question;
                }

            },
            submit() {

                // validate
                this.$store.commit('setError', []);

                let unanswered = [];

                this.questions.forEach((q, i) => {
                    let answerSelected = false;
                    this.questions[i].questionAnswers.forEach((a, j) => {
                        if(a.selected) {
                            answerSelected = true;
                        }
                    });
                    if(!answerSelected) {
                        unanswered.push(i + 1);
                    }
                });

                if(unanswered.length > 0) {
                    // invalid
                    let msg = 'Please select at least one answer for question(s) ' + unanswered.join(', ');
                    this.$store.commit('setError', msg);
                } else {
                    // valid

                    const data = {
                        answers: this.getSelectedAnswers(), // array of answer id's
                        company_policy_id: this.exam.company_policy_id,
                        company_test_id: this.$route.params.testId
                    };

                    this.loading = true;

                    this.$store.dispatch('submitExam', data).then((data) => {

                        this.$fire({
                            title: 'Pass',
                            type: 'success',
                            timer: 3000
                        });

                        this.$router.push('/my-policy-profile');

                        this.loading = false;
                    }).catch((e) => {

                        this.$fire({
                            title: 'Failed',
                            text: e,
                            type: 'error',
                            timer: 3000
                        });

                        this.$router.push('/my-policy-profile');

                    });
                }

            }
        },
        beforeRouteLeave (to, from, next) {
            console.log('leave route... ');
            this.stopCounter();
            next();
        },
        mounted() {

            this.loading = true;

            this.stopCounter();

            Promise.all([
                this.$store.dispatch('getCompanyTest', this.$route.params.testId),
                this.$store.dispatch('companyTestQuestions', this.$route.params.testId)
            ]).then((data) => {

                console.log(data);

                this.exam = data[0];
                this.questions = this.initQuestions(data[1].results);
                this.loading = false;
                // this.startCounter();
            });

        }
    }

</script>

<style lang="css">



</style>
