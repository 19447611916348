import Companies from '../views/Companies'
import CompaniesAdd from '../views/Companies/Add'
import CompaniesEdit from '../views/Companies/Edit'
import CompaniesView from '../views/Companies/View'
import CompanySiteEdit from '../views/Companies/Sites/Edit'
import CompanySiteAdd from '../views/Companies/Sites/Add'
import CompanyUserEdit from '../views/Companies/Users/Edit'
import CompanyUserAdd from '../views/Companies/Users/Add'
import CompanyPoliciesGenerate from '../views/Companies/Policies/Generate'
import CompanyTestsGenerate from '../views/Companies/Tests/Generate'
import CompanyProcessMapsGenerate from '../views/Companies/ProcessMaps/Generate'

// role = 'super_admin';
// role = 'company_admin';
// role = 'division_manager';
// role = 'site_manager';
// role = 'staff';

export default {
    routes: [
        {
            path: '/companies',
            name: 'Companies',
            component: Companies,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/companies/add',
            name: 'CompaniesAdd',
            component: CompaniesAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/companies/edit/:id',
            name: 'CompaniesEdit',
            component: CompaniesEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin', 'company_admin']
            }
        },
        {
            path: '/companies/view/:id',
            name: 'CompaniesView',
            component: CompaniesView,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        // sites
        {
            path: '/company/:companyId/site/add',
            name: 'CompanySiteAdd',
            component: CompanySiteAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin', 'company_admin']
            }
        },
        {
            path: '/company/:companyId/site/:siteId/edit',
            name: 'CompanySiteEdit',
            component: CompanySiteEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin', 'company_admin']
            }
        },
        // users
        {
            path: '/company/:companyId/users/add',
            name: 'CompanyUserAdd',
            component: CompanyUserAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin', 'company_admin']
            }
        },
        {
            path: '/company/:companyId/users/:userId/edit',
            name: 'CompanyUserEdit',
            component: CompanyUserEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin', 'company_admin']
            }
        },
        {
            path: '/company/:companyId/policies/generate',
            name: 'CompanyPoliciesGenerate',
            component: CompanyPoliciesGenerate,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/company/:companyId/tests/generate',
            name: 'CompanyTestsGenerate',
            component: CompanyTestsGenerate,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/company/:companyId/process-maps/generate',
            name: 'CompanyProcessMapsGenerate',
            component: CompanyProcessMapsGenerate,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin', 'company_admin']
            }
        }
    ]
}
