import axios from 'axios';

const actions = {
  getThreads({rootState}, data) {
    return new Promise((resolve, reject) => {

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
    axios.get(rootState.apiUrl + '/messages/' + data)
      .then((res) => {

                if(res.data.status) {
                    resolve(res.data);
                }else{
                    reject(res.data);
                }

      })
      .catch((e) => {
        reject(e);
      });
    });
  },
  getMessageThread({rootState}, data) {
      return new Promise((resolve, reject) => {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
      axios.get(rootState.apiUrl + '/message/' + data )
        .then((res) => {

                  if(res.data.status) {
                      resolve(res.data);
                  }else{
                      reject(res.data);
                  }

        })
        .catch((e) => {
          reject(e);
        });
      });
  },
  createMessageThread({rootState}, data) {
      return new Promise((resolve, reject) => {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
      axios.post(rootState.apiUrl + '/message/create', data )
        .then((res) => {

                  if(res.data.status) {
                      resolve(res.data);
                  }else{
                      reject(res.data);
                  }

        })
        .catch((e) => {
          reject(e);
        });
      });
  },
  sendMessage({rootState}, data) {
      return new Promise((resolve, reject) => {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
      axios.post(rootState.apiUrl + '/message/send', data )
        .then((res) => {

                  if(res.data.status) {
                      resolve(res.data);
                  }else{
                      reject(res.data);
                  }

        })
        .catch((e) => {
          reject(e);
        });
      });
  },
  getContacts({rootState}) {
      return new Promise((resolve, reject) => {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
      axios.get(rootState.apiUrl + '/contacts')
        .then((res) => {

                  if(res.data.status) {
                      resolve(res.data);
                  }else{
                      reject(res.data);
                  }

        })
        .catch((e) => {
          reject(e);
        });
      });
  },
  deleteThread({rootState}, data) {
    return new Promise((resolve, reject) => {

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
      axios.post(rootState.apiUrl + '/message/delete-thread', data )
        .then((res) => {

                  if(res.data.status) {
                      resolve(res.data);
                  }else{
                      reject(res.data);
                  }

        })
        .catch((e) => {
          reject(e);
        });
      });
  }
};

export default {
    actions
}
