<template>
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <bread-crumbs :links="[
                { name: 'Reports', href: '/admin/reports' },
                { name: 'Users', href: '/admin/reports/users' },
                { name: 'User Statistics' }
            ]" />

            <div class="flex justify-between items-center mb-8">
                <h1 class="hdln-1">Statistics for {{ userName }}</h1>
                <app-button @click="downloadCsv">Download CSV</app-button>
            </div>

            <policy-stats :stat="policy" v-for="policy in policies" />

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import AppButton from '../../../components/ui/AppButton'
import StatTable from '../../../components/StatTable'
import PolicyStats from '../../../components/reports/PolicyStats'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        StatTable,
        PolicyStats
    },
    computed: {
        userName() {
            return this.user.first_name + ' ' + this.user.last_name;
        }
    },
    data() {
        return {
            user: {
                first_name: '',
                last_name: ''
            },
            policies: [],
            loading: false
        };
    },
    methods: {
        downloadCsv() {
            this.$store.dispatch('userPoliciesReportCsv', this.$route.params.id).then((data) => {
                window.location.href = data.download_link;
            });
        },
    },
    mounted() {

        this.loading = true;

        this.$store.dispatch('getUser').then((loggedInUser) => {

            const data = {
                userId: this.$route.params.id,
                companyId: this.$store.state.user.currentCompany.id // loggedInUser.company_id
            };

            this.$store.dispatch('userPoliciesReport', this.$route.params.id).then((data) => {
                this.user = data.user;
                this.policies = data.policies;
                this.loading = false;
            });

        });

    }
}

</script>
