<template lang="html">
    <div class="prog-bar relative">
        <div class="prog-bar--inner text-white text-center"
             :class="'bg-' + color"
             :style="{ width: percent + '%' }"></div>
         <span class="text-sm text-center text-black absolute top-0.5 w-full complete-text">{{ percent }}% Complete</span>
    </div>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: 'secondary-dark'
            },
            percent: {
                type: Number,
                default: 35
            }
        }
    }
</script>

<style lang="scss">

.prog-bar {
    width: 100%;
    height: 25px;
    border-radius: 30px;
    padding: 2px;
    border: solid 1px #bcbcbc;
    background: #fff;
    &--inner {
        border-radius: 30px 3px 3px 30px;
        height: 19px;
        font-weight: 100;
    }
}

.complete-text {
    text-shadow: 0px 0px 2px white;
}

</style>
