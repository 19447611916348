<template>

    <div class="mb-6 p-3">

        <h2 class="text-xl font-bold mb-4 text-center">
            <slot></slot>
            <!-- <font-awesome-icon icon="angle-down" class="cursor-pointer" /> -->
        </h2>

        <div class="documents">
            <div v-for="doc in docs">
                <div v-if="doc.is_directory" class="mb-8">
                    <div class="flex justify-between items-center mb-3">
                        <p class="text-xl font-bold">{{ doc.title }}</p>
                        <!-- <font-awesome-icon icon="angle-down" class="cursor-pointer" /> -->
                    </div>
                    <doc-file :doc="subdoc" v-for="subdoc in doc.files" />
                </div>
                <doc-file :doc="doc" v-else />
            </div>
        </div>

    </div>

</template>
<script>

import IconButton from '../../components/ui/IconButton'
import DocFile from './DocFile'

export default {
    name: 'Companies',
    components: {
        IconButton,
        DocFile
    },
    methods: {
        download(download_link) {
            window.location.href = download_link;
        }
    },
    props: {
        docs: Array
    }
}

</script>
<style scoped>

    /* .documents {
        background: #fff;
        padding: 10px;
        border-radius: 5px;
    } */

</style>
