<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">Tests</h1>
            </div>

            <div :class="{ 'loading-white': examsLoading }">
                <exam-list base_link="/admin/tests/" :exams="exams" />
            </div>

        </div>

    </app-layout>
</template>
<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import Panel from '../../../components/ui/Panel'
    import BreadCrumbs from '../../../components/ui/BreadCrumbs'
    import AppButton from '../../../components/ui/AppButton'
    import IconButton from '../../../components/ui/IconButton'
    import AppTable from '../../../components/ui/AppTable'
    import PopUpConfirm from '../../../components/PopUpConfirm'
    import ExamList from '../../../components/exams/List'

    export default {
        components: {
            AppLayout,
            Panel,
            BreadCrumbs,
            AppButton,
            IconButton,
            AppTable,
            PopUpConfirm,
            ExamList
        },
        data() {
            return {
                loading: false,
                examsLoading: false,
                searchKeyword: '',
                exams: []
            }
        },
        methods: {
            // search() {
            //     this.examsLoading = true;
            //     const searchKeyword = this.searchKeyword;
            //     this.$store.dispatch('generatedTests', { testSetId, searchKeyword }).then((exams) => {
            //         this.exams = exams.ListExam;
            //         this.examsLoading = false;
            //     });
            // }
        },
        mounted() {

            this.loading = true;
            this.$store.dispatch('getUser').then((data) => {

                const companyId = this.$store.state.user.currentCompany.id; // this.$store.state.user.user.company_id;

                this.$store.dispatch('generatedTests', companyId).then((tests) => {
                    this.exams = tests;
                    this.loading = false;
                }).catch(() => { this.loading = false; });

            }).catch(() => { this.loading = false; });

        }
    }

</script>
