<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white': pageLoading }">

            <bread-crumbs :links="[
                { name: 'Master Test Sets', href: '/master-test-sets' },
                { name: testSet.name + ' Exams', href: '/master-test-sets/' + testSet.id + '/exams' },
                { name: 'Edit Exam' }
            ]" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">Edit Exam</h1>
            </div>

            <app-form @onSubmit="submit" ref="form" :class="{ 'loading-white': formLoading }" />

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import AppButton from '../../../components/ui/AppButton'
import IconButton from '../../../components/ui/IconButton'
import AppTable from '../../../components/ui/AppTable'
import PopUpConfirm from '../../../components/PopUpConfirm'
import AppForm from './AppForm'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        IconButton,
        AppTable,
        AppForm
    },
    data() {
        return {
            pageLoading: false,
            formLoading: false,
            testSet: {
                id: null,
                name: ''
            }
        }
    },
    methods: {
        submit(data) {

            data.id = this.$route.params.examId;
            this.formLoading = true;

            this.$store.dispatch('updateExam', data).then((success) => {
                if(success) {

                    this.$fire({
                        title: "Exam Updated",
                        text: success.message,
                        type: "success",
                        timer: 3000
                    });

                    this.formLoading = false;

                    this.$router.push('/master-test-sets/' + this.$route.params.id + '/exams');
                }
            }).catch(() => { this.formLoading = false; });

        }
    },
    mounted() {

        this.pageLoading = true;

        Promise.all([
            this.$store.dispatch('getMasterTestSet', this.$route.params.id),
            this.$store.dispatch('getExam', this.$route.params.examId)
        ]).then((data) => {

            this.testSet = data[0];
            this.$refs.form.setForm({
                exam_title: data[1].exam_title,
                policy_id: data[1].policy_id,
                exam_marks: data[1].exam_marks,
                pass_score: data[1].pass_score,
                exam_description: data[1].exam_description,
                expected_duration: data[1].expected_duration,
                expiration_date: data[1].expiration_date
            });
            this.pageLoading = false;

        });

    }
}

</script>
