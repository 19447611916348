<template lang="html">
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Manage Master Test Sets</h1>
            <router-link to="/master-test-sets/add">
                <app-button>Add Master Test Set</app-button>
            </router-link>
        </div>

        <app-table :class="{ 'loading-white': $store.state.loading }">
            <template #thead>
                <tr class="text-white">
                    <th>Name</th>
                    <th>Policy Set Name</th>
                    <th></th>
                </tr>
            </template>
            <template #tbody>
                <tr v-for="testSet in masterTestSets">
                    <td>{{ testSet.name }}</td>
                    <td>{{ (testSet.master_policy_set ? testSet.master_policy_set.title : '' ) }}</td>
                    <td>
                        <div class="flex justify-end">
                            <router-link :to="'/master-test-sets/' + testSet.id + '/edit'">
                                <icon-button icon="edit" color="secondary" class="ml-2" />
                            </router-link>
                            <router-link :to="'/master-test-sets/' + testSet.id + '/duplicate'">
                                <icon-button icon="copy" color="danger" class="ml-2" />
                            </router-link>
                            <router-link :to="'/master-test-sets/' + testSet.id + '/exams'">
                                <icon-button icon="eye" color="secondary" class="ml-2" />
                            </router-link>
                            <icon-button icon="trash-alt" color="danger" class="ml-2" @click="areYouSureOpen = true; currentDeleteId = testSet.id" />
                        </div>
                    </td>
                </tr>
            </template>
        </app-table>

        <pop-up-confirm message="Are you sure you want to delete this master test set?"
                        :isOpen="areYouSureOpen"
                        :id="currentDeleteId"
                        @canceled="areYouSureOpen = false"
                        @confirmed="deleteTestSet" />

    </app-layout>
</template>

<script>

    import AppLayout from '../../components/layout/AppLayout'
    import Panel from '../../components/ui/Panel'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import AppTable from '../../components/ui/AppTable'
    import PopUpConfirm from '../../components/PopUpConfirm'

    export default {
        components: {
            AppLayout,
            Panel,
            AppButton,
            IconButton,
            AppTable,
            PopUpConfirm
        },
        data() {
            return {
                areYouSureOpen: false,
                currentDeleteId: null,
                // searchKeyword: '',
                masterTestSets: []
            }
        },
        methods: {
            // search() {
            //     this.$store.dispatch('getMasterTestSets', this.searchKeyword).then((masterTestSets) => {
            //         this.masterTestSets = masterTestSets;
            //     });
            // },
            getTestSets(done) {
                this.$store.dispatch('getMasterTestSets').then((masterTestSets) => {

                    console.log('masterTestSets ', masterTestSets);

                    this.masterTestSets = masterTestSets;
                    if(done) {
                        done();
                    }
                });
            },
            deleteTestSet() {
                this.areYouSureOpen = false;
                console.log('this.currentDeleteId ', this.currentDeleteId);
                this.$store.dispatch('deleteMasterTestSet', this.currentDeleteId).then((success) => {
                    this.getTestSets(() => {
                        this.$fire({
                            title: "Master Test Set Deleted",
                            text: success.message,
                            type: "success",
                            timer: 3000
                        });
                    });
                });
            }
        },
        mounted() {
            this.getTestSets();
        }
    }

</script>

<style lang="css">



</style>
