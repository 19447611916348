<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Manage User Policy Profile', href: '/admin/staff-policy-profiles' },
            { name: user.first_name + ' ' + user.last_name }
        ]" />

        <div :class="{ 'loading-white' : loading }">

            <panel class="p-4">
                <div class="md:flex justify-start items-center text-left">
                    <div class="md:mr-4">
                        <avatar size="lg" :imageSrc="user.profile_picture" />
                    </div>
                    <div>
                        <p class="font-bold text-primary text-lg">{{ user.first_name }} {{ user.last_name }}</p>
                        <p>{{ user.company_policy_count }} policies are available at your subscription level. You currently have {{ user.user_policy_count }} policies in your policy profile.</p>
                    </div>
                </div>
            </panel>

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Policy</th>
                        <th>Lesson</th>
                        <th>Status</th>
                        <th class="text-right">
                            <input class="bg-primary border-b placeholder-white focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search" />
                            <font-awesome-icon icon="search" class="cursor-pointer" @click="loadDetails" />
                        </th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="policy in policies">
                        <td>{{ policy.policy_title }}</td>
                        <td>{{ policy.lesson_count }}</td>
                        <td class="text-secondary-dark">{{ policy.status }}%</td>
                        <td class="text-right">
                            <app-button v-if="policy.action === 'remove'" color="danger" @click="removeFromProfile(policy)">Remove From Profile</app-button>
                            <app-button v-else color="secondary" @click="addToProfile(policy)">Add To Profile</app-button>
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>
    </app-layout>
</template>
<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import AppButton from '../../../components/ui/AppButton'
    import IconButton from '../../../components/ui/IconButton'
    import Panel from '../../../components/ui/Panel'
    import BreadCrumbs from '../../../components/ui/BreadCrumbs'
    import AppTable from '../../../components/ui/AppTable'
    import Avatar from '../../../components/Avatar'

    export default {
        components: {
            AppLayout,
            AppButton,
            IconButton,
            Panel,
            BreadCrumbs,
            AppTable,
            Avatar
        },
        data() {
            return {
                user: {
                    first_name: '',
                    last_name: ''
                },
                policies: [],
                loading: false,
                searchKeyword: ''
            };
        },
        methods: {
            addToProfile(policy) {
                this.loading = true;
                const data = {
                    company_policy_id: policy.policy_id,
                    user_id: this.$route.params.id
                };
                this.$store.dispatch('addPolicyToProfile', data).then((res) => {
                    this.loadDetails();
                });
            },
            removeFromProfile(policy) {
                this.loading = true;
                const data = {
                    company_policy_id: policy.policy_id,
                    user_id: this.$route.params.id
                };
                this.$store.dispatch('removePolicyFromProfile', data).then((res) => {
                    this.loadDetails();
                });
            },
            loadDetails() {
                this.loading = true;
                const data = {
                    searchKeyword: this.searchKeyword,
                    userId: this.$route.params.id
                };
                this.$store.dispatch('getUserPolicyDetails', data).then((data) => {
                    this.user = data.data;
                    this.policies = data.policy_data;
                    this.loading = false;
                });
            }
        },
        mounted() {
            this.loading = true;
            this.$store.dispatch('getUser').then((user) => {
                this.loadDetails();
            });
        }
    }

</script>
