<template>

    <div class="bg-danger text-white text-center p-6 fixed w-full md:w-3/5 lg:w-4/5 right-0 bottom-0 errorbox" v-if="showError">
        <p v-for="e in errors">{{ formattedError(e) }}</p>
    </div>

</template>

<script>
    export default {
        props: {
            errors: Array
        },
        computed: {
            showError() {
                if(this.errors.length > 0) {
                    return true;
                }
            }
        },
        methods: {
            formattedError(e) {
                const limit = 200;
                return (e.length > limit) ? e.substr(0, limit-1) + '&hellip;' : e;
            }
        }
    }
</script>

<style lang="css" scoped>

    .errorbox {
        box-shadow: 0px 0px 10px #0003;
        z-index: 10;
    }

</style>
