<template>
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Overview Statistics</h1>
        </div>

        <div :class="{ 'loading-white': $store.state.loading }" style="min-height: 400px">
            <super-admin-stats ref="superAdminStats" />
            <admin-stats ref="adminStats" />
            <staff-stats ref="staffStats" />
            <chat-box />
        </div>
    </app-layout>
</template>
<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import PercentageCircle from '../../components/ui/PercentageCircle'
import SuperAdminStats from '../../components/dashboard/SuperAdminStats'
import AdminStats from '../../components/dashboard/AdminStats'
import StaffStats from '../../components/dashboard/StaffStats'
import StatBox from '../../components/dashboard/StatBox'
import ChatBox from '../../components/dashboard/ChatBox'
// import PercentageCircle from 'vue-css-percentage-circle'

export default {
    name: 'Dashboard',
    components: {
        AppLayout,
        Panel,
        PercentageCircle,
        SuperAdminStats,
        AdminStats,
        StaffStats,
        StatBox,
        ChatBox,
    },
    mounted() {

        this.$store.dispatch('getUser').then(() => {
            switch(this.$store.state.user.user.job_title_id) {
                case 1:
                    // super_admin
                    this.$refs.superAdminStats.getData(() => {});
                    break;
                case 2:
                case 3:
                case 4:
                    // company_admin
                    this.$refs.adminStats.getData(() => {});
                    break;
                default:
                    // staff
                    this.$refs.staffStats.getData(() => {});
            }
        });

        console.log(this.$store.state.user.user)
    }
}

</script>
