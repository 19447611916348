<template lang="html">
    <div :class="{ hidden: !loaded }">

        <!-- <app-table>
            <template #thead>
                <tr class="text-white">
                    <th>Assigned Policy</th>
                    <th></th>
                </tr>
            </template>
            <template #tbody>
                <tr class="text-white">
                    <td></td>
                </tr>
            </template>
        </app-table> -->

        <div class="flex flex-wrap">
            <stat-box type="number" :amount="assignedPolicies">Assigned Policies</stat-box>
            <stat-box type="percentage" :amount="policiesRead">Policies Read</stat-box>
            <stat-box type="percentage" :amount="testsPassed">Tests Passed</stat-box>
        </div>

        <!-- <div class="flex flex-wrap">
            <stat-box type="percentage" :amount="staffCompletedAllPolicies">Staff who completed all allocated policies</stat-box>
            <stat-box type="percentage" :amount="staffCompletedAllTests">Staff who have completed all required tests</stat-box>
            <stat-box type="percentage" :amount="policiesCompletedByAllStaff">Policies completed by all staff</stat-box>
        </div> -->

    </div>
</template>

<script>

    import StatBox from './StatBox'
    import AppTable from '../ui/AppTable'

    export default {
        components: {
            StatBox,
            AppTable
        },
        data() {
            return {
                loaded: false,
                assignedPolicies: 0,
                policiesRead: 0,
                testsPassed: 0
            }
        },
        methods: {
            getData(done) {
                this.$store.dispatch('getStaffStats').then((data) => {

                    console.log(data);

                    this.assignedPolicies = data.assignedPolicies;
                    this.policiesRead = data.policiesRead;
                    this.testsPassed = data.testsPassed;
                    this.loaded = true;
                    done();
                });
            }
        }
    }

</script>

<style lang="css">



</style>
