<template>
    <div>
        <router-link :class="classes" :to="outputLink(link.href)" v-if="link.href">
            <font-awesome-icon :icon="link.icon" class="mr-3" />
            {{ link.name }}
        </router-link>
        <div :class="classes" v-else>
            <font-awesome-icon :icon="link.icon" class="mr-3" />
            <span>{{ link.name }}</span>
            <font-awesome-icon icon="angle-down" class="ml-auto" :class="{ 'transform rotate-180': link.open }" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        link: Object
    },
    methods: {
        outputLink(href) {
            const user = this.$store.getters.getUser;
            let companyId = '';
            if(user) {
                companyId = user.company_id;
            }
            return href.replace('{companyId}', companyId);
        }
    },
    computed: {
        classes() {
            let c = 'link hover:bg-secondary-lighter px-4 py-2 flex items-center';
            if(this.link.open) {
                c += ' is-open';
            }
            return c;
        }
    }
}
</script>

<style lang="css" scoped>

.link {
    width: 100%;
    cursor: pointer;
}

.is-open,
.is-open:hover {
    background: #395415;
}

</style>
