import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    getCompanyPolicies({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-policy/detail',
                data: {
                    company_id: data.company_id,
                    searchKeyword: data.searchKeyword
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    revertCompanyPolicies({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-policy/revert-policy',
                data: {
                    company_id: data.company_id,
                    company_policy_id: data.company_policy_id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getCompanyLesson({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-lesson/detail',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    updateCompanyLesson({commit, rootState, getters}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-lesson/update',
                data: {
                    company_id: getters.currentCompany,
                    company_policy_id: data.get('company_policy_id'),
                    description: data.get('description'),
                    expected_duration: data.get('expected_duration'),
                    id: data.get('id'),
                    lessonModule: getters.lessonModulesData,
                    sort_order: data.get('sort_order'),
                    status: 1,
                    title: data.get('title')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getCompanyPolicy({commit, rootState}) {

        const data = {
            company_id: rootState.user.currentCompany.id,
            user_id: rootState.user.user.id
        };

        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/company-policy/get-company-policy-with-count', data)
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(res.data);
                    }

                    resolve(res.data.data);
                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });

    },
    generarePolicyPDF({commit, rootState}, data) {

        let companyId = rootState.user.currentCompany.id;

        if(!companyId) {
            companyId = data.companyId;
        }

        console.log('companyID ', companyId);

        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-policy/save-pdf-lesson-component',
                data: {
                    company_policy_id: data.policyId,
                    company_id: companyId // data.companyId
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
	actions
}
