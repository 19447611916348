import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    getComplianceLibrary({commit, rootState}) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/compliance-library',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
};

export default {
    actions
}
