<template lang="html">

    <div>

        <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg">Policies</h2>
            <div class="flex">
                <router-link :to="'/company/' + companyId + '/policies/generate'">
                    <app-button>Generate Policies</app-button>
                </router-link>
            </div>
        </div>

        <div class="relative">

            <app-table :class="{ 'loading-white' : loading }">
                <template #thead>
                    <tr class="text-white">
                        <th>Policy</th>
                        <th></th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="policy in generatedPolicies">
                        <td>{{ policy.policy_title }}</td>
                        <td class="flex justify-end">
                            <icon-button size="sm" icon="file-pdf" color="secondary" class="mr-2" @click="generatePDF(policy.id)" />
                            <icon-button size="sm" icon="trash-alt" color="danger" @click="deleteGeneratedPolicy(policy.id)" />
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

    </div>

</template>

<script>

    import AppTable from '../../components/ui/AppTable'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import Modal from '../../components/ui/Modal'
    import UploadedLogo from '../../components/UploadedLogo'

    export default {
        components: {
            AppTable,
            AppButton,
            IconButton,
            Modal,
            UploadedLogo
        },
        props: {
            companyId: Number
        },
        data() {
            return {
                loading: false,
                generatedPolicies: []
            }
        },
        methods: {
            deleteGeneratedPolicy(policyId) {
                this.$confirm("Are you sure you want to delete this policy?").then(() => {

                    const data = {
                        policyId,
                        companyId: this.companyId
                    };

                    this.$store.dispatch('companyPolicyDelete', data).then(() => {
                        this.$store.dispatch('generatedCompanyPolicies', this.companyId).then((data) => {
                            this.generatedPolicies = data.ListPolicy;
                            this.$fire({
                                title: "Policy deleted",
                                text: "",
                                type: "success",
                                timer: 3000
                            });
                        });
                    });

                });
            },
            getPolicies() {
                this.$store.dispatch('generatedCompanyPolicies', this.companyId).then((data) => {
                    this.generatedPolicies = data.ListPolicy;
                });
            },
            generatePDF(policyId) {
                this.loading = true;
                this.$store.dispatch('generarePolicyPDF', { policyId, companyId: this.companyId }).then((data) => {
                    window.location.href = data;
                    this.loading = false;
                });
            }
        }
    }

</script>

<style lang="css">

</style>
