<template>
    <app-layout>

        <div>

            <div class="flex justify-between mb-8 items-start">
                <h1 class="hdln-1">Manage User Policy Profile</h1>
                <app-button @click="openBulkAssignModal">Bulk Assign</app-button>
            </div>

            <div class="text-right mb-2">
                <input class="border-b transparent placeholder-black focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search" />
                <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
            </div>

            <app-table :class="{ 'loading-white': loading }">
                <template #thead>
                    <tr class="text-white">
                        <th>User</th>
                        <th>Job Role</th>
                        <th>Assigned Policies</th>
                        <th>
                            <div class="flex justify-end">
                                <fake-checkbox id="policies_all" :checked="isAllSelected" @changed="toggleSelectAll" :is_light="true">
                                    Select All
                                </fake-checkbox>
                            </div>
                        </th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="user in users">
                        <td>
                            <div class="flex items-center">
                                <avatar size="sm" :imageSrc="(user.profile_picture ? uploadsPath + '/' + user.profile_picture : null)" />
                                <span class="ml-2">{{ user.first_name + ' ' + user.last_name }}</span>
                            </div>
                        </td>
                        <td>{{ user.job_title_name }}</td>
                        <td>{{ user.policy_count }}</td>
                        <td>
                            <div class="flex justify-end">
                                <router-link :to="'/admin/staff-policy-profiles/' + user.id">
                                    <icon-button icon="edit" color="secondary" />
                                </router-link>
                                <fake-checkbox class="ml-4" :id="'user_' + user.id" :checked="isSelected(user.id)" @changed="toggleUser(user.id)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

        <modal header="Bulk Assign" :isOpen="bulkAssignOpen" @closed="bulkAssignOpen = false" class="modal-sm" :class="{ 'loading-white': bulkAssignLoading }">

            <!-- <form-group class="w-full md:pr-2 mb-4">
                <form-label :required="true">Policy</form-label>
                <select class="form-select" v-model="bulkAssignForm.policy_id">
                    <option value="">Choose Policy</option>
                    <option v-for="policy in policies" :value="policy.id">{{ policy.policy_title }}</option>
                </select>
                <validation-message :show="showErrors && bulkAssignForm.policy_id === ''">policy required</validation-message>
            </form-group> -->
            <div class="flex justify-end">
                <fake-checkbox id="policies_all_user" :checked="isAllPoliciesSelected" @changed="toggleSelectAllPolicies">
                    Select All
                </fake-checkbox>
            </div>
            <p class="text-primary mb-2">Policies </p>

            <table class="w-full mb-4">
                <tr v-for="policy in policies" class="border-b border-gray-300">
                    <td>{{ policy.policy_title }}</td>
                    <td>
                        <fake-checkbox class="ml-4" :id="'policy_' + policy.id" :checked="isPolicySelected(policy.id)" @changed="togglePolicy(policy.id)" />
                    </td>
                </tr>
            </table>

            <div class="flex justify-center items-center">
                <app-button @click="bulkAssign">Assign Policies to Selected Users</app-button>
            </div>

        </modal>

    </app-layout>
</template>
<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import AppButton from '../../../components/ui/AppButton'
    import IconButton from '../../../components/ui/IconButton'
    import Panel from '../../../components/ui/Panel'
    import Modal from '../../../components/ui/Modal'
    import AppTable from '../../../components/ui/AppTable'
    import Avatar from '../../../components/Avatar'
    import FakeCheckbox from '../../../components/forms/FakeCheckbox'
    import FormGroup from '../../../components/forms/FormGroup'
    import FormLabel from '../../../components/forms/FormLabel'
    import ValidationMessage from '../../../components/forms/ValidationMessage'

    export default {
        components: {
            AppLayout,
            AppButton,
            IconButton,
            Panel,
            Modal,
            AppTable,
            Avatar,
            FakeCheckbox,
            FormGroup,
            FormLabel,
            ValidationMessage
        },
        data() {
            return {
                loading: false,
                uploadsPath: '',
                users: [],
                policies: [],
                selectedUsers: [],
                selectedPolicies: [],
                bulkAssignLoading: false,
                bulkAssignOpen: false,
                bulkAssignForm: {
                    policy_id: ''
                },
                showErrors: false,
                searchKeyword: '',
                searchedKeyword: ''
            };
        },
        computed: {
            isAllSelected() {
                return (this.selectedUsers.length === this.users.length);
            },
            isAllPoliciesSelected() {
                return (this.selectedPolicies.length === this.policies.length);
            }
        },
        methods: {
            toggleUser(id) {
                if(this.isSelected(id)) {
                    var index = this.selectedUsers.indexOf(id);
                    if (index !== -1) {
                        this.selectedUsers.splice(index, 1);
                    }
                }else{
                    this.selectedUsers.push(id);
                }
            },
            isSelected(id) {
                return this.selectedUsers.includes(id);
            },
            togglePolicy(id) {
                if(this.isPolicySelected(id)) {
                    var index = this.selectedPolicies.indexOf(id);
                    if (index !== -1) {
                        this.selectedPolicies.splice(index, 1);
                    }
                }else{
                    this.selectedPolicies.push(id);
                }
            },
            isPolicySelected(id) {
                return this.selectedPolicies.includes(id);
            },
            toggleSelectAll() {
                if(this.isAllSelected) {
                    this.deselectAll('user');
                }else{
                    this.selectAll('user');
                }
            },
            toggleSelectAllPolicies() {
                if(this.isAllPoliciesSelected) {
                    this.deselectAll('policy');
                }else{
                    this.selectAll('policy');
                }
            },
            selectAll(type) {
                if(type == 'user') {
                    this.selectedUsers = [];
                    this.users.forEach((u, i) => {
                        this.selectedUsers.push(u.id);
                    });
                } else if (type == 'policy') {

                    this.selectedPolicies = [];
                    this.policies.forEach((u, i) => {
                        this.selectedPolicies.push(u.id);
                    });
                }
            },
            deselectAll(type) {
                if(type == 'user') {
                    this.selectedUsers = [];
                } else if (type == 'policy') {

                    this.selectedPolicies = [];
                }

            },
            openBulkAssignModal() {
                if(this.selectedUsers.length > 0) {
                    this.bulkAssignOpen = true;
                }else{
                    this.$fire({
                        title: "Select at least one user to assign the policy to",
                        type: "error",
                        timer: 3000
                    });
                }
            },
            bulkAssign() {

                console.log('YEAH?');

                if(this.selectedPolicies.length > 0 !== '' && this.selectedUsers.length > 0) {
                    this.bulkAssignLoading = true;
                    const data = {
                        users: this.selectedUsers,
                        policies: this.selectedPolicies
                    };
                    this.$store.dispatch('bulkAssignPolicy', data).then(() => {
                        this.loadPolicyProfiles();
                        this.bulkAssignLoading = false;
                        this.bulkAssignOpen = false;
                    });
                }else{
                    this.showErrors = true;
                }

            },
            loadPolicyProfiles(done) {
                this.loading = true;
                const company_id = this.$store.state.user.currentCompany.id;
                Promise.all([
                    this.$store.dispatch('getPolicyProfiles', company_id),
                    this.$store.dispatch('getCompanyPolicies', { company_id })
                ]).then((data) => {
                    this.uploadsPath = data[0].uploadsPath;
                    this.users = data[0].users;
                    this.policies = data[1].ListPolicy;
                    this.loading = false;

                    if(done) {
                        done();
                    }

                }).catch(() => { this.loading = false; });
            },
            search() {

                const keyword = this.searchKeyword.toLowerCase();
                this.searchedKeyword = '';

                this.loadPolicyProfiles(() => {

                    if(keyword !== '') {
                        let filteredUsers = [];
                        this.users.forEach((user, i) => {

                            let userAdded = false;

                            if( user.first_name.toLowerCase().includes(keyword) ) {
                                if(!userAdded) {
                                    filteredUsers.push(user);
                                    userAdded = true;
                                }
                            }

                            if( user.last_name.toLowerCase().includes(keyword) ) {
                                if(!userAdded) {
                                    filteredUsers.push(user);
                                    userAdded = true;
                                }
                            }

                        });
                        this.users = filteredUsers;
                        this.searchedKeyword = this.searchKeyword;
                    }

                });

            }
        },
        mounted() {

            this.loading = true;
            this.$store.dispatch('getUser').then((data) => {

                this.loadPolicyProfiles();

            }).catch(() => { this.loading = false; });

        }
    }

</script>
<style scoped>

    .transparent { background: transparent; }

    .border-gray-300 { border-color: #eee; }

</style>
