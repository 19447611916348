import AdminCompany from '../views/Admin/Company'
import AdminProcessMaps from '../views/Admin/ProcessMaps'
import AdminProcessMapsEdit from '../views/Admin/ProcessMaps/Edit'
import PolicyLibrary from '../views/Admin/Policies/Library'
import PolicyManagement from '../views/Admin/Policies/Management'
import LessonEdit from '../views/Admin/Lessons/Edit'
import StaffPolicyProfiles from '../views/Admin/StaffPolicyProfiles'
import StaffPolicyProfileEdit from '../views/Admin/StaffPolicyProfiles/Edit'
import Tests from '../views/Admin/Tests'
import Questions from '../views/Admin/Tests/Questions'
import QuestionsAdd from '../views/Admin/Tests/Questions/Add'
import QuestionsEdit from '../views/Admin/Tests/Questions/Edit'

export default {
    routes: [
        {
            path: '/admin/company',
            name: 'AdminCompany',
            component: AdminCompany,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/process-maps',
            name: 'AdminProcessMaps',
            component: AdminProcessMaps,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/process-maps/:id/edit',
            name: 'AdminProcessMapsEdit',
            component: AdminProcessMapsEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/policies/library',
            name: 'PolicyLibrary',
            component: PolicyLibrary,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/policies/management',
            name: 'PolicyManagement',
            component: PolicyManagement,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/lesson/:id/edit',
            name: 'LessonEdit',
            component: LessonEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/staff-policy-profiles',
            name: 'StaffPolicyProfiles',
            component: StaffPolicyProfiles,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/staff-policy-profiles/:id',
            name: 'StaffPolicyProfiles',
            component: StaffPolicyProfileEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/tests',
            name: 'Tests',
            component: Tests,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/tests/:examId/questions',
            name: 'AdminQuestions',
            component: Questions,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/tests/:examId/questions/add',
            name: 'AdminQuestionsAdd',
            component: QuestionsAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        },
        {
            path: '/admin/tests/:examId/questions/:questionId/edit',
            name: 'AdminQuestionsEdit',
            component: QuestionsEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['company_admin']
            }
        }
    ]
}
