<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'My Policy Profile', href: '/my-policy-profile' },
            { name: 'Process Maps' }
        ]" />

        <h1 class="hdln-1 mb-8">Process Maps for Policy</h1>

        <div :class="{ 'loading-white' : loading }">

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Name</th>
                        <th class="text-right"></th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="(processMap, i) in processMaps">
                        <td>{{ processMap.process_map_title }}</td>
                        <td>
                            <div class="flex justify-end">
                                <icon-button color="secondary" icon="clipboard-list" class="ml-2" @click="showInstructions(i)" />
                                <icon-button color="secondary" icon="eye" class="ml-2" @click="showMap(i)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </app-table>

            <modal header="See Instructions"
                   :isOpen="instructionsOpen"
                   v-if="currentProcessMap"
                   @closed="instructionsOpen = false">
                <p>{{ currentProcessMap.instructions }}</p>
            </modal>

            <modal header="View Document"
                   :isOpen="documentOpen"
                   v-if="currentProcessMap"
                   @closed="documentOpen = false">
               <iframe :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + currentProcessMap.document" style="width: 100%; height: 500px;" />
            </modal>

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../components/layout/AppLayout'
import AppTable from '../../components/ui/AppTable'
import AppButton from '../../components/ui/AppButton'
import IconButton from '../../components/ui/IconButton'
import Panel from '../../components/ui/Panel'
import Modal from '../../components/ui/Modal'
import BreadCrumbs from '../../components/ui/BreadCrumbs'

export default {
    components: {
        AppLayout,
        AppButton,
        IconButton,
        AppTable,
        Panel,
        Modal,
        BreadCrumbs
    },
    methods: {
        showInstructions(i) {
            this.currentProcessMap = this.processMaps[i];
            this.instructionsOpen = true;
        },
        showMap(i) {
            this.currentProcessMap = this.processMaps[i];
            this.documentOpen = true;
        },
    },
    data() {
        return {
            loading: false,
            processMaps: [],
            companyPolicy: {
                title: ''
            },
            instructionsOpen: false,
            documentOpen: false,
            currentProcessMap: null
        };
    },
    mounted() {

        this.loading = true;

        const policyId = this.$route.params.policyId;

        Promise.all([
            this.$store.dispatch('getGeneratedProcessMapsByCompanyPolicyId', policyId)
        ]).then((data) => {
            this.processMaps = data[0];
            console.log('this.processMaps ', this.processMaps);
            // this.companyPolicy = data[1];
            this.loading = false;
        });

    }
}

</script>
<style scoped>



</style>
