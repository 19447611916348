<template>
  <div class="text-xs px-3 sticky top-0 bg-blue text-black w-full shadow-sm">
    <input
      @keyup="filter"
      v-model="search"
      class="p-2 w-full border rounded-md outline-none inline-block"
      autocomplete="off"
      placeholder="Search Contacts"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: null,
    };
  },
  methods: {
    filter() {
      this.$emit('search', this.search)
    }
  }
};
</script>