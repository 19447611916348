<template>
    <app-layout>

        <div :class="{ 'loading-white': $store.state.loading }">

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">Process Maps</h1>
            </div>

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Name</th>
                        <th class="text-right">
                            <input class="bg-primary border-b placeholder-white focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search" />
                            <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
                        </th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="(processMap, i) in generatedProcessMaps">
                        <td>{{ processMap.process_map_title }}</td>
                        <td>
                            <div class="flex justify-end">
                                <router-link :to="'/admin/process-maps/' + processMap.company_policy_id + '/edit'">
                                    <icon-button color="secondary" icon="edit" />
                                </router-link>
                                <icon-button color="secondary" icon="clipboard-list" class="ml-2" @click="showInstructions(i)" />
                                <icon-button color="secondary" icon="eye" class="ml-2" @click="showMap(i)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </app-table>

            <modal header="See Instructions"
                   :isOpen="instructionsOpen"
                   v-if="currentProcessMap"
                   @closed="instructionsOpen = false">
                <p>{{ currentProcessMap.instructions }}</p>
            </modal>

            <modal header="View Document"
                   :isOpen="documentOpen"
                   v-if="currentProcessMap"
                   @closed="documentOpen = false">
               <iframe :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + currentProcessMap.document" style="width: 100%; height: 500px;" />
            </modal>

        </div>

    </app-layout>
</template>

<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import AppTable from '../../../components/ui/AppTable'
    import IconButton from '../../../components/ui/IconButton'
    import Modal from '../../../components/ui/Modal'

    export default {
        components: {
            AppLayout,
            AppTable,
            IconButton,
            Modal
        },
        data() {
            return {
                searchKeyword: '',
                generatedProcessMaps: [],
                instructionsOpen: false,
                documentOpen: false,
                currentProcessMap: null,
                companyId: null
            };
        },
        methods: {
            showInstructions(i) {
                this.currentProcessMap = this.generatedProcessMaps[i];
                this.instructionsOpen = true;
            },
            showMap(i) {
                this.currentProcessMap = this.generatedProcessMaps[i];
                this.documentOpen = true;
            },
            search() {
                const data = {
                    companyId: this.companyId,
                    searchKeyword: this.searchKeyword
                };
                this.$store.dispatch('generatedProcessMaps', data).then((maps) => {

                    console.log('maps ', maps);

                    this.generatedProcessMaps = maps;
                });
            }
        },
        mounted() {
            this.$store.dispatch('getUser').then((data) => {
                this.companyId = this.$store.state.user.currentCompany.id; // data.data.company_id;
                this.search();
            });
        }
    }

</script>

<style lang="css">
</style>
