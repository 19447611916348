import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    masterPolicySetList({commit, rootState}, searchKeyword) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy-set/list',
                data: { searchKeyword },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicySetAdd({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy-set/create',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicySetUpdate({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.put(commit, rootState, {
                url: '/master-policy-set/update',
                data: {
                    id: data.get('id'),
                    title: data.get('title'),
                    description: data.get('description')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicySetGet({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy-set/id',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicySetDuplicate({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy-set/duplicate',
                data: {
                    id: data.get('id'),
                    description: data.get('description'),
                    title: data.get('title'),
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicySetDelete({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy-set/delete',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicySetPolicies({commit, rootState}, data) {
        //    /master-policy-set/policy-list-by-single-policy-set
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy-set/policy-list-by-single-policy-set',
                data: {
                    master_policy_set_id: data.master_policy_set_id,
                    search: data.search
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
	actions
}
