<template lang="html">
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Process Maps', href: '/process-maps' },
            { name: 'Add Process Map' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Add Process Map</h1>
        </div>

        <panel class="p-4" :class="{ 'loading-white': $store.state.loading }">

            <form-group class="w-full mb-4">
                <form-label :required="true">Process Map Name</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('process_map_title') && showErrors }"
                       v-model="form.process_map_title.value" />
                <validation-message :show="showErrors && !isValid('process_map_title')">process map name required</validation-message>
            </form-group>

            <form-group class="w-full mb-4">
                <form-label :required="true">Master Policy Set</form-label>
                <select v-model="form.policy_set_id.value"
                        class="form-input"
                        @change="getPolicies"
                        :class="{ invalid: !isValid('policy_set_id') && showErrors }">
                    <option value="">Choose policy set</option>
                    <option :value="policySet.id" v-for="policySet in masterPolicySets">{{ policySet.title }}</option>
                </select>
                <validation-message :show="showErrors && !isValid('policy_set_id')">master policy set required</validation-message>
            </form-group>

            <form-group class="w-full mb-4">
                <form-label :required="true">Policy Name</form-label>
                <select v-model="form.policy_id.value"
                        class="form-input"
                        :class="{ invalid: !isValid('policy_id') && showErrors }">
                    <option value="">Choose policy set</option>
                    <option :value="policy.id" v-for="policy in policies">{{ policy.policy_title }}</option>
                </select>
                <validation-message :show="showErrors && !isValid('policy_id')">master policy set required</validation-message>
            </form-group>

            <form-group class="w-full mb-4">
                <form-label :required="true">Process Instructions</form-label>
                <textarea class="form-input" v-model="form.instructions.value"></textarea>
            </form-group>

            <div v-if="fileUpload.preview">
                <font-awesome-icon icon="check" class="logo-icon text-secondary" />
            </div>
            <div v-else>
                <font-awesome-icon icon="cloud-upload-alt" class="logo-icon" />
            </div>

            <label for="map_document">
                <input id="map_document" class="hidden" type="file" value="Click here to browse" @change="fileChanged" />
                <div class="bg-secondary cursor-pointer text-white rounded p-2 w-60 mx-auto mb-2">{{ fileUpload.text }}</div>
                <validation-message :show="showErrors && !isValid('document')">process map required</validation-message>
            </label>

            <div class="flex items-center">
                <app-button class="mr-2" @click="submit">Add Process Map</app-button>
                <router-link to="/process-maps">
                    <app-button color="danger" class="mr-2">Cancel</app-button>
                </router-link>
            </div>

        </panel>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import AppButton from '../../components/ui/AppButton'
import IconButton from '../../components/ui/IconButton'
import BreadCrumbs from '../../components/ui/BreadCrumbs'

import FormGroup from '../../components/forms/FormGroup'
import FormSection from '../../components/forms/FormSection'
import FormLabel from '../../components/forms/FormLabel'
import FakeCheckbox from '../../components/forms/FakeCheckbox'
import ValidationMessage from '../../components/forms/ValidationMessage'

import formHelpers from '../../lib/form-helpers'

export default {
    components: {
        AppLayout,
        Panel,
        AppButton,
        IconButton,
        BreadCrumbs,
        FormGroup,
        FormLabel,
        FakeCheckbox,
        ValidationMessage
    },
    data() {
        return {
            fileUpload: {
                preview: '',
                text: 'Upload Process Map'
            },
            showErrors: false,
            form: {
                process_map_title: { value: '', required: true },
                policy_set_id: { value: '', required: true },
                policy_id: { value: '', required: true },
                instructions: { value: '', required: true },
                document: { value: '', required: true },
                status: { value: 1 }
            },
            masterPolicySets: [],
            policies: []
        };
    },
    methods: {
        fileChanged(e) {
            if(e.target.files.length > 0) {
                const file = e.target.files[0];
                this.fileUpload.text = file.name;
                this.form.document.value = file;
                this.fileUpload.preview = URL.createObjectURL(file);
            }
        },
        getPolicies() {
            const data = {
                master_policy_set_id: this.form.policy_set_id.value,
                search: ''
            };
            this.$store.dispatch('masterPolicySetPolicies', data).then((data) => {
                this.policies = data.ListPolicy;
                console.log(this.policies);
            });
        },
        isValid(field) {
            return formHelpers.isValid(this.form, field);
        },
        submit() {

            // const formData = formHelpers.getFormData(this.form);

            if(formHelpers.isFormValid(this.form)) {
                const formData = formHelpers.getFormData(this.form);
                this.$store.dispatch('processMapAdd', formData).then((success) => {

                    this.$fire({
                        title: "Process Map Added",
                        text: success.message,
                        type: "success",
                        timer: 3000
                    });

                    this.$router.push('/process-maps');

                });
            }else{
                this.showErrors = true;
            }

        }
    },
    mounted() {

        Promise.all([
            this.$store.dispatch('masterPolicySetList')
        ]).then((data) => {
            this.masterPolicySets = data[0];
            console.log(data[0][0]);
        });

    }
}

</script>

<style lang="css">



</style>
