<template lang="html">
    <div class="flex justify-center items-center">
        <div class="status-light" :class="{ 'is-online': status === 1 }"></div>
        <div class="status-text" :class="{ 'is-online': status === 1 }">{{ statusText }}</div>
    </div>
</template>

<script>

export default {
    props: {
        status: Number
    },
    computed: {
        statusText() {
            return ( this.status === 1 ? 'Online' : 'Offline' );
        }
    }
}

</script>

<style lang="css" scoped>

    .status-light {
        width: 12px;
        height: 12px;
        background: #999999;
        border-radius: 100%;
        margin-right: 8px;
    }

    .status-text {
        color: #999999;
    }

    .status-light.is-online {
        background: #56f03a;
        box-shadow: 0px 0px 10px #65ff00;
    }

    .status-text.is-online {
        color: #197f07;
    }

</style>
