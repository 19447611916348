<template>

    <div class="flex flex-col md:flex-row">

        <div class="md:w-1/4">
            <p class="font-bold mt-4 mb-4">{{ title }}</p>
        </div>

        <div class="md:w-3/4">
            <panel class="p-4 mb-8">

                <slot></slot>

            </panel>
        </div>

    </div>

</template>

<script>

    import Panel from './../ui/Panel'

    export default {
        name: 'FormSection',
        components: {
            Panel
        },
        props: {
            title: String
        }
    }

</script>

<style lang="css">
</style>
