import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import user from './user'
import users from './users'
import serviceTiers from './serviceTiers'
import businessTypes from './businessTypes'
import companies from './companies'
import companyPolicies from './companyPolicies'
import companyTests from './companyTests'
import masterPolicySets from './masterPolicySets'
import masterTestSets from './masterTestSets'
import masterPolicies from './masterPolicies'
import policyProfile from './policyProfile'
import processMaps from './processMaps'
import placeholders from './placeholders'
import jobTitles from './jobTitles'
import lesson from './lesson'
import reports from './reports'
import dashboard from './dashboard'
import exams from './exams'
import test from './test'
import complianceLibrary from './complianceLibrary'
import messages from './messages'

Vue.use(Vuex)

const localUrl = 'http://dev.insequa.com:8000'
// const localUrl = 'http://dev.insequa.com'
const liveUrl = 'https://api.insequa.co.uk' // 'http://insequa-api.rwdstaging.co.uk'
// const liveUrl = 'https://ec2-18-217-51-205.us-east-2.compute.amazonaws.com' // 'http://insequa-api.rwdstaging.co.uk'
const baseUrl = (window.location.href.indexOf("localhost") > -1 ? localUrl : liveUrl )

export default new Vuex.Store({
    state: {
        errors: [],
		success: '',
		loading: false,
        token: sessionStorage.getItem('token') || '',
        baseUrl: baseUrl,
        apiUrl: baseUrl + '/api',
        menuIsOpen: false
    },
    mutations: {
        auth(state, token) {
            sessionStorage.setItem('token', token);
            state.token = token;
		},
		logout(state) {
			sessionStorage.clear('token');
            sessionStorage.clear('user_role');
            sessionStorage.clear('current_company');
            state.token = '';
		},
        setLoading(state, loading) {
			state.loading = loading;
		},
		setSuccess(state, message) {
			state.success = message;
		},
		setError(state, eData) {

			if(!eData || eData.length < 1) {
				state.errors = [];
				return;
			}

			if(Array.isArray(eData)) {
				state.errors = eData;
				return;
			}

			if(typeof eData === 'string') {
				state.errors = [ eData ];
			}

			if(typeof eData === 'object') {
				if(eData.response) {
					if(eData.response.data) {
						if(eData.response.data.errors) {
							var errs = [];
							for (const [key, value] of Object.entries(eData.response.data.errors)) {
								console.log(key);
								errs.push(value[0]);
							}
							state.errors = errs;
							return;
						}
						state.errors = [eData.response.data.message];
						return;
					}
					state.errors = [eData.status + ': ' + eData.statusText];
				}
				if(eData.message) {
					state.errors = [eData.message];
					return;
				}
			}

			state.errors = [eData];

		},
        toggleMenu(state) {
            state.menuIsOpen = !state.menuIsOpen;
        }
    },
    actions: {
        login({commit, dispatch}, loginData) {

    		commit('setLoading', true);
    		commit('setError', []);

			return new Promise((resolve) => {

				axios.post(this.state.apiUrl + '/user/login', loginData)
					.then((res) => {

                        if(res.data.status) {
                            var token = res.data.token;
    						axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                            commit('auth', token);
                            commit('setUser', res.data.data);
                            commit('setUserRole', res.data.data.job_title_id);

                            commit('setLoading', false);
                            resolve();

                        }else{
                            commit('setError', res.data.message);
                            commit('setLoading', false);
                        }

					})
					.catch((e) => {
						console.log('e.response ', e.response);
						commit('setError', e);
						commit('setLoading', false);
					});

			});

		},
        logout({commit}) {
			return new Promise((resolve) => {
				axios.post(this.state.apiUrl + '/user/logout').then((res) => {
                    commit('switchUserCompany', null);
                    resolve();
                })
            })
            .catch((e) => {
                commit('setError', e);
            });
        }
    },
    getters: {
		isLoggedIn(state) {
            return !!state.token;
		},
        showCompanyBar(state, getters) {
            return function(path) {
                if(getters.userHasRoles(['company_admin'])) {
                    // show if relevent page
                    if(path === '/profile') {
                        return false;
                    }
                    if(path.includes('/my-policy-profile')) {
                        return false;
                    }
                    return true;
                }
                return false;
            }
        }
	},
    modules: {
        user,
        users,
        serviceTiers,
        businessTypes,
        companies,
        companyPolicies,
        companyTests,
        masterPolicySets,
        masterPolicies,
        policyProfile,
        processMaps,
        placeholders,
        jobTitles,
        lesson,
        reports,
        dashboard,
        masterTestSets,
        exams,
        test,
        complianceLibrary,
        messages
    }
})
