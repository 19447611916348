<template>
  <div class="border-b rounded-md">
    <Header :contacts="contacts" :selected="selectedThread" />
    <div class="rounded-b-md flex h-96">
      <ContactsList
        :contacts="contactsList"
        @search="setFilter"
        @selected="startConversationWith"
      />
      <Conversation
        :contact="selectedContact"
        :thread="selectedThread"
        :messages="messages"
        @new="saveNewMessage"
      />
      <ThreadList
        :threads="threads"
        :contact="selectedThread"
        @selected="showThread"
        @delete="deleteThread"
      />
    </div>
  </div>
</template>

<script>
import Conversation from "./Conversation";
import ContactsList from "./ContactsList";
import ThreadList from "./ThreadList";
import Header from "./Header";
export default {
  data() {
    return {
      search: null,
      selectedContact: {},
      selectedThread: null,
      messages: [],
      contacts: [],
      threads: [],
      polling: null
    };
  },
  computed: {
    contactsList() {
      if (this.search) {
        return this.contacts.filter((contact) => {
          let name = contact.first_name + " " + contact.last_name;
          return name.toLowerCase().includes(this.search.trim().toLowerCase());
        });
      }
      return this.contacts;
    },
  },
  mounted() {
    this.getContacts(),
    this.getThreads(),
    this.pollServer()
  },
  methods: {
    pollServer() {
      this.polling = setInterval(() => this.getThreads(), 10000)
    },
    getContacts() {
        this.$store.dispatch("getContacts").then((data) => {
        this.contacts = data.data;
      })
    },
    getThreads() {
        this.$store.dispatch("getThreads").then((data) => {
        this.threads = data.data;
        if (!this.selectedThread || !this.threads.find(e => e.id === this.selectedThread.id)) {
          this.messages = []
          this.selectedThread = null
        }
        if(this.selectedThread) {
          let unreadThread = this.threads.find(e => e.isUnread && e.id === this.selectedThread.id)
          if(unreadThread && unreadThread.id == this.selectedThread.id) {
            this.showThread(unreadThread)
          }
        }
  
      })
    },
    startConversationWith(contact) {
      for (const element of this.threads) {
        if (element.users.length > 1 && contact.id < 0) {
          let subject = element.subject.replace(/\s/g, "");
          let name = contact.first_name + contact.last_name;
          name = name.replace(/\s/g, "");
          if (subject == name) {
            this.selectedThread = element;
            this.selectedContact = contact;
            return;
          }
        }
        if (element.users[0].id == contact.id && !element.subject.startsWith('All Staff') && !element.subject.startsWith('All Company Admin')) {
          this.selectedThread = element;
          this.selectedContact = contact;
          return;
        }
      }

      this.$store
        .dispatch("createMessageThread", { contact_id: contact.id })
        .then((data) => {
          this.selectedContact = contact;
          this.selectedThread = data.data;
          this.threads.push(data.data);
        });
    },
    saveNewMessage(message) {
      this.messages.push(message);
    },
    setFilter(search) {
      this.search = search;
    },

    filterContacts(search) {
      return this.contacts.filter((item) => {
        let name = item.first_name + " " + item.last_name;
        return search
          .toLowerCase()
          .split(" ")
          .every((v) => name.toLowerCase().includes(v));
      });
    },
    showThread(thread) {
      if(!thread) {
        return
      }
      this.$store.dispatch("getMessageThread", thread.id).then((data) => {
        this.selectedThread = thread;
        this.messages = data.data.messages;
      });
    },
    deleteThread(thread) {
      this.$store.dispatch("deleteThread", { thread_id : thread.id }).then((data) => {
        this.threads = data.data;
        this.messages = []
        this.selectedThread = null
      }) 
    },
   
  },
  beforeDestroy () {
    clearInterval(this.polling)
  },
  components: {
    Conversation,
    ContactsList,
    ThreadList,
    Header,
  },
};
</script>

<style lang="css">
/* Hide scrollbar for Chrome, Safari and Opera */
.chatscroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.chatscroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* width */
.chatscroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.chatscroll::-webkit-scrollbar-track {
  background: #f0f0f0;
}

/* Handle */
.chatscroll::-webkit-scrollbar-thumb {
  background: #5b8be1;
}

/* Handle on hover */
.chatscroll::-webkit-scrollbar-thumb:hover {
  background: #446cb4;
}
</style>
