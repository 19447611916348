<template>
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Companies', href: '/companies' },
            { name: company.company_name, href: '/companies/view/' + $route.params.companyId },
            { name: 'Company Process Maps' }
        ]" />

        <div :class="{ 'loading-white': ( $store.state.loading ? true : false ) }">

            <div class="flex justify-between items-center">
                <h1 class="hdln-1 mb-8">Company Process Maps</h1>
                <div class="flex justify-between items-center">
                    <app-button color="secondary" @click="generatedProcessMaps">Generate Process Maps</app-button>
                    <router-link :to="'/companies/view/' + $route.params.companyId">
                        <app-button color="danger" class="ml-4">Back</app-button>
                    </router-link>
                </div>
            </div>

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Process Map</th>
                        <th>
                            <div class="flex justify-end cursor-pointer">
                                <fake-checkbox id="maps_all" :checked="isAllSelected" @changed="toggleSelectAll" :is_light="true">
                                    Select All
                                </fake-checkbox>
                            </div>
                        </th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="map in processMaps">
                        <td>{{ map.process_map_title }}</td>
                        <td>
                            <div class="flex justify-end">
                                <fake-checkbox :id="'process_map_' + map.id" :checked="isSelected(map.id)" @changed="toggleMap(map.id)" />
                            </div>
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

        <!-- <modal header="Placeholders"
               :isOpen="genPoliciesOpen" @closed="genPoliciesOpen = false" :loading="$store.state.loading">

            <form-group class="mb-4" v-for="(placeholder, i) in placeholders">
                <form-label :required="true">{{ placeholder.placeholder_key }}</form-label>
                <input type="text" v-model="placeholders[i].placeholder_value" class="form-input" />
            </form-group>

            <div class="flex">
                <app-button class="mr-4" @click="generatePolicies">Submit</app-button>
                <app-button class="mr-4" @click="genPoliciesOpen = false">Cancel</app-button>
            </div>

        </modal> -->

    </app-layout>
</template>
<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import Modal from '../../../components/ui/Modal'
import AppButton from '../../../components/ui/AppButton'
import IconButton from '../../../components/ui/IconButton'
import AppTable from '../../../components/ui/AppTable'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import FakeCheckbox from '../../../components/forms/FakeCheckbox'
import FormGroup from '../../../components/forms/FormGroup'
import FormLabel from '../../../components/forms/FormLabel'
import ValidationMessage from '../../../components/forms/ValidationMessage'

import helpers from '../../../lib/general'

export default {
    components: {
        AppLayout,
        Panel,
        Modal,
        AppButton,
        IconButton,
        AppTable,
        BreadCrumbs,
        FakeCheckbox,
        FormGroup,
        FormLabel,
        ValidationMessage
    },
    data() {
        return {
            company: {
                company_name: null
            },
            processMaps: [],
            selectedProcessMaps: []
        };
    },
    computed: {
        isAllSelected() {
            return (this.selectedProcessMaps.length === this.processMaps.length);
        }
    },
    methods: {
        toggleMap(id) {
            if(this.isSelected(id)) {
                var index = this.selectedProcessMaps.indexOf(id);
                if (index !== -1) {
                    this.selectedProcessMaps.splice(index, 1);
                }
            }else{
                this.selectedProcessMaps.push(id);
            }
        },
        isSelected(id) {
            return this.selectedProcessMaps.includes(id);
        },
        toggleSelectAll() {
            if(this.isAllSelected) {
                this.deselectAll();
            }else{
                this.selectAll();
            }
        },
        selectAll() {
            this.selectedProcessMaps = [];
            this.processMaps.forEach((p, i) => {
                this.selectedProcessMaps.push(p.id);
            });
        },
        deselectAll() {
            this.selectedProcessMaps = [];
        },
        generatedProcessMaps() {
            if(this.selectedProcessMaps.length > 0) {
                const data = {
                    company_id: this.$route.params.companyId,
                    process_map_ids: JSON.stringify(this.selectedProcessMaps)
                };
                this.$store.dispatch('generateProcessMap', data).then((success) => {
                    console.log('success ', success);
                    this.$fire({
                        title: "Process Maps Generated",
                        text: success.message,
                        type: "success",
                        timer: 3000
                    });
                    this.$router.push('/companies/view/' + this.$route.params.companyId);
                });
            }else{
                this.$fire({
                    title: "Select a process map",
                    type: "success",
                    timer: 3000
                });
            }
        }
    },
    mounted() {

        this.$store.dispatch('companiesGet', this.$route.params.companyId).then((company) => {
            this.company = company;
            this.$store.dispatch('processMapList', {policy_set_id: company.master_policies_set_id}).then((processMaps) => {
                this.processMaps = processMaps;
            });
        });

    }
}

</script>
