<template>
    <app-layout>

        <div :class="{ 'loading-white' : loading }">

            <bread-crumbs :links="[
                { name: 'Reports', href: '/admin/reports' },
                { name: 'Policies', href: '/admin/reports/policies' },
                { name: policy.policy_title }
            ]" />

            <div class="flex justify-between items-center mb-8">
                <h1 class="hdln-1">{{ policy.policy_title }}</h1>
                <app-button @click="downloadCsv">Download CSV</app-button>
            </div>

            <stat-table>
                <template #thead>
                    <tr>
                        <th class="p-2">User Name</th>
                        <th class="p-2 text-center">Read Policy</th>
                        <th class="p-2 text-center">Passed Test</th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="user in report">
                        <td class="p-2">{{ user.first_name }} {{ user.last_name }}</td>
                        <td class="p-2 text-center">
                            <font-awesome-icon v-if="readPolicy(user)" icon="check" class="text-greenbright" />
                            <font-awesome-icon v-else icon="times" class="text-danger" />
                        </td>
                        <td class="p-2 text-center">
                            <font-awesome-icon v-if="user.test_passed" icon="check" class="text-greenbright" />
                            <font-awesome-icon v-else icon="times" class="text-danger" />
                        </td>
                    </tr>
                </template>
            </stat-table>

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../../components/layout/AppLayout'
import Panel from '../../../components/ui/Panel'
import ProgBar from '../../../components/ui/ProgBar'
import BreadCrumbs from '../../../components/ui/BreadCrumbs'
import AppButton from '../../../components/ui/AppButton'
import StatTable from '../../../components/StatTable'
import PercentageCircle from 'vue-css-percentage-circle'

export default {
    components: {
        AppLayout,
        Panel,
        ProgBar,
        BreadCrumbs,
        AppButton,
        StatTable,
        PercentageCircle
    },
    data() {
        return {
            policy: {
                policy_title: ''
            },
            report: [],
            company: {
                company_name: ''
            },
            loading: false
        };
    },
    methods: {
        readPolicy(user) {
            if(user.policyProfile) {
                if(user.policyProfile.log_percent === 100) {
                    return true;
                }
            }
            return false;
        },
        isEven(value) {
        	if (value%2 == 0)
        		return true;
        	else
        		return false;
        },
        downloadCsv() {
            const data = {
                policyId: this.$route.params.policyId,
                companyId: this.$store.state.user.currentCompany.id
            };
            this.$store.dispatch('policyCsv', data).then((data) => {
                window.location.href = data.download_link;
            });
        }
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch('getUser').then((data) => {

            const companyId = this.$store.state.user.currentCompany.id; // data.data.company_id;

            Promise.all([
                this.$store.dispatch('companiesGet', companyId),
                this.$store.dispatch('policyReport', { policyId: this.$route.params.policyId, companyId: companyId })
            ]).then((data) => {
                this.company = data[0];
                this.policy = data[1].policy;
                this.report = data[1].report;
                this.loading = false;
            });

        });
    }
}

</script>
