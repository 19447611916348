<template>
    <nav class="side-nav bg-secondary left-0 w-10/12 md:w-2/5 lg:w-1/5 text-white h-100 hidden md:flex flex-col" :class="{ isOpen: this.$store.state.menuIsOpen }">

        <div class="bg-secondary-dark p-4 flex items-center">
            <img :src="this.$store.getters.userProfilePicture" class="avatar" />
            <p class="ml-4 text-lg">{{ $store.getters.userName }}</p>
        </div>

        <!-- <div class="overflow-y-scroll"> -->
            <Menu :links="links" @toggleLink="toggleLink" />
        <!-- </div> -->

        <!-- <div class="fixed w-10/12 md:w-2/5 lg:w-1/5 bottom-0"> -->
            <div class="bg-secondary-dark p-4 flex items-center cursor-pointer hover:bg-secondary-lighter mt-auto" @click="logout">
                <p>Logout </p>
                <font-awesome-icon icon="sign-out-alt" class="ml-auto" />
            </div>
        <!-- </div> -->
    </nav>
</template>
<script>

    import Menu from './Menu'

    export default {
        name: 'SideNav',
        components: {
            Menu
        },
        computed: {
            user() {
                return this.$store.state.user.user;
            },
            links() {

                switch (this.$store.getters.userRole) {
                    case 'super_admin':
                        return this.superAdminLinks;
                        break;
                    case 'company_admin':
                    case 'division_manager':
                    case 'site_manager':
                        return this.companyAdminLinks;
                        break;
                    default:
                        return this.staffLinks;
                }

            }
        },
        data() {
            return {
                superAdminLinks: [
                    { href: '/dashboard', name: 'Dashboard', icon: 'tachometer-alt' },
                    { href: '/profile', name: 'My Profile', icon: 'user-circle' },
                    { href: '/service-tiers', name: 'Service Tiers', icon: 'concierge-bell' },
                    { href: '/business-types', name: 'Business Types', icon: 'briefcase' },
                    { href: '/companies', name: 'Companies', icon: 'building' },
                    {
                        name: 'Compliance',
                        icon: 'clipboard-check',
                        open: false,
                        subLinks: [
                            { href: '/master-policy-sets', name: 'Master Policy Sets' },
                            { href: '/master-test-sets', name: 'Master Test Sets' },
                            { href: '/placeholders', name: 'Placeholders' },
                            { href: '/process-maps', name: 'Process Maps' },
                        ]
                    },
                    { href: '/reports', name: 'Reports', icon: 'clipboard-list' },
                    { href: '/test', name: 'Test', icon: 'clipboard-list' }
                ],
                companyAdminLinks: [
                    { href: '/dashboard', name: 'Dashboard', icon: 'tachometer-alt' },
                    { href: '/profile', name: 'My Profile', icon: 'user-circle' },
                    { href: '/my-policy-profile', name: 'My Policy Profile', icon: 'address-card' },
                    { href: '/admin/staff-policy-profiles', name: 'Staff Policy Profiles', icon: 'address-card' },

                    { href: '/admin/company', name: 'Company', icon: 'building' },
                    { href: '/admin/process-maps', name: 'Process Maps', icon: 'shoe-prints' },
                    { href: '/admin/tests', name: 'Tests', icon: 'vial' },
                    {
                        name: 'Policies',
                        icon: 'clipboard-check',
                        open: false,
                        subLinks: [
                            { href: '/admin/policies/library', name: 'Library' },
                            { href: '/admin/policies/management', name: 'Management' }
                        ]
                    },
                    { href: '/admin/reports', name: 'Reports', icon: 'clipboard-list' },
                    { href: '/compliance-library', name: 'Compliance Library', icon: 'book' }
                ],
                staffLinks: [
                    { href: '/dashboard', name: 'Dashboard', icon: 'tachometer-alt' },
                    { href: '/profile', name: 'My Profile', icon: 'user-circle' },
                    { href: '/my-policy-profile', name: 'My Policy Profile', icon: 'address-card' },
                ]
            };
        },
        methods: {
            toggleLink(i) {
                switch (this.$store.getters.userRole) {
                    case 'super_admin':
                        this.superAdminLinks[i].open = !this.superAdminLinks[i].open;
                        break;
                    case 'company_admin':
                    case 'division_manager':
                    case 'site_manager':
                        this.companyAdminLinks[i].open = !this.companyAdminLinks[i].open;
                        break;
                    default:
                        this.staffLinks[i].open = !this.staffLinks[i].open;
                }
            },
            logout() {

                this.$confirm('Are you sure you want to logout?').then(() => {

                    this.$store.dispatch('logout')
        				.then(() => {
                            this.$store.commit('logout');
                            this.$store.commit('setError', []);
                            this.$router.push('/login');
        				});

                });

            }
        },
        mounted() {
            console.log('');
        }
    }

</script>
<style>

    .side-nav {
        position: fixed;
        top: 72px;
        height: calc(100% - 72px);
        z-index: 9;
    }

    .avatar {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        object-fit: cover;
        object-position: center;
    }

    .isOpen {
        display: block !important;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 72px;
    }

</style>
