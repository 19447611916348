<template>

    <div class="answer-cb" :class="{ 'is-selected': checked }">
        <div></div>
    </div>

</template>

<script>
export default {
    props: {
        checked: Boolean
    }
}
</script>

<style lang="css" scoped>

    .answer-cb {
        width: 24px;
        height: 24px;
        border: solid 2px #d6d6d6;
        border-radius: 100%;
        cursor: pointer;
    }

    .answer-cb.is-selected {
        border: solid 2px #05cb02;
    }

    .answer-cb.is-selected > div {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: #05cb02;
        margin-top: 2px;
        margin-left: 2px;
    }

</style>
