import axios from 'axios';

function loadingToggle(commit, loadingFunc, section, state) {
    let payload = state;
    if(!loadingFunc) {
        loadingFunc = 'setLoading';
    }
    if(section) {
        payload = { section, loading: state };
    }
    commit(loadingFunc, payload);
}

function call(method, commit, rootState, options) {

    if(!options.loadingFunc) {
        options.loadingFunc = null;
    }

    if(!options.disableLoading) {
        loadingToggle(commit, options.loadingFunc, options.loadingSection, true);
    }
    commit('setError', []);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
    axios[method](rootState.apiUrl + options.url, options.data)
        .then((res) => {

            if(!res.data.status) {
                commit('setError', res.data.message);
                options.error(res.data.message);
            }else{
                options.success(res.data);
            }

            if(!options.disableLoading) {
                loadingToggle(commit, options.loadingFunc, options.loadingSection, false);
            }

        })
        .catch((e) => {
            commit('setError', e);
            if(!options.disableLoading) {
                loadingToggle(commit, options.loadingFunc, options.loadingSection, false);
            }
            options.error(e);
        });
}

export default {
    get(commit, rootState, options) {
        return call('get', commit, rootState, options);
    },
    post(commit, rootState, options) {
        return call('post', commit, rootState, options);
    },
    put(commit, rootState, options) {
        return call('put', commit, rootState, options);
    },
    delete(commit, rootState, options) {
        return call('delete', commit, rootState, options);
    }
}
