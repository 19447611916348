<template lang="html">
    <div>

        <form-section title="Question">

            <div class="md:flex">

                <div class="w-full md:w-1/2 md:mr-2">

                    <form-group class="mb-4">
                        <form-label :required="true">Question Type</form-label>
                        <select class="form-input"
                               :class="{ invalid: !isValid('question_type') && showErrors }"
                               v-model="form.question_type.value">
                               <option v-for="qt in questionTypes" :value="qt">{{ qt }}</option>
                        </select>
                        <validation-message :show="showErrors && !isValid('question_type')">question type required</validation-message>
                    </form-group>

                </div>

                <div class="w-full md:w-1/2 md:mr-2">

                    <form-group class="mb-4">
                        <form-label :required="true">Question Marks</form-label>
                        <input type="number"
                               class="form-input"
                               :class="{ invalid: !isValid('question_marks') && showErrors }"
                               v-model="form.question_marks.value" />
                        <validation-message :show="showErrors && !isValid('question_marks')">question marks required</validation-message>
                    </form-group>

                </div>

            </div>

            <form-group>
                <form-label :required="true">Question</form-label>
                <textarea class="form-input" :class="{ invalid: !isValid('question') && showErrors }"
                       v-model="form.question.value"></textarea>
                <validation-message :show="showErrors && !isValid('question')">question required</validation-message>
            </form-group>

        </form-section>

        <form-section title="Answers">

            <div class="answers text-left">

                <div v-for="(a, i) in answers" class="flex justify-between answer">
                    <div class="w-full md:mr-4">
                        <form-label>Answer {{ i + 1 }}</form-label>
                        <input class="answer-input" type="text" v-model="answers[i].answer" />
                    </div>
                    <div class="w-full md:mr-4">
                        <form-label>Marks</form-label>
                        <input class="answer-input" type="number" v-model="answers[i].points" />
                    </div>
                    <div>
                        <form-label class="whitespace-nowrap">Correct Answer</form-label>
                        <div class="answer-cb" @click="selectCorrectAnswer(i)" :class="{ 'is-selected': answers[i].correct_answer }">
                            <div></div>
                        </div>
                    </div>
                    <div class="flex justify-center items-end">
                        <icon-button v-if="answers.length > 1" icon="minus" color="danger" @click="removeAnswer(i)" />
                    </div>
                </div>

                <validation-message :show="showErrors && !answersError === false" class="mb-2">{{ answersError }}</validation-message>

                <div>
                    <app-button color="greenbright" @click="addAnswer">
                        <span class="mr-2">Add Answer</span>
                        <font-awesome-icon icon="plus" />
                    </app-button>
                </div>

            </div>

        </form-section>

        <div class="flex justify-end mt-2">
            <app-button class="mr-4" color="greenbright" @click="onSubmit">Save</app-button>
            <router-link v-if="$store.getters.userHasRoles(['super_admin'])" :to="'/master-test-sets/' + $route.params.id + '/exams/' + this.$route.params.examId + '/questions'">
                <app-button>Back</app-button>
            </router-link>
            <router-link v-else :to="'/admin/tests/' + $route.params.examId + '/questions'">
                <app-button>Back</app-button>
            </router-link>
        </div>

    </div>
</template>

<script>

    import Panel from '../ui/Panel'
    import AppButton from '../ui/AppButton'
    import IconButton from '../ui/IconButton'
    import FormGroup from '../forms/FormGroup'
    import FormSection from '../forms/FormSection'
    import FormLabel from '../forms/FormLabel'
    import FakeCheckbox from '../forms/FakeCheckbox'
    import ValidationMessage from '../forms/ValidationMessage'
    import Datepicker from 'vuejs-datepicker';

    import formHelpers from '../../lib/form-helpers'

    export default {
        components: {
            Panel,
            AppButton,
            IconButton,
            FormSection,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            ValidationMessage,
            Datepicker
        },
        data() {
            return {
                showErrors: false,
                questionTypes: [
                    'Single Answer',
                    'Multiple Answer'
                    // 'Sorting Answer',
                    // 'Fill in the blanks',
                    // 'Matching'
                ],
                form: {
                    question_type: { value: 'Single Answer', required: true },
                    question_marks: { value: null, required: true },
                    question: { value: null, required: true },
                },
                answers: [
                    { answer: '', points: 1, correct_answer: true }
                ]
            };
        },
        computed: {
            isThereACorrectAnswer() {
                let selectedAnswer = false;
                this.answers.forEach((answer, i) => {
                    if(this.answers[i].correct_answer) {
                        selectedAnswer = true;
                    }
                });
                return selectedAnswer;
            },
            answersError() {
                let error = false;
                if(!this.isThereACorrectAnswer) {
                    error = 'No correct answer set';
                }
                this.answers.forEach((answer, i) => {
                    if(answer.points === '' || answer.points === null) {
                        error = 'Points required for answer ' + (i + 1);
                    }
                    if(!answer.answer) {
                        error = 'Answer required for answer ' + (i + 1);
                    }
                });
                return error;
            },
        },
        methods: {
            addAnswer() {
                this.answers.push({ answer: '', points: 1, correct_answer: false });
            },
            removeAnswer(i) {
                if(this.answers.length > 1) {
                    this.answers.splice(i, 1);
                    if(!this.isThereACorrectAnswer) {
                        this.answers[0].correct_answer = true;
                    }
                }
            },
            selectCorrectAnswer(index) {
                if(this.form.question_type.value === 'Single Answer') {
                    this.answers.forEach((answer, i) => {
                        this.answers[i].correct_answer = false;
                    });
                    this.answers[index].correct_answer = true;
                }else{
                    this.answers[index].correct_answer = !this.answers[index].correct_answer;
                }
            },
            onSubmit() {

                const data = formHelpers.getData(this.form);

                data.exam_id = this.$route.params.examId;
                data.answers = this.answers;

                if(formHelpers.isFormValid(this.form) && !this.answersError) {
                    // console.log('DATA TO SUBMIT ', data);
                    this.$emit('onSubmit', data);
                }else{
                    this.showErrors = true;
                    const invalidFilds = formHelpers.getInvalidFields(this.form);
                    console.log(invalidFilds[0] + ' required');
                }

            },
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            setForm(data) {

                this.form.question_type.value = data.question_type;
                this.form.question_marks.value = data.question_marks;
                this.form.question.value = data.question;
                this.answers = data.answers;

            }
        },
        mounted() {

            // this.$store.dispatch('getPoliciesFromTestSet', this.$route.params.id).then((policies) => {
            //     this.policies = policies;
            // });

        }
    }

</script>

<style lang="css" scoped>

    .answer {
        margin-bottom: 12px;
        border-bottom: solid 2px #d6d6d6;
        padding-bottom: 14px;
    }

    .answer-input {
        width: 100%;
        padding: 3px 10px;
        border: solid 1px #c5c5c5;
        border-radius: 3px;
    }

    .answer-cb {
        width: 24px;
        height: 24px;
        border: solid 2px #d6d6d6;
        border-radius: 100%;
        margin: 12px auto 0px auto;
        cursor: pointer;
    }

    .answer-cb.is-selected {
        border: solid 2px #05cb02;
    }

    .answer-cb.is-selected > div {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: #05cb02;
        margin-top: 2px;
        margin-left: 2px;
    }

</style>
