<template lang="html">

    <div class="p-4 rounded  bg-white mb-8">

        <div class="flex justify-between items-center mb-2 pb-2 border-b-2 border-greenbright">
            <p class="text-xl">{{ policyTitle }}</p>
        </div>

        <div class="flex flex-col md:flex-row pt-2">

            <div class="md:w-1/2 md:pr-2">

                <h2 class="bg-greenbright text-white text-md py-1.5 text-center px-2">Read Progress</h2>

                <table class="text-left policyTable">
                    <!-- <tr>
                        <th class="px-2 py-1">Accessed</th>
                        <td class="px-2 py-1">{{ ( policy.accessed ? 'Yes' : 'No' ) }}</td>
                    </tr> -->
                    <tr>
                        <th class="px-2 py-1">Started</th>
                        <td class="px-2 py-1">{{ (stat.log_percent > 0 ? 'Yes' : 'No' ) }}</td>
                    </tr>
                    <tr>
                        <th class="px-2 py-1">Completed</th>
                        <td class="px-2 py-1">{{ stat.log_percent }}%</td>
                    </tr>
                    <tr>
                        <th class="px-2 py-1">Read Duration</th>
                        <td class="px-2 py-1">{{ readDuration }}</td>
                    </tr>
                    <tr>
                        <th class="px-2 py-1">Expected Duration</th>
                        <td class="px-2 py-1">{{ expectedDuration }}</td>
                    </tr>
                </table>

            </div>

            <div class="md:w-1/2 md:pl-2">

                <h2 class="bg-greenbright text-white text-md py-1.5 text-center px-2">Comprehension Test</h2>

                <table class="text-left policyTable">
                    <tr>
                        <th class="px-2 py-1">Attempts</th>
                        <td class="px-2 py-1">{{ attempts }}</td>
                    </tr>
                    <tr>
                        <th class="px-2 py-1">Pass / Fail</th>
                        <td class="px-2 py-1">{{ passFail }}</td>
                    </tr>
                    <tr>
                        <th class="px-2 py-1">Date &amp Time Passed</th>
                        <td class="px-2 py-1">{{ dateTimePassed }}</td>
                    </tr>
                </table>

            </div>

        </div>

    </div>

</template>

<script>

    import AppButton from '../ui/AppButton'

    export default {
        components: {
            AppButton
        },
        props: {
            stat: Object
        },
        computed: {
            policyTitle() {
                if(this.stat.policy) {
                    return this.stat.policy.policy_title;
                }
                return '';
            },
            readDuration() {
                const time = this.stat.read_duration;
                if(time) {
                    return time.hours + ( time.hours === 1 ? ' hour ' : ' hours ' ) + time.minutes + ( time.minutes === 1 ? ' minute' : ' minutes' );
                }
                return '';
            },
            expectedDuration() {
                const time = this.stat.expected_duration;
                if(time) {
                    return time.hours + ( time.hours === 1 ? ' hour ' : ' hours ' ) + time.minutes + ( time.minutes === 1 ? ' minute' : ' minutes' );
                }
                return '';
            },
            attempts() {
                if(this.stat.test) {
                    return this.stat.test.test_count;
                }
                return 0;
            },
            passFail() {
                if(!this.stat.test) {
                    return 'Test not taken';
                }else{
                    if(this.stat.test.exam_status === '1') {
                        return 'Pass';
                    }else{
                        return 'Fail';
                    }
                }
            },
            dateTimePassed() {
                if(this.passFail === 'Pass') {
                    return this.stat.test.updated_at; // .split(' ')[0];
                }
                return '-';
            }
        },
        data() {
            return {

            };
        },
        mounted() {
            console.log('policy ', this.stat);
        }
    }

</script>

<style lang="scss" scoped>

    .policyTable {
        width: 100%;
        font-weight: 100;
    }

    .policyTable tr {
        background: #f0f0f0;
    }

    .policyTable tr:nth-child(even) {
        background: #fff; // #e8e8e8;
    }

    /* .policyTable th {
        background: #cbcbcb;
        color: #3b5e0d;
    }

    .policyTable tr:nth-child(even) th {
        background: #e8e8e8;
        background: #bcbcbc;
    } */

</style>
