import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    testMail({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/mail/test',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
    actions
}
