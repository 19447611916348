function isValidEmail(email) {
    if(!email.value) {
        return false;
    }
    return true;
}

// function isValidPassword(password) {
//
//     // empty
//     if(!password.value) {
//         return false;
//     }
//
//     // length > 6
//     if(password.length < 6) {
//         return false;
//     }
//
//     // upper, lower, numeric and special
//     const pattern = new RegExp(
//       "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
//     );
//
//     if (!pattern.test(password)) {
//         return false;
//     }
//
//     return true;
// }

function isValidConfirmedPassword(password, confirm) {
    if(password !== confirm) {
        return false;
    }
    return true;
}

function isValidCheckbox(value) {
    if(value !== null && value !== undefined) {
        return true;
    }
    return false;
}

function isValidNumber(value) {
    if(!value) {
        return false;
    }
    if( !isNaN(Number(value)) ) {
        return true;
    }
    return false;
}

export default {
    isValid(data, field) {

        if(!data[field].required) {
            return true;
        }

        if(typeof data[field].validation === 'function') {
            console.log('function ', data[field].validation());
            return data[field].validation();
        }

        switch(data[field].validation) {
            case 'email':
                return isValidEmail(data[field]);
                break;
            case 'password':
                return this.isValidPassword(data[field]);
                break;
            case 'confirm':
                return isValidConfirmedPassword(data[field].value, '');
                break;
            case 'checkbox':
                return isValidCheckbox(data[field].value);
            case 'number':
                return isValidNumber(data[field].value);
            default:
                if(!data[field].value) {
                    return false;
                }
                return true;
        }

    },
    isValidPassword(password) {

        // empty
        if(!password) {
            return false;
        }

        // length > 6
        if(password.length < 6) {
            return false;
        }

        // upper, lower, numeric and special
        // const pattern = new RegExp(
        //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
        // );
        //
        // if (!pattern.test(password)) {
        //     return false;
        // }

        return true;

    },
    isFormValid(form) {

        let valid = true;

        for (var key in form) {
            if(!this.isValid(form, key)) {
                valid = false;
            }
        }

        return valid;

    },
    getInvalidFields(form) {
        let fields = [];
        for (var key in form) {
            if(!this.isValid(form, key)) {
                fields.push(key);
            }
        }
        return fields;

    },
    getFormData(form) {
        let formData = new FormData();
        for (var key in form) {
            formData.append(key, form[key].value);
        }
        return formData;
    },
    getData(form) {
        const data = {};
        for (var key in form) {
            data[key] = form[key].value;
        }
        return data;
    },
    printValues(form) {
        for (var key in form) {
            console.log(key, ': ', form[key].value);
        }
    }
}
