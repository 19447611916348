<template lang="html">

    <div class="company-panel">

        <router-link :to="'/companies/edit/' + company.id">
            <font-awesome-icon icon="edit" class="absolute right-4 top-4 text-primary hover:text-primary-lighter" />
        </router-link>
        <tabs :headings="tabs" :activeIndex="activeTab" @changed="tabChanged" />
        <div class="p-4" v-if="activeTab === 0">

            <div class="flex flex-col md:flex-row">

                <div class="mb-4 md:mr-8">
                    <span class="text-sm block text-primary">Company Name</span>
                    <p class="text-lg">{{ company.company_name }}</p>
                </div>

                <div class="mb-4 md:mr-8">
                    <span class="text-sm block text-primary">Service Tier</span>
                    <p class="text-lg">{{ ( company.service_tier ? company.service_tier.name : '' ) }}</p>
                </div>

                <div class="mb-4">
                    <span class="text-sm block text-primary">Master Policy Set</span>
                    <p class="text-lg">{{ ( company.master_policy_set ? company.master_policy_set.title : '' ) }}</p>
                </div>

            </div>

            <div class="flex flex-col md:flex-row justify-start">
                <uploaded-logo iconSize="6xl" :imageSrc="company.company_logo" class="mr-8 logo-box" />
                <online-status :status="company.company_enable" class="mr-4" />
            </div>

        </div>
        <div class="p-4" v-if="activeTab === 1">

            <div class="flex flex-col md:flex-row">

                <div class="mb-4">
                    <span class="text-sm block text-primary">Billing Period</span>
                    <p class="text-lg">{{ (company.billing_type === 1 ? 'Monthly' : 'Annually' ) }}</p>
                </div>

            </div>

            <div class="flex flex-col md:flex-row">

                <div class="mb-4">
                    <span class="text-sm block text-primary">Billing Price</span>
                    <p class="text-lg">£{{ company.billing_price }}</p>
                </div>

            </div>

        </div>
        <div class="p-4" v-if="activeTab === 2">

            <div class="mb-4 md:mr-4">
                <span class="text-sm block text-primary">Company Address</span>
                <address class="text-md">
                    <p>{{ company.address_line_1 }}</p>
                    <p>{{ company.address_line_1 }}</p>
                    <p>{{ company.city }}</p>
                    <p>{{ company.country }}</p>
                    <p>{{ company.postal_code }}</p>
                </address>
            </div>

        </div>
        <div class="p-4" v-if="activeTab === 3">

            <div class="mb-4 md:mr-4">
                <span class="text-sm block text-primary mb-2">Signature for policies</span>
                <uploaded-logo iconSize="6xl" :imageSrc="company.signature_image" class="mr-8 logo-box" />
            </div>

        </div>

    </div>

</template>

<script>

    import Tabs from '../ui/Tabs'
    import OnlineStatus from '../OnlineStatus'
    import UploadedLogo from '../UploadedLogo'

    export default {
        components: {
            Tabs,
            OnlineStatus,
            UploadedLogo
        },
        data() {
            return {
                tabs: ['Company ', 'Billing', 'Contact', 'Other'],
                activeTab: 0
            };
        },
        methods: {
            tabChanged(i) {
                this.activeTab = i;
            }
        },
        props: {
            company: Object
        },
        mounted() {

        }
    }

</script>

<style lang="css">

    .company-panel {
        box-shadow: 0px 3px 8px #dbdbdb;
        outline: solid 1px #d7d7d7;
        margin-bottom: 48px;
        position: relative;
        background: #fff;
    }

</style>
