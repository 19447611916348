<template>
    <label class="mr-2 flex items-center cursor-pointer" :for="id">
        <div class="fake-checkbox mr-2" :class="{ 'is-light': is_light }">
            <font-awesome-icon icon="check" class="text-xs flex justify-center items-center" :class="{ 'hidden': !checked }" />
        </div>
        <span><slot></slot></span>
        <input type="checkbox" :id="id" class="hidden" @change="$emit('changed')" :checked="checked" :value="value" />
    </label>
</template>

<script>

    export default {
        props: {
            checked: Boolean,
            id: String,
            is_light: {
                type: Boolean,
                default: false
            },
            value: {
                default: ''
            }
        }
    }

</script>

<style lang="css" scoped>

.fake-checkbox {
    width: 16px;
    height: 16px;
    border: solid 2px #747474;
    cursor: pointer;
}

.fake-checkbox.is-light {
    border: solid 2px #fff;
    color: #fff;
}

.fake-checkbox div {
    background: #890;
    width: 14px;
    height: 14px;
}

</style>
