import axios from 'axios';
import apiServer from './../lib/apiServer';

const state = {
    loading: {
        import: false
    }
};

const mutations = {
    setUsersLoading(state, data) {
        state.loading[data.section] = data.loading;
    }
};

const actions = {
    userList({commit, rootState}, data) {

        console.log('data ', data);

        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/company/' + data.companyId + '/users?searchKeyword=' + data.searchKeyword,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    userAdd({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/company/' + data.get('company_id') + '/users',
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    userGet({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/company/' + data.companyId + '/user/' + data.userId,
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    userUpdate({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/company/' + data.get('companyId') + '/user/' + data.get('userId'),
                data: data,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    userImport({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/company/' + data.get('companyId') + '/users/import',
                data: data,
                loadingSection: 'import',
                loadingFunc: 'setUsersLoading',
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    grantAccess({commit, rootState}, data) {
        // v2/company/{companyId}/users/grantAccess
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/company/' + data.companyId + '/users/grantAccess',
                data: { email: data.email },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    // userCsvExample({commit, rootState}, companyId) {
    //     return new Promise((resolve, reject) => {
    //         apiServer.post(commit, rootState, {
    //             url: '/v2/company/' + companyId + '/users/download',
    //             success(data) { resolve(data.data) },
    //             error(err) { reject() }
    //         });
    //     });
    // },
    userDelete({commit, rootState}, data) {
        // v2/company/{companyId}/users/{userId}
        return new Promise((resolve, reject) => {
            apiServer.delete(commit, rootState, {
                url: '/v2/company/' + data.companyId + '/users/' + data.userId,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
};

export default {
    state,
    mutations,
	actions
}
