<template lang="html">

    <div class="overlay scrollbar pb-8 mb-8" :style="{ display: ( isOpen ? 'block' : 'none' ) }" :class="{'loading-white': loading}">

        <div class="flex justify-center items-start mt-20 h-full">

            <div class="confirm-box modal-inner bg-white rounded relative mb-20">
                <font-awesome-icon icon="times-circle" class="absolute -right-3 -top-3 text-white text-2xl cursor-pointer" @click="$emit('closed')" />
                <div class="bg-primary text-white rounded-t px-4 py-2">{{ header }}</div>
                <div class="p-8 modal-body scrollbar overflow-y-scroll">
                    <slot></slot>
                </div>
            </div>

        </div>

    </div>

</template>

<script>

    export default {
        props: {
            message: String,
            loading: {
                type: Boolean,
                default: false
            },
            header: String,
            isOpen: Boolean,
            id: Number
        }
    }

</script>

<style lang="css">

    .overlay {
        position: fixed;
        background: #000000c9;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        z-index: 10;
        overflow-y: scroll;
    }

    .modal-inner {
        width: 90%;
        max-width: 800px;
    }

    .modal-sm .modal-inner {
        max-width: 400px;
    }

    .modal-body {
        max-height: 380px;
    }

</style>
