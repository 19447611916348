import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    getPolicyProfiles({commit, rootState}, company_id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/policy-profile/get-users',
                data: {
                    company_id: company_id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getPolicyProfile({commit, rootState}, searchKeyword) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/policy-profile/user_id',
                data: {
                    user_id: rootState.user.user.id,
                    searchKeyword: searchKeyword
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    addPolicyToProfile({commit, rootState, getters}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/policy-profile/create',
                data: {
                    add_to_profile: "add",
                    company_id: getters.currentCompany,
                    company_policy_id: data.company_policy_id,
                    company_site_id: data.company_site_id,
                    user_id: data.user_id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    removePolicyFromProfile({commit, rootState, getters}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/policy-profile/create',
                data: {
                    add_to_profile: "remove",
                    company_id: getters.currentCompany,
                    company_policy_id: data.company_policy_id,
                    company_site_id: data.company_site_id,
                    user_id: data.user_id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getCompanyLessonsByCompanyPolicy({commit, rootState, getters}, company_policy_id) {

        // console.log('getters.currentCompany ', getters.currentCompany);

        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/company-lesson/company-lesson-list-by-policy',
                data: {
                    // company_id: getters.currentCompany,
                    company_policy_id: company_policy_id,
                    user_id: rootState.user.user.id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getCompanyMyPolicyProfile({commit, rootState, getters}, company_policy_id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/manage-policy-profile/get-company-my-policy-profile',
                data: {
                    // company_id: getters.currentCompany,
                    company_policy_id: company_policy_id,
                    user_id: rootState.user.user.id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getUserPolicyDetails({commit, rootState, getters}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/policy-profile/get-user-policy-details',
                data: {
                    data: {
                        company_id: getters.currentCompany,
                        user_id: data.userId
                    },
                    searchKeyword: data.searchKeyword
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    addUserStatistic({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/user-statistics/add-user-statistics',
                data: {
                    company_policy_id: data.company_policy_id,
                    company_site_id: null,
                    component_id: data.component_id,
                    component_type: 1,
                    percentage: 100,
                    user_id: rootState.user.user.id
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    bulkAssignPolicy({ commit, rootState, getters }, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/policy-profile/bulk-create',
                data: {
                    company_id: getters.currentCompany,
                    users: data.users,
                    policies: data.policies
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    updateReadDuration({commit, rootState, dispatch}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/v2/lesson-read/upsert',
                data: {
                    company_policy_id: data.company_policy_id,
                    company_lesson_id: data.company_lesson_id,
                    read_duration: data.read_duration
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    getReadDuration({commit, rootState, dispatch}, data) {
        return new Promise((resolve, reject) => {
            apiServer.get(commit, rootState, {
                url: '/v2/lesson-read/' + data.lessonId,
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
            // // v2/lesson-read/{lessonId}
            // setTimeout(() => {
            //     resolve({ read_duration: 100 });
            // }, 1000);

        });
    }
};

export default {
	actions
}
