<template lang="html">
    <div>

        <panel class="p-4">

            <div class="md:flex">

                <div class="w-full md:w-1/2 md:mr-2">

                    <form-group class="mb-4">
                        <form-label :required="true">Title</form-label>
                        <input type="text"
                               class="form-input"
                               :class="{ invalid: !isValid('exam_title') && showErrors }"
                               v-model="form.exam_title.value" />
                        <validation-message :show="showErrors && !isValid('exam_title')">title required</validation-message>
                    </form-group>

                    <form-group class="mb-4">
                        <form-label :required="true">Description</form-label>
                        <input type="text"
                               class="form-input"
                               :class="{ invalid: !isValid('exam_description') && showErrors }"
                               v-model="form.exam_description.value" />
                        <validation-message :show="showErrors && !isValid('exam_description')">description required</validation-message>
                    </form-group>

                    <form-group class="mb-4">
                        <form-label :required="true">Marks</form-label>
                        <input type="number"
                               min="0"
                               class="form-input"
                               :class="{ invalid: !isValid('exam_marks') && showErrors }"
                               v-model="form.exam_marks.value" />
                        <validation-message :show="showErrors && !isValid('exam_marks')">exam marks required</validation-message>
                    </form-group>

                    <form-group class="mb-4">
                        <form-label :required="true">Pass Score</form-label>
                        <input type="number"
                               min="0"
                               class="form-input"
                               :class="{ invalid: !isValid('pass_score') && showErrors }"
                               v-model="form.pass_score.value" />
                        <validation-message :show="showErrors && !isValid('pass_score')">pass score required</validation-message>
                    </form-group>

                </div>

                <div class="w-full md:w-1/2 md:ml-2">

                    <form-group class="mb-4">
                        <form-label :required="true">Expected Duration ( Minutes )</form-label>
                        <input type="number"
                               min="0"
                               class="form-input"
                               :class="{ invalid: !isValid('expected_duration') && showErrors }"
                               v-model="form.expected_duration.value" />
                        <validation-message :show="showErrors && !isValid('expected_duration')">expected duration required</validation-message>
                    </form-group>

                    <form-group class="mb-4">
                        <form-label :required="true">Expiration Date</form-label>
                        <datepicker :value="form.expiration_date.value"
                                    name="expiration_date"
                                    :class="{ invalid: !isValid('expiration_date') && showErrors }"
                                    format="d-MM-yy"
                                    @selected="expirationDateChoose"></datepicker>
                        <validation-message :show="showErrors && !isValid('expiration_date')">expiration date required</validation-message>
                    </form-group>

                    <form-group class="mb-4">
                        <form-label :required="true">Policy</form-label>
                            <select class="form-input"
                                    :class="{ invalid: !isValid('policy_id') && showErrors }"
                                    v-model="form.policy_id.value">
                                <option value="">Choose Policy</option>
                                <option v-for="policy in policies" :value="policy.id">{{ policy.policy_title }}</option>
                            </select>
                        <validation-message :show="showErrors && !isValid('policy_id')">poliy id required</validation-message>
                    </form-group>

                </div>

            </div>

            <div class="flex mt-2">
                <app-button class="mr-4" color="secondary" @click="onSubmit">Save</app-button>
                <router-link :to="'/master-test-sets/' + $route.params.id + '/exams'">
                    <app-button>Back</app-button>
                </router-link>
            </div>

        </panel>

    </div>
</template>

<script>

    import Panel from '../../../components/ui/Panel'
    import AppButton from '../../../components/ui/AppButton'
    import FormGroup from '../../../components/forms/FormGroup'
    import FormSection from '../../../components/forms/FormSection'
    import FormLabel from '../../../components/forms/FormLabel'
    import FakeCheckbox from '../../../components/forms/FakeCheckbox'
    import ValidationMessage from '../../../components/forms/ValidationMessage'
    import Datepicker from 'vuejs-datepicker';

    import formHelpers from '../../../lib/form-helpers'

    export default {
        components: {
            Panel,
            AppButton,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            ValidationMessage,
            Datepicker
        },
        data() {
            return {
                today: new Date(),
                showErrors: false,
                policies: [],
                form: {
                    test_set_id: { value: this.$route.params.id, required: true },
                    exam_title: { value: '', required: true },
                    policy_id: { value: '', required: true },
                    exam_marks: { value: null, required: true },
                    pass_score: { value: null, required: true },
                    exam_description: { value: '', required: true },
                    expected_duration: { value: '', required: true },
                    expiration_date: { value: '', required: true, validation: 'date' }
                }
            };
        },
        methods: {
            onSubmit() {

                const data = formHelpers.getData(this.form);

                if(formHelpers.isFormValid(this.form)) {
                    this.$emit('onSubmit', data);
                }else{
                    this.showErrors = true;
                    const invalidFilds = formHelpers.getInvalidFields(this.form);
                    console.log(invalidFilds[0] + ' required');
                }

            },
            expirationDateChoose(e) {
                const day = e.getDate();
                const month = e.getMonth() + 1;
                const year = e.getFullYear();
                this.form.expiration_date.value = year + '-' + (month > 9 ? month : '0' + month) + '-' + (day > 9 ? day : '0' + day);
            },
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            setForm(data) {
                this.form.exam_title.value = data.exam_title;
                this.form.policy_id.value = data.policy_id;
                this.form.exam_marks.value = data.exam_marks;
                this.form.pass_score.value = data.pass_score;
                this.form.exam_description.value = data.exam_description;
                this.form.expected_duration.value = data.expected_duration;
                this.form.expiration_date.value = data.expiration_date.split(' ')[0];
            }
        },
        mounted() {

            this.$store.dispatch('getPoliciesFromTestSet', this.$route.params.id).then((policies) => {
                this.policies = policies;
            });

        }
    }

</script>

<style lang="css">
</style>
