import axios from 'axios';
import apiServer from './../lib/apiServer';

const actions = {
    masterPolicyShow({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '',
                data: { searchKeyword },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicyAdd({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy/create',
                data: {
                    policy_title: data.get('policy_title'),
                    description: data.get('description'),
                    version: data.get('version'),
                    policy_set_id: data.get('policy_set_id'),
                    expiration_date: data.get('expiration_date')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicyGet({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy/id',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicyUpdate({commit, rootState}, data) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy/update',
                data: {
                    id: data.get('id'),
                    policy_title: data.get('policy_title'),
                    description: data.get('description'),
                    version: data.get('version'),
                    policy_set_id: data.get('policy_set_id'),
                    expiration_date: data.get('expiration_date')
                },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    },
    masterPolicyDelete({commit, rootState}, id) {
        return new Promise((resolve, reject) => {
            apiServer.post(commit, rootState, {
                url: '/master-policy/delete',
                data: { id },
                success(data) { resolve(data.data) },
                error(err) { reject() }
            });
        });
    }
}

export default {
	actions
}
