<template lang="html">
    <app-layout :class="{ 'loading-white': ( $store.state.loading ? true : false ) }">

        <bread-crumbs :company="company" page="Edit User" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Update User</h1>
        </div>

        <panel class="p-4" :class="{ 'loading-white': $store.state.loading }">

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:mr-2 md:pr-2 mb-4">
                    <form-label :required="true">First Name</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('first_name') && showErrors }"
                           v-model="form.first_name.value" />
                    <validation-message :show="showErrors && !isValid('first_name')">first name required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:ml-2 md:pr-2 mb-4">
                    <form-label :required="true">Last Name</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('last_name') && showErrors }"
                           v-model="form.last_name.value" />
                    <validation-message :show="showErrors && !isValid('last_name')">last name required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:mr-2 md:pr-2 mb-4">
                    <form-label :required="true">Email</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('email') && showErrors }"
                           v-model="form.email.value" />
                    <validation-message :show="showErrors && !isValid('email')">email required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:ml-2 md:pr-2 mb-4">
                    <form-label :required="true">Mobile No.</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('phone_no') && showErrors }"
                           v-model="form.phone_no.value" />
                    <validation-message :show="showErrors && !isValid('phone_no')">mobile number required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:mr-2 md:pr-2 mb-4">
                    <form-label :required="true">Address Line One</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('address_line_1') && showErrors }"
                           v-model="form.address_line_1.value" />
                    <validation-message :show="showErrors && !isValid('address_line_1')">address line one required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:ml-2 md:pr-2 mb-4">
                    <form-label :required="false">Address Line Two</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('address_line_2') && showErrors }"
                           v-model="form.address_line_2.value" />
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:mr-2 md:pr-2 mb-4">
                    <form-label :required="true">City</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('city') && showErrors }"
                           v-model="form.city.value" />
                    <validation-message :show="showErrors && !isValid('city')">City required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:ml-2 md:pr-2 mb-4">
                    <form-label :required="true">Country</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('country') && showErrors }"
                           v-model="form.country.value" />
                    <validation-message :show="showErrors && !isValid('country')">country required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:mr-2 md:pr-2 mb-4">
                    <form-label :required="true">Postal code</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('postal_code') && showErrors }"
                           v-model="form.postal_code.value" />
                    <validation-message :show="showErrors && !isValid('postal_code')">postal code required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:ml-2 md:pr-2 mb-4">
                    <form-label :required="true">Job Role</form-label>
                    <select v-model="form.job_title_id.value"
                            class="form-input"
                            :class="{ invalid: !isValid('job_title_id') && showErrors }">
                        <option value="">Choose Job Role</option>
                        <option v-for="job in jobTitles" :value="job.id">{{ job.title }}</option>
                    </select>
                    <validation-message :show="showErrors && !isValid('job_title_id')">job role required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:mr-2 md:pr-2 mb-4">
                    <form-label>What sites can user access?</form-label>
                    <app-table>
                        <template #thead>
                            <tr class="text-white">
                                <th>Site</th>
                            </tr>
                        </template>
                        <template #tbody>
                            <tr v-for="(site, i) in sites">
                                <td>
                                    <fake-checkbox :id="'site_' + site.id"
                                                    @changed="toggleSite(site.id)"
                                                    :checked="userHasSite(site.id)"
                                                    :value="site.id">{{ site.site_name }}</fake-checkbox>
                                </td>
                            </tr>
                        </template>
                    </app-table>
                </form-group>

                <form-group class="w-full md:w-1/2 md:mr-2 md:pr-2 mb-4">
                    <form-label :required="true">Lock/Unlock user account</form-label>
                    <select v-model="form.status.value" class="form-input">
                        <option value="">Choose user account</option>
                        <option value="2">Lock</option>
                        <option value="1">Unlock</option>
                    </select>
                </form-group>

            </div>

            <hr class="mb-8 text-grey-light" />

            <div>

                <img v-if="imageUploadPreview" :src="imageUploadPreview" class="mx-auto mt-2 mb-4 logo-preview" />
                <div v-else>
                    <font-awesome-icon icon="user-circle" class="logo-icon mb-4" />
                </div>

                <label for="profile_picture">
                    <input id="profile_picture" class="hidden" type="file" value="Click here to browse" @change="profilePictureChanged" />
                    <div class="bg-secondary cursor-pointer text-white rounded p-2 w-60 mx-auto mb-2">{{ imageUploadText }}</div>
                </label>

            </div>

            <div class="flex">
                <app-button class="mr-2" @click="submit">Update User</app-button>
                <cancel-button :companyId="company.id" />
            </div>

        </panel>

    </app-layout>
</template>

<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import Panel from '../../../components/ui/Panel'
    import AppButton from '../../../components/ui/AppButton'
    import IconButton from '../../../components/ui/IconButton'
    import AppTable from '../../../components/ui/AppTable'
    import BreadCrumbs from '../../../components/companies/BreadCrumbs'
    import CancelButton from '../../../components/companies/CancelButton'

    import FormGroup from '../../../components/forms/FormGroup'
    import FakeCheckbox from '../../../components/forms/FakeCheckbox'
    import FormLabel from '../../../components/forms/FormLabel'
    import ValidationMessage from '../../../components/forms/ValidationMessage'

    import formHelpers from '../../../lib/form-helpers'

    export default {
        components: {
            AppLayout,
            Panel,
            AppButton,
            IconButton,
            AppTable,
            BreadCrumbs,
            CancelButton,
            FormGroup,
            FakeCheckbox,
            FormLabel,
            ValidationMessage
        },
        data() {
            return {
                showErrors: false,
                imageUploadText: 'Click here to browse',
                imageUploadPreview: null,
                form: {
                    company_id: { value: null, required: true },
                    first_name: { value: '', required: true },
                    last_name: { value: '', required: true },
                    job_title_id: { value: '', required: true },
                    email: { value: '', required: true, validation: 'email' },
                    phone_no: { value: '', required: true, validation: 'phone' },
                    address_line_1: { value: '', required: true },
                    address_line_2: { value: '', required: false },
                    city: { value: '', required: true },
                    country: { value: '', required: true },
                    postal_code: { value: '', required: true },
                    status: { value: 1, required: true },
                    profile_picture: { value: '', required: false },
                    sites: { value: [], required: false }
                },
                company: {
                    company_name: null
                },
                jobTitles: [
                    { id: 2, title: 'Company Admin' },
                    { id: 5, title: 'User or Staff' }
                ],
                sites: [],
                siteIds: []
            };
        },
        methods: {
            userHasSite(siteId) {
                return this.siteIds.includes(siteId);
            },
            toggleSite(siteId) {
                if(this.userHasSite(siteId)) {
                    var index = this.siteIds.indexOf(siteId);
                    if (index !== -1) {
                        this.siteIds.splice(index, 1);
                    }
                }else{
                    this.siteIds.push(siteId);
                }
            },
            profilePictureChanged(e) {
                if(e.target.files.length > 0) {
                    const file = e.target.files[0];
                    this.imageUploadText = file.name;
                    this.form.profile_picture.value = file;
                    this.imageUploadPreview = URL.createObjectURL(file);
                }
            },
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            submit() {

                const self = this;

                if(formHelpers.isFormValid(this.form)) {

                    const data = formHelpers.getFormData(this.form, true);
                    data.append('sites', this.siteIds);
                    data.append('companyId', this.$route.params.companyId);
                    data.append('userId', this.$route.params.userId);
                    this.$store.dispatch('userUpdate', data).then((success) => {

                        if(success) {
                            this.$fire({
                                title: "User Updated",
                                text: success.message,
                                type: "success",
                                timer: 3000
                            });
                            this.$router.push(this.$store.getters.viewCompanyLink(this.company.id));
                        }

                    });
                }else{
                    this.showErrors = true;
                }

            }
        },
        mounted() {

            Promise.all([
                this.$store.dispatch('companiesGet', this.$route.params.companyId),
                // this.$store.dispatch('getJobTitles'),
                this.$store.dispatch('companySiteList', this.$route.params.companyId),
                this.$store.dispatch('userGet', {companyId: this.$route.params.companyId, userId: this.$route.params.userId})
            ]).then((data) => {

                this.company = data[0];
                // this.jobTitles = data[1];
                this.sites = data[1];
                const user = data[2].user;

                this.form.company_id.value = this.company.id;
                this.form.first_name.value = user.first_name;
                this.form.last_name.value = user.last_name;
                this.form.job_title_id.value = user.job_title_id;
                this.form.email.value = user.email;
                this.form.phone_no.value = user.phone_no;
                this.form.address_line_1.value = user.address_line_1;
                this.form.address_line_2.value = user.address_line_2;
                this.form.city.value = user.city;
                this.form.country.value = user.country;
                this.form.postal_code.value = user.postal_code;
                this.form.status.value = user.status;
                this.form.profile_picture.value = user.profile_picture;

                user.company_sites.forEach((site, i) => {
                    this.siteIds.push(site.id);
                });

                if(user.profile_picture) {
                    this.imageUploadPreview = data[2].uploadsPath + '/' + user.profile_picture;
                }

            });
        }
    }

</script>

<style lang="css">
</style>
