<template lang="html">
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Manage Master Policy Sets</h1>
            <router-link to="/master-policy-sets/add">
                <app-button>Add Master Policy Set</app-button>
            </router-link>
        </div>

        <app-table :class="{ 'loading-white': $store.state.loading }">
            <template #thead>
                <tr class="text-white">
                    <th>Name</th>
                    <th class="text-right">
                        <input class="bg-primary border-b placeholder-white focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search" />
                        <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
                    </th>
                </tr>
            </template>
            <template #tbody>
                <tr v-for="policySet in masterPolicySets">
                    <td>{{ policySet.title }}</td>
                    <td>
                        <div class="flex justify-end">
                            <router-link :to="'/master-policy-sets/' + policySet.id + '/edit'">
                                <icon-button icon="edit" color="secondary" class="ml-2" />
                            </router-link>
                            <router-link :to="'/master-policy-sets/' + policySet.id + '/duplicate'">
                                <icon-button icon="copy" color="danger" class="ml-2" />
                            </router-link>
                            <router-link :to="'/master-policy-sets/' + policySet.id + '/policies'">
                                <icon-button icon="eye" color="secondary" class="ml-2" />
                            </router-link>
                            <icon-button icon="trash-alt" color="danger" class="ml-2" @click="areYouSureOpen = true; currentDeleteId = policySet.id" />
                        </div>
                    </td>
                </tr>
            </template>
        </app-table>

        <pop-up-confirm message="Are you sure you want to delete this master policy set?"
                        :isOpen="areYouSureOpen"
                        :id="currentDeleteId"
                        @canceled="areYouSureOpen = false"
                        @confirmed="deleteMasterPolicySet" />

    </app-layout>
</template>

<script>

    import AppLayout from '../../components/layout/AppLayout'
    import Panel from '../../components/ui/Panel'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import AppTable from '../../components/ui/AppTable'
    import PopUpConfirm from '../../components/PopUpConfirm'

    export default {
        components: {
            AppLayout,
            Panel,
            AppButton,
            IconButton,
            AppTable,
            PopUpConfirm
        },
        data() {
            return {
                areYouSureOpen: false,
                currentDeleteId: null,
                searchKeyword: '',
                masterPolicySets: []
            }
        },
        methods: {
            search() {
                this.$store.dispatch('masterPolicySetList', this.searchKeyword).then((masterPolicySets) => {
                    this.masterPolicySets = masterPolicySets;
                });
            },
            getMasterPolicySets(done) {
                this.$store.dispatch('masterPolicySetList').then((masterPolicySets) => {
                    this.masterPolicySets = masterPolicySets;
                    if(done) {
                        done();
                    }
                });
            },
            deleteMasterPolicySet() {
                this.areYouSureOpen = false;
                this.$store.dispatch('masterPolicySetDelete', this.currentDeleteId).then((success) => {
                    this.getMasterPolicySets(() => {
                        this.$fire({
                            title: "Master Policy Set Deleted",
                            text: success.message,
                            type: "success",
                            timer: 3000
                        });
                    });
                });
            }
        },
        mounted() {
            this.getMasterPolicySets();
        }
    }

</script>

<style lang="css">



</style>
