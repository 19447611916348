import MasterTestSets from '../views/MasterTestSets'
import MasterTestSetsAdd from '../views/MasterTestSets/Add'
import MasterTestSetsEdit from '../views/MasterTestSets/Edit'
import MasterTestSetsDuplicate from '../views/MasterTestSets/Duplicate'
import Exams from '../views/MasterTestSets/Exams'
import ExamsAdd from '../views/MasterTestSets/Exams/Add'
import ExamsEdit from '../views/MasterTestSets/Exams/Edit'
import Questions from '../views/MasterTestSets/Exams/Questions'
import QuestionsAdd from '../views/MasterTestSets/Exams/Questions/Add'
import QuestionsEdit from '../views/MasterTestSets/Exams/Questions/Edit'

export default {
    routes: [
        {
            path: '/master-test-sets',
            name: 'MasterTestSets',
            component: MasterTestSets,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-test-sets/add',
            name: 'MasterTestSetsAdd',
            component: MasterTestSetsAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-test-sets/:id/edit',
            name: 'MasterTestSetsEdit',
            component: MasterTestSetsEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-test-sets/:id/duplicate',
            name: 'MasterTestSetsDuplicate',
            component: MasterTestSetsDuplicate,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        // exams
        {
            path: '/master-test-sets/:id/exams',
            name: 'Exams',
            component: Exams,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-test-sets/:id/exams/add',
            name: 'ExamsAdd',
            component: ExamsAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-test-sets/:id/exams/:examId/edit',
            name: 'ExamsEdit',
            component: ExamsEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        // questions
        {
            path: '/master-test-sets/:id/exams/:examId/questions',
            name: 'Questions',
            component: Questions,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-test-sets/:id/exams/:examId/questions/add',
            name: 'QuestionsAdd',
            component: QuestionsAdd,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        },
        {
            path: '/master-test-sets/:id/exams/:examId/questions/:questionId/edit',
            name: 'QuestionsEdit',
            component: QuestionsEdit,
            meta: {
                allowAnonymous: false,
                jobRoles: ['super_admin']
            }
        }
    ]
}
