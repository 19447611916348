<template>
    <div :class="styles"
         @click="$emit('click')">
        <font-awesome-icon :icon="icon" @click="" />
    </div>
</template>

<script>

    export default {
        name: 'IconButton',
        props: {
            color: {
                type: String,
                default: 'primary'
            },
            icon: String,
            size: {
                type: String,
                default: 'md'
            }
        },
        computed: {
            styles() {
                const bgColor = 'bg-' + this.color;
                const bgHover = 'hover:bg-' + this.color + '-lighter';
                const iconSize = 'icon-size-' + this.size;
                let styles = [
                    bgColor,
                    bgHover,
                    'rounded',
                    'text-white',
                    'cursor-pointer',
                    'flex',
                    'justify-center',
                    'items-center',
                    'icon-button',
                    iconSize
                ];

                let styleString = '';
                styles.forEach((s) => {
                    styleString += s + ' ';
                });

                return styleString;
            }
        }
    }

</script>

<style lang="css" scoped>

    .icon-button {
        padding: 0px;
    }

    .icon-size-sm {
        height: 28px;
        width: 28px;
        font-size: 12px;
    }

    .icon-size-md {
        height: 32px;
        width: 32px;
    }

</style>
