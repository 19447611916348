<template>
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8 wrap-none whitespace-nowrap">Process Maps</h1>
            <select class="form-input w-80" v-model="selectedPolicySet" @change="getMapsByPolicySet">
                <option value="">Filter Process Map By Policy Set</option>
                <option :value="policySet.id" v-for="policySet in masterPolicySets">{{ policySet.title }}</option>
            </select>
            <router-link to="/process-maps/add">
                <app-button class="whitespace-nowrap">Add New Process Map</app-button>
            </router-link>
        </div>

        <app-table :class="{ 'loading-white': $store.state.loading }">
            <template #thead>
                <tr class="text-white">
                    <th>Process Map Name</th>
                    <th>Policy</th>
                    <th class="text-right">
                        <input class="bg-primary border-b placeholder-white focus:outline-none" v-model="searchKeyword" type="search" placeholder="Search" />
                        <font-awesome-icon icon="search" class="cursor-pointer" @click="search" />
                    </th>
                </tr>
            </template>
            <template #tbody>
                <tr v-for="(map, i) in processMaps">
                    <td>{{ map.process_map_title }}</td>
                    <td>{{ map.policy.policy_title }}</td>
                    <td>
                        <div class="flex justify-end items-center">
                            <router-link :to="'/process-maps/' + map.id + '/edit'">
                                <icon-button icon="edit" color="secondary" />
                            </router-link>
                            <icon-button @click="showInstructions(i)" icon="clipboard-list" color="secondary" class="ml-2" />
                            <icon-button @click="showMap(i)" icon="eye" color="secondary" class="ml-2" />
                            <icon-button icon="trash-alt" color="danger" class="ml-2" @click="deleteProcessMap(map.id)" />
                        </div>
                    </td>
                </tr>
            </template>
        </app-table>

        <!-- <p>{{ 'https://view.officeapps.live.com/op/embed.aspx?src=' + currentProcessMap.document }}</p> -->

        <modal header="See Instructions"
               :isOpen="instructionsOpen"
               v-if="currentProcessMap"
               @closed="instructionsOpen = false">
            <p>{{ currentProcessMap.instructions }}</p>
        </modal>

        <modal header="View Document"
               :isOpen="documentOpen"
               v-if="currentProcessMap"
               @closed="documentOpen = false">
           <iframe :src="'https://view.officeapps.live.com/op/embed.aspx?src=' + currentProcessMap.document" style="width: 100%; height: 500px;" />
        </modal>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import AppButton from '../../components/ui/AppButton'
import IconButton from '../../components/ui/IconButton'
import AppTable from '../../components/ui/AppTable'
import Modal from '../../components/ui/Modal'

export default {
    components: {
        AppLayout,
        Panel,
        AppButton,
        IconButton,
        AppTable,
        Modal
    },
    data() {
        return {
            searchKeyword: '',
            selectedPolicySet: '',
            processMaps: [],
            masterPolicySets: [],
            instructionsOpen: false,
            documentOpen: false,
            currentProcessMap: {
                document: null
            }
        };
    },
    methods: {
        showInstructions(i) {
            this.currentProcessMap = this.processMaps[i];
            this.instructionsOpen = true;
        },
        showMap(i) {
            this.currentProcessMap = this.processMaps[i];
            this.documentOpen = true;
        },
        deleteProcessMap(id) {
            this.$confirm("Are you sure you want to delete this process map?").then(() => {

                this.$store.dispatch('processMapDelete', id).then(() => {
                    this.search();
                    this.$fire({
                        title: "Process map deleted",
                        text: "",
                        type: "success",
                        timer: 3000
                    });
                });

            });
        },
        getMapsByPolicySet() {
            const data = {
                policySetId: this.selectedPolicySet,
                searchKeyword: ''
            }
            this.$store.dispatch('processMapListBySetId', data).then((processMaps) => {
                this.processMaps = processMaps;
            });
        },
        search() {
            const data = { searchKeyword: this.searchKeyword };
            this.$store.dispatch('processMapList', data).then((processMaps) => {
                this.processMaps = processMaps;
            });
        }
    },
    mounted() {

        this.$store.dispatch('masterPolicySetList').then((masterPolicySets) => {
            this.masterPolicySets = masterPolicySets;
            this.search();
        });

    }
}

</script>

<style lang="css">

</style>
