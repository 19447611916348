<template lang="html">
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Master Test Sets', href: '/master-test-sets' },
            { name: 'Add Master Test Set' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Add Master Test Set</h1>
        </div>

        <panel class="p-8 px-8 mb-8 w-full md:w-2/3" :class="{ 'loading-white' : loading }">

            <form-group class="mb-4">
                <form-label :required="true">Master Test Set Name</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('name') && showErrors }"
                       v-model="form.name.value" />
                <validation-message :show="showErrors && !isValid('name')">name required</validation-message>
            </form-group>

            <form-group class="mb-4">
                <form-label :required="true">Enter Description</form-label>
                <textarea class="form-input"
                       :class="{ invalid: !isValid('description') && showErrors }"
                       v-model="form.description.value"></textarea>
                <validation-message :show="showErrors && !isValid('description')">description required</validation-message>
            </form-group>

            <form-group class="mb-4">
                <form-label :required="true">Master Policy Set</form-label>
                <select class="form-input" v-model="form.master_policy_set_id.value">
                    <option value="">Choose Master Policy Set</option>
                    <option v-for="mps in masterPolicySets" :value="mps.id">{{ mps.title }}</option>
                </select>
                <validation-message :show="showErrors && !isValid('master_policy_set_id')">master policy set required</validation-message>
            </form-group>

            <div class="flex mt-8">
                <app-button class="mr-4" color="secondary" @click="submit">Submit</app-button>
                <router-link to="/master-test-sets">
                    <app-button>Back</app-button>
                </router-link>
            </div>

        </panel>

    </app-layout>
</template>
<script>

    import AppLayout from '../../components/layout/AppLayout'
    import Panel from '../../components/ui/Panel'
    import BreadCrumbs from '../../components/ui/BreadCrumbs'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import AppTable from '../../components/ui/AppTable'
    import PopUpConfirm from '../../components/PopUpConfirm'
    import FormGroup from '../../components/forms/FormGroup'
    import FormSection from '../../components/forms/FormSection'
    import FormLabel from '../../components/forms/FormLabel'
    import FakeCheckbox from '../../components/forms/FakeCheckbox'
    import ValidationMessage from '../../components/forms/ValidationMessage'

    import formHelpers from '../../lib/form-helpers'

    export default {
        components: {
            AppLayout,
            Panel,
            BreadCrumbs,
            AppButton,
            IconButton,
            AppTable,
            PopUpConfirm,
            FormSection,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            ValidationMessage
        },
        data() {
            return {
                showErrors: false,
                loading: false,
                masterPolicySets: [],
                form: {
                    name: { value: '', required: true },
                    description: { value: '', required: true },
                    master_policy_set_id: { value: '', required: true }
                }
            }
        },
        methods: {
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            submit() {

                if(formHelpers.isFormValid(this.form)) {
                    const data = {
                        description: this.form.description.value,
                        master_policy_set_id: this.form.master_policy_set_id.value,
                        name: this.form.name.value
                    };
                    this.$store.dispatch('addMasterTestSet', data).then((success) => {
                        if(success) {

                            this.$fire({
                                title: "Master Test Set Added",
                                text: success.message,
                                type: "success",
                                timer: 3000
                            });

                            this.$router.push('/master-test-sets');
                        }
                    });
                }else{
                    this.showErrors = true;
                }

            }
        },
        mounted() {

            this.loading = true;

            this.$store.dispatch('masterPolicySetList').then((masterPolicySets) => {
                console.log('masterPolicySets ', masterPolicySets);
                this.masterPolicySets = masterPolicySets;
                this.loading = false;
            });

        }
    }

</script>
