<template>
    <app-layout>

        <h1 class="hdln-1 mb-8">Reports</h1>

        <div class="flex flex-wrap" :class="{ 'loading-white' : loading }">


            <div v-for="company in companies" class="report bg-secondary p-4 rounded text-white w-60 mr-4 mb-4">

                <p class="font-bold">{{ company.company_name }}</p>

                <hr class="block my-2">

                <router-link :to="'/reports/' + company.id + '/by-user'" class="block hover:text-secondary-lighter">
                    <div class="flex items-center">
                        <font-awesome-icon icon="user-circle" class="mr-2" />
                        <span>Report by User</span>
                    </div>
                </router-link>
                <router-link :to="'/reports/' + company.id + '/by-policy'" class="block hover:text-secondary-lighter">
                    <div class="flex items-center">
                        <font-awesome-icon icon="clipboard-list" class="mr-2" />
                        <span>Report by Policy</span>
                    </div>
                </router-link>

                <!-- <font-awesome-icon icon="clipboard-list" class="float-right text-4xl mt-4" /> -->

            </div>

        </div>

    </app-layout>
</template>

<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'

export default {
    components: {
        AppLayout,
        Panel
    },
    computed: {
        // reports() {
        //     return [
        //         { name: 'Policies', link: '/admin/reports/policies' },
        //         { name: 'Users', link: '/reports/users' }
        //     ];
        // }
    },
    data() {
        return {
            companies: [],
            loading: false
        };
    },
    mounted() {
        this.loading = true;
        this.$store.dispatch('companiesList').then((companies) => {
            this.companies = companies;
            this.loading = false;
        });
    }
}

</script>
