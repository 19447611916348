<template lang="html">

    <div>

        <div class="bg-greenbright bg-greenbright-lighter hidden"></div>

        <div class="flex justify-between items-center mb-4">
            <h2 class="text-lg">Tests</h2>
            <div class="flex">
                <router-link :to="'/company/' + companyId + '/tests/generate'">
                    <app-button>Generate Tests</app-button>
                </router-link>
            </div>
        </div>

        <div class="relative">

            <app-table>
                <template #thead>
                    <tr class="text-white">
                        <th>Test</th>
                        <th></th>
                    </tr>
                </template>
                <template #tbody>
                    <tr v-for="test in generatedTests">
                        <td>{{ test.exam_title }}</td>
                        <td class="flex justify-end">
                            <icon-button size="sm" icon="trash-alt" color="danger" @click="deleteGeneratedTest(test.id)" />
                        </td>
                    </tr>
                </template>
            </app-table>

        </div>

    </div>

</template>

<script>

    import AppTable from '../../components/ui/AppTable'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import Modal from '../../components/ui/Modal'
    import UploadedLogo from '../../components/UploadedLogo'

    export default {
        components: {
            AppTable,
            AppButton,
            IconButton,
            Modal,
            UploadedLogo
        },
        props: {
            companyId: Number
        },
        data() {
            return {
                loading: false,
                generatedTests: []
            }
        },
        methods: {
            deleteGeneratedTest(testId) {
                this.$confirm("Are you sure you want to delete this test?").then(() => {

                    const data = {
                        testId,
                        companyId: this.companyId
                    };

                    this.$store.dispatch('companyTestDelete', data).then(() => {
                        this.$store.dispatch('generatedTests', this.companyId).then((data) => {
                            this.generatedTests = data;
                            this.$fire({
                                title: "Test deleted",
                                text: "",
                                type: "success",
                                timer: 3000
                            });
                        });
                    });

                });
            },
            getTests() {
                this.$store.dispatch('generatedTests', this.companyId).then((data) => {
                    this.generatedTests = data;
                });
            }
        }
    }

</script>

<style lang="css">

</style>
