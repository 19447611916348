<template>

    <div>

        <div class="flex flex-col md:flex-row">

            <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                <form-label :required="true">First Name</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('first_name') && showErrors }"
                       v-model="form.first_name.value" />
                <validation-message :show="showErrors && !isValid('first_name')">first name required</validation-message>
            </form-group>

            <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                <form-label :required="true">Last Name</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('last_name') && showErrors }"
                       v-model="form.last_name.value" />
                <validation-message :show="showErrors && !isValid('last_name')">last name required</validation-message>
            </form-group>

        </div>

        <div class="flex flex-col md:flex-row">

            <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                <form-label :required="true">Email</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('email') && showErrors }"
                       v-model="form.email.value" />
                <validation-message :show="showErrors && !isValid('email')">email required</validation-message>
            </form-group>

            <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                <form-label :required="true">Mobile No.</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('phone_no') && showErrors }"
                       v-model="form.phone_no.value" />
                <validation-message :show="showErrors && !isValid('phone_no')">phone number required</validation-message>
            </form-group>

        </div>

        <div v-if="!isSuperAdmin">

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Address Line One</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('address_line_1') && showErrors }"
                           v-model="form.address_line_1.value" />
                    <validation-message :show="showErrors && !isValid('address_line_1')">address line one required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="false">Address Line Two</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('address_line_2') && showErrors }"
                           v-model="form.address_line_2.value" />
                    <validation-message :show="showErrors && !isValid('address_line_2')">last name required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">City</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('city') && showErrors }"
                           v-model="form.city.value" />
                    <validation-message :show="showErrors && !isValid('city')">city required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="true">Country</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('country') && showErrors }"
                           v-model="form.country.value" />
                    <validation-message :show="showErrors && !isValid('country')">country required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Postal code</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('postal_code') && showErrors }"
                           v-model="form.postal_code.value" />
                    <validation-message :show="showErrors && !isValid('postal_code')">postal code required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label>Job Role</form-label>
                    <p>{{ jobRole }}</p>
                </form-group>

            </div>

        </div>

        <div class="clear-both text-left py-1 mt-4">
            <app-button @click="submit">Save Profile</app-button>
        </div>

    </div>

</template>

<script>

    import AppButton from '../../components/ui/AppButton'
    import FormGroup from '../../components/forms/FormGroup'
    import FormLabel from '../../components/forms/FormLabel'
    import ValidationMessage from '../../components/forms/ValidationMessage'

    import formHelpers from '../../lib/form-helpers'

    export default {
        components: {
            AppButton,
            FormGroup,
            FormLabel,
            ValidationMessage
        },
        data() {

            const vm = this;

            return {
                showErrors: false,
                form: {
                    first_name: { value: '', required: true },
                    last_name: { value: '', required: true },
                    email: { value: '', required: true, validation: 'email' },
                    phone_no: { value: '', required: true, validation: 'phone' },
                    include_address: { value: ( this.isSuperAdmin ? 0 : 1 ) },
                    address_line_1: {
                        value: '',
                        required: true,
                        validation: () => {
                            if(vm.isSuperAdmin) {
                                return true;
                            }
                            if(vm.form.address_line_1.value) {
                                return true;
                            }
                            return false;
                        }
                    },
                    address_line_2: {
                        value: '',
                        required: false
                    },
                    city: {
                        value: '',
                        required: true,
                        validation: () => {
                            console.log(vm.isSuperAdmin);
                            if(vm.isSuperAdmin) {
                                return true;
                            }
                            if(vm.form.city.value) {
                                return true;
                            }
                            return false;
                        }
                    },
                    country: {
                        value: '',
                        required: true,
                        validation: () => {
                            if(vm.isSuperAdmin) {
                                return true;
                            }
                            if(vm.form.country.value) {
                                return true;
                            }
                            return false;
                        }
                    },
                    postal_code: {
                        value: '',
                        required: true,
                        validation: () => {
                            if(vm.isSuperAdmin) {
                                return true;
                            }
                            if(vm.form.postal_code.value) {
                                return true;
                            }
                            return false;
                        }
                    }
                }
            }
        },
        computed: {
            isSuperAdmin() {
                return this.$store.getters.userHasRoles(['super_admin']);
            },
            jobRole() {
                const user = this.$store.state.user.user;
                if(user) {
                    return user.job_role.job_title;
                }
                return '';
            }
        },
        methods: {
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            submit() {

                const formData = formHelpers.getFormData(this.form);

                if(formHelpers.isFormValid(this.form)) {

                    this.$store.dispatch('updateProfile', formData).then((successMsg) => {

                        if(successMsg) {
                            this.$fire({
                                title: "Success",
                                text: successMsg,
                                type: "success",
                                timer: 3000
                            });
                        }

                    });

                } else {
                    this.showErrors = true;
                }

            }
        },
        mounted() {

            this.$store.dispatch('getUser').then(() => {

                const user = this.$store.state.user.user;

                this.form.first_name.value = user.first_name;
                this.form.last_name.value = user.last_name;
                this.form.email.value = user.email;
                this.form.phone_no.value = user.phone_no;

                if(!this.isSuperAdmin) {
                    this.form.address_line_1.value = user.address_line_1;
                    this.form.address_line_2.value = user.address_line_2;
                    this.form.city.value = user.city;
                    this.form.country.value = user.country;
                    this.form.postal_code.value = user.postal_code;
                }

            });

        }
    }

</script>

<style lang="css">

</style>
