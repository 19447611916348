<template lang="html">
    <div class="lg:w-1/5 bg-white  rounded p-4 mr-6 mb-6 stat-number">
        <div class="w-40 mx-auto">
            <percentage-circle :circumference="295" :percent="amount" :loading="loading" :type="type">
                <p class="text-3xl">{{ amount }}<span v-if="type === 'percentage'">%</span></p>
            </percentage-circle>
        </div>
        <p class="text-center mt-4"><slot></slot></p>
    </div>
</template>

<script>

import PercentageCircle from '../ui/PercentageCircle'

    export default {
        components: {
            PercentageCircle
        },
        props: {
            type: {
                default: 'number',
                type: String
            },
            amount: Number,
            loading: Boolean
        }
    }

</script>

<style lang="css">

    .stat-number {
        border-radius: 100%;
        color: #4cc790;
        font-weight: bold;
    }

</style>
