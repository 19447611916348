<template lang="html">

    <div class="overlay" :style="{ display: ( isOpen ? 'block' : 'none' ) }">

        <div class="flex justify-center items-center h-full">


            <div class="confirm-box bg-white p-8 text-center rounded">

                <p class="text-lg mb-4">{{ message }}</p>

                <app-button color="secondary" @click="$emit('confirmed')" class="mr-2 w-20">Delete</app-button>
                <app-button @click="$emit('canceled')" class="ml-2 w-20">Cancel</app-button>

            </div>

        </div>

    </div>

</template>

<script>

    import AppButton from './ui/AppButton'

    export default {
        components: {
            AppButton
        },
        props: {
            message: String,
            isOpen: Boolean,
            id: Number
        }
    }

</script>

<style lang="css">

    .overlay {
        position: fixed;
        background: #000000c9;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
    }

</style>
