<template lang="html">
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Placeholders', href: '/placeholders' },
            { name: 'Add Placeholder' }
        ]" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Add Placeholder</h1>
        </div>

        <panel class="p-4">
            <div class="flex flex-col md:flex-row">
                <form-group class="w-full mb-4 md:mr-4">
                    <form-label :required="true">Placeholder Key</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('placeholder_key') && showErrors }"
                           v-model="form.placeholder_key.value" />
                    <validation-message :show="showErrors && !isValid('placeholder_key')">placeholder key required</validation-message>
                </form-group>
                <form-group class="w-full mb-4 md:ml-4">
                    <form-label :required="true">Placeholder Value</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('placeholder_value') && showErrors }"
                           v-model="form.placeholder_value.value" />
                    <validation-message :show="showErrors && !isValid('placeholder_value')">placeholder value required</validation-message>
                </form-group>
            </div>

            <div class="flex">
                <app-button class="mr-2" @click="submit">Add Placeholder</app-button>
                <router-link to="/placeholders/">
                    <app-button color="danger">Cancel</app-button>
                </router-link>
            </div>

        </panel>

    </app-layout>
</template>

<script>

    import AppLayout from '../../components/layout/AppLayout'
    import Panel from '../../components/ui/Panel'
    import AppButton from '../../components/ui/AppButton'
    import IconButton from '../../components/ui/IconButton'
    import BreadCrumbs from '../../components/ui/BreadCrumbs'

    import FormGroup from '../../components/forms/FormGroup'
    import FormSection from '../../components/forms/FormSection'
    import FormLabel from '../../components/forms/FormLabel'
    import FakeCheckbox from '../../components/forms/FakeCheckbox'
    import ValidationMessage from '../../components/forms/ValidationMessage'

    import formHelpers from '../../lib/form-helpers'

    export default {
        components: {
            AppLayout,
            Panel,
            AppButton,
            IconButton,
            BreadCrumbs,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            ValidationMessage
        },
        data() {
            return {
                showErrors: false,
                form: {
                    placeholder_key: { value: '', required: true },
                    placeholder_value: { value: '', required: true }
                }
            }
        },
        methods: {
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            submit() {

                if(formHelpers.isFormValid(this.form)) {
                    const formData = formHelpers.getFormData(this.form);
                    this.$store.dispatch('placeholderAdd', formData).then((success) => {
                        if(success) {
                            this.$router.push('/placeholders');
                        }
                    });
                }else{
                    this.showErrors = true;
                }

            }
        }
    }

</script>

<style lang="css">



</style>
