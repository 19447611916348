import axios from 'axios';

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
    businessTypeList({commit, rootState}, searchKeyword) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/business-type/list', { searchKeyword })
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                    }

                    resolve(res.data.data);
                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    },
    businessTypeGet({commit, rootState}, id) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/business-type/id', { id })
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(res.data.data);
                    }

                    resolve(res.data.data);
                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    },
    businessTypeAdd({commit, rootState}, data) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/business-type/create', data)
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(true);
                    }

                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    },
    businessTypeUpdate({commit, rootState}, data) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/business-type/update', data)
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(true);
                    }

                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    },
    businessTypeDelete({commit, rootState}, id) {
        commit('setLoading', true);
        commit('setError', []);
        return new Promise((resolve, reject) => {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + rootState.token;
            axios.post(rootState.apiUrl + '/business-type/delete', { id })
                .then((res) => {

                    if(!res.data.status) {
                        commit('setError', res.data.message);
                        reject();
                    }else{
                        resolve(true);
                    }

                    commit('setLoading', false);

                })
                .catch((e) => {
                    commit('setError', e);
                    commit('setLoading', false);
                });
        });
    }
};

export default {
	state,
	getters,
	mutations,
	actions
}
