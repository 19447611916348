<template>
    <div class="text-left mb-4">
        <label class="block mb-2 font-bold">
            <font-awesome-icon :icon="icon" class="mr-2 text-lg text-secondary" />
            <span>{{ label }}</span>
        </label>
        <slot></slot>
    </div>
</template>
<script>

    export default {
        name: 'FormGroup',
        props: {
            icon: String,
            label: String
        }
    }

</script>
