<template lang="html">
    <div :class="{ hidden: !loaded }">

        <div class="flex flex-wrap">
            <stat-box type="number" :amount="totalUsers">Number of Users</stat-box>
            <stat-box type="number" :amount="avgPoliciesCompleted">Average Policies Completed</stat-box>
            <stat-box type="number" :amount="avgTestsPassed">Average Tests Passed</stat-box>
        </div>

        <!-- <div class="flex flex-wrap">
            <stat-box type="percentage" :amount="totalPolicyCompleted">Total Policy Completed</stat-box>
            <stat-box type="percentage" :amount="totalTestPassed">Total Test Passed</stat-box>
        </div> -->

    </div>
</template>

<script>

    import StatBox from './StatBox'

    export default {
        components: {
            StatBox
        },
        data() {
            return {
                loaded: false,
                totalUsers: 0,
                avgPoliciesCompleted: 0,
                avgTestsPassed: 0,
                // totalPolicyCompleted: 0,
                // totalTestPassed: 0
            }
        },
        methods: {
            getData(done) {
                this.$store.dispatch('getSuperAdminStats').then((data) => {

                    this.totalUsers = data.totalUsers,
                    this.avgPoliciesCompleted = data.avgPoliciesCompleted,
                    this.avgTestsPassed = data.avgTestsPassed,
                    // this.totalPolicyCompleted = data.totalPolicyCompleted,
                    // this.totalTestPassed = data.totalTestPassed

                    this.loaded = true;
                    done();
                });
            }
        }
    }

</script>

<style lang="css">



</style>
