<template>
    <app-layout>

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Companies</h1>
            <router-link to="/companies/add">
                <app-button>Add New Company</app-button>
            </router-link>
        </div>

        <app-table :class="{ 'loading-white' : loading }">
            <template #thead>
                <tr class="text-white">
                    <th>Image</th>
                    <th>Name</th>
                    <th></th>
                </tr>
            </template>
            <template #tbody>
                <tr v-for="company in companies">
                    <td>
                        <uploaded-logo :imageSrc="company.company_logo" leftMargin="45" />
                    </td>
                    <td>{{ company.company_name }}</td>
                    <td>
                        <div class="flex justify-end items-center">
                            <online-status :status="company.company_enable" class="mr-4" />
                            <router-link :to="'/companies/edit/' + company.id" class="mr-4">
                                <icon-button icon="edit" color="secondary" />
                            </router-link>
                            <router-link :to="'/companies/view/' + company.id" class="mr-4">
                                <icon-button icon="eye" color="secondary" />
                            </router-link>
                            <icon-button icon="trash-alt" color="danger" class="mr-2" @click="areYouSureOpen = true; currentDeleteId = company.id" />
                        </div>
                    </td>
                </tr>
            </template>
        </app-table>

        <pop-up-confirm message="Are you sure you want to delete this company?"
                        :isOpen="areYouSureOpen"
                        :id="currentDeleteId"
                        @canceled="areYouSureOpen = false"
                        @confirmed="deleteCompany" />

    </app-layout>
</template>
<script>

import AppLayout from '../../components/layout/AppLayout'
import Panel from '../../components/ui/Panel'
import AppButton from '../../components/ui/AppButton'
import IconButton from '../../components/ui/IconButton'
import AppTable from '../../components/ui/AppTable'
import PopUpConfirm from '../../components/PopUpConfirm'
import OnlineStatus from '../../components/OnlineStatus'
import UploadedLogo from '../../components/UploadedLogo'

export default {
    name: 'Companies',
    components: {
        AppLayout,
        Panel,
        AppButton,
        IconButton,
        AppTable,
        PopUpConfirm,
        OnlineStatus,
        UploadedLogo
    },
    data() {
        return {
            companies: [],
            areYouSureOpen: false,
            currentDeleteId: null,
            loading: false
        };
    },
    methods: {
        getCompanies(done) {
            this.loading = true;
            this.$store.dispatch('companiesList').then((companies) => {
                this.companies = companies;
                if(done) {
                    done();
                }else{
                    this.loading = false;
                }
            });
        },
        deleteCompany() {
            this.areYouSureOpen = false;
            this.loading = true;
            this.$store.dispatch('companiesDelete', this.currentDeleteId).then((success) => {
                this.getCompanies(() => {
                    this.$fire({
                        title: "Company Deleted",
                        text: success.message,
                        type: "success",
                        timer: 3000
                    });
                    this.loading = false;
                });
            });
        }
    },
    mounted() {

        this.getCompanies();

    }
}

</script>
<style scoped>

</style>
