<template lang="html">
    <app-layout :class="{ 'loading-white': $store.state.loading }">

        <bread-crumbs :company="company" page="Add Site" />

        <div class="flex justify-between items-start">
            <h1 class="hdln-1 mb-8">Add Site</h1>
        </div>

        <form-section title="Site Details">

            <!-- <form-group class="w-full mb-4">
                <form-label :required="true">Branch</form-label>
                <select class="form-select"
                        :class="{ invalid: !isValid('branch') && showErrors }"
                        v-model="form.branch.value">
                    <option value="">Choose Branch</option>
                    <option value="1">Home</option>
                    <option value="2">Other</option>
                </select>
                <validation-message :show="showErrors && !isValid('branch')">branch required</validation-message>
            </form-group> -->

            <form-group class="w-full mb-4">
                <form-label :required="true">Name</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('site_name') && showErrors }"
                       v-model="form.site_name.value" />
                <validation-message :show="showErrors && !isValid('site_name')">site name required</validation-message>
            </form-group>

            <form-group class="w-full mb-4">
                <form-label :required="true">URL</form-label>
                <input type="text"
                       class="form-input"
                       :class="{ invalid: !isValid('site_url') && showErrors }"
                       v-model="form.site_url.value" />
                <validation-message :show="showErrors && !isValid('site_url')">site url required</validation-message>
            </form-group>

            <form-group class="w-full">
                <fake-checkbox :checked="boolVal(form.site_enable.value)"
                               id="site_enable"
                               @changed="enabledCheckboxChanged">
                    Enabled
               </fake-checkbox>
            </form-group>

        </form-section>

        <form-section title="Contact Details">

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Address Line One</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('address_line_1') && showErrors }"
                           v-model="form.address_line_1.value" />
                    <validation-message :show="showErrors && !isValid('address_line_1')">address line one required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="false">Address Line Two</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('address_line_2') && showErrors }"
                           v-model="form.address_line_2.value" />
                    <validation-message :show="showErrors && !isValid('address_line_2')">address line two required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">City</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('city') && showErrors }"
                           v-model="form.city.value" />
                    <validation-message :show="showErrors && !isValid('city')">city required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="true">Country</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('country') && showErrors }"
                           v-model="form.country.value" />
                    <validation-message :show="showErrors && !isValid('country')">country required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Postcode</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('postal_code') && showErrors }"
                           v-model="form.postal_code.value" />
                    <validation-message :show="showErrors && !isValid('postal_code')">postcode required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="true">Email</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('email') && showErrors }"
                           v-model="form.email.value" />
                    <validation-message :show="showErrors && !isValid('email')">email required</validation-message>
                </form-group>

            </div>

            <div class="flex flex-col md:flex-row">

                <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                    <form-label :required="true">Contact Number One</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('contact_number_one') && showErrors }"
                           v-model="form.contact_number_one.value" />
                    <validation-message :show="showErrors && !isValid('contact_number_one')">contact number one required</validation-message>
                </form-group>

                <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                    <form-label :required="true">Contact Number Two</form-label>
                    <input type="text"
                           class="form-input"
                           :class="{ invalid: !isValid('contact_number_two') && showErrors }"
                           v-model="form.contact_number_two.value" />
                    <validation-message :show="showErrors && !isValid('contact_number_two')">contact number two required</validation-message>
                </form-group>

            </div>

        </form-section>

        <form-section title="Site Logo">

            <img v-if="logoPreview" :src="logoPreview" class="mx-auto mt-2 mb-4 logo-preview" />
            <div v-else>
                <font-awesome-icon icon="image" class="logo-icon" />
            </div>

            <label for="site_logo">
                <input id="site_logo" class="hidden" type="file" value="Click here to browse" @change="logoChanged" />
                <div class="bg-secondary cursor-pointer text-white rounded p-2 w-60 mx-auto mb-2">{{ logoFileUploadText }}</div>
            </label>

        </form-section>

        <div class="flex mt-8">
            <app-button class="mr-2" @click="submit">Add Site</app-button>
            <cancel-button :companyId="company.id" />
        </div>

    </app-layout>
</template>

<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import Panel from '../../../components/ui/Panel'
    import BreadCrumbs from '../../../components/companies/BreadCrumbs'
    import AppButton from '../../../components/ui/AppButton'
    import AppTable from '../../../components/ui/AppTable'
    import IconButton from '../../../components/ui/IconButton'
    import FormGroup from '../../../components/forms/FormGroup'
    import FormSection from '../../../components/forms/FormSection'
    import FormLabel from '../../../components/forms/FormLabel'
    import FakeCheckbox from '../../../components/forms/FakeCheckbox'
    import ValidationMessage from '../../../components/forms/ValidationMessage'
    import CancelButton from '../../../components/companies/CancelButton'

    import formHelpers from '../../../lib/form-helpers'

    export default {
        components: {
            AppLayout,
            Panel,
            BreadCrumbs,
            AppButton,
            AppTable,
            IconButton,
            FormSection,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            ValidationMessage,
            CancelButton
        },
        data() {
            return {
                showErrors: false,
                logoFileUploadText: 'Click here to browse',
                logoPreview: null,
                company: {
                    id: null,
                    company_name: ''
                },
                form: {
                    company_id: { value: null, required: true },
                    // branch: { value: '', required: true },
                    site_name: { value: null, required: true },
                    site_url: { value: null, required: true },
                    site_logo: { value: null, required: false },
                    site_enable: { value: 0, required: true, validation: 'checkbox' },
                    address_line_1: { value: null, required: true },
                    address_line_2: { value: null, required: false },
                    city: { value: null, required: true },
                    country: { value: null, required: true },
                    postal_code: { value: null, required: true },
                    email: { value: null, required: true },
                    contact_number_one: { value: null, required: true },
                    contact_number_two: { value: null, required: true }
                }
            }
        },
        methods: {
            logoChanged(e) {
                if(e.target.files.length > 0) {
                    const file = e.target.files[0];
                    this.logoFileUploadText = file.name;
                    this.form.site_logo.value = file;
                    this.logoPreview = URL.createObjectURL(file);
                }
            },
            boolVal(value) {
                return ( value === 1 ? true : false );
            },
            enabledCheckboxChanged() {
                if(this.form.site_enable.value === 1) {
                    this.form.site_enable.value = 0;
                } else {
                    this.form.site_enable.value = 1;
                }
            },
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            submit() {

                const self = this;

                if(formHelpers.isFormValid(this.form)) {

                    const data = formHelpers.getFormData(this.form, true);
                    this.$store.dispatch('companySiteAdd', data).then((success) => {
                        if(success) {

                            this.$fire({
                                title: "Site Added",
                                text: success.message,
                                type: "success",
                                timer: 3000
                            });

                            self.$router.push(this.$store.getters.viewCompanyLink(this.company.id));
                        }
                    });
                }else{
                    this.showErrors = true;
                }

            }
        },
        mounted() {

            const companyId = this.$route.params.companyId;

            this.form.company_id.value = companyId;

            this.$store.dispatch('companiesGet', companyId).then((company) => {
                this.company = company;
            });

        }
    }

</script>

<style lang="css">

    .logo-icon {
        font-size: 138px;
        color: #d6d6d6;
    }

    .logo-preview {
        width: 300px;
        height: 225px;
        object-fit: contain;
        object-position: center;
    }

</style>
