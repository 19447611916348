<template>
    <div class="flex justify-between items-center doc">
        <p>{{ doc.title }}</p>
        <div class="flex justify-center items-center">
            <icon-button icon="file-word" color="blue" @click="download(doc.word)" />
            <!-- <icon-button v-if="doc.pdf" icon="file-pdf" class="ml-2" @click="download(doc.pdf)" /> -->
        </div>
    </div>
</template>
<script>

import IconButton from '../../components/ui/IconButton'

export default {
    name: 'DocFile',
    components: {
        IconButton
    },
    methods: {
        download(download_link) {
            window.location.href = download_link;
        }
    },
    props: {
        doc: Object
    }
}

</script>
<style>

.doc {
    border-bottom: solid 1px #d5d5d5;
    padding: 5px 0px;
    text-align: left;
}

</style>
