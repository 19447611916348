<template lang="html">
    <app-layout>

        <div :class="{ 'loading-white': pageLoading }">

            <bread-crumbs :links="[
                { name: 'Tests', href: '/admin/tests' },
                { name: exam.exam_title, href: '/admin/tests/' + exam.id + '/questions' },
                { name: 'Edit Question' }
            ]" />

            <div class="flex justify-between items-start">
                <h1 class="hdln-1 mb-8">Edit Question</h1>
            </div>

            <app-form ref="questionForm" @onSubmit="submit" :class="{ 'loading-white': formLoading }" />

        </div>

    </app-layout>
</template>
<script>

import AppLayout from '../../../../components/layout/AppLayout'
import Panel from '../../../../components/ui/Panel'
import BreadCrumbs from '../../../../components/ui/BreadCrumbs'
import AppButton from '../../../../components/ui/AppButton'
import IconButton from '../../../../components/ui/IconButton'
import AppTable from '../../../../components/ui/AppTable'
import AppForm from '../../../../components/exams/QuestionForm'

export default {
    components: {
        AppLayout,
        Panel,
        BreadCrumbs,
        AppButton,
        IconButton,
        AppTable,
        AppForm
    },
    data() {
        return {
            pageLoading: false,
            formLoading: false,
            testSet: {
                name: '',
                id: null
            },
            exam: {
                exam_title: null,
                id: null
            }
        };
    },
    methods: {
        submit(data) {

            this.formLoading = true;

            const formData = {
                id: this.$route.params.questionId,
                company_id: this.$store.state.user.currentCompany.id, // this.$store.state.user.user.company_id,
                company_test_id: data.exam_id,
                question_type: data.question_type,
                question: data.question,
                question_marks: data.question_marks,
                answers: data.answers
            };

            this.$store.dispatch('updateCompanyQuestion', formData).then((success) => {
                if(success) {

                    this.$fire({
                        title: "Question Updated",
                        text: success.message,
                        type: "success",
                        timer: 3000
                    });

                    this.formLoading = false;
                    this.$router.push('/admin/tests/' + this.exam.id + '/questions');

                }
            }).catch(() => { this.formLoading = false; });

        }
    },
    mounted() {

        this.pageLoading = true;

        Promise.all([
            this.$store.dispatch('getCompanyTest', this.$route.params.examId),
            this.$store.dispatch('getCompanyQuestion', this.$route.params.questionId)
        ]).then((data) => {
            this.exam = data[0];
            this.$refs.questionForm.setForm({
                question_type: data[1].questionDetails.question_type,
                question_marks: data[1].questionDetails.questionMarks,
                question: data[1].questionDetails.question,
                answers: data[1].answerDetails
            });
            this.pageLoading = false;
        });

    }
}

</script>
