<template lang="html">
    <app-layout>

        <bread-crumbs :links="[
            { name: 'Policies', href: '/admin/policies/management' },
            { name: 'Update Lesson' }
        ]" />

        <h1 class="hdln-1 mb-8">Update Lesson</h1>

        <div class="flex flex-col md:flex-row" :class="{ 'loading-white': $store.state.loading }">

            <div class="w-full xl:w-3/4">

                <panel class="p-4 mb-8">

                    <form-group class="w-full mb-4">
                        <form-label :required="true">Lesson Title</form-label>
                        <input type="text"
                               class="form-input"
                               :class="{ invalid: !isValid('title') && showErrors }"
                               v-model="form.title.value" />
                        <validation-message :show="showErrors && !isValid('title')">lesson title required</validation-message>
                    </form-group>

                    <form-group class="w-full mb-4">
                        <form-label :required="true">Description</form-label>
                        <textarea class="form-input"
                               :class="{ invalid: !isValid('description') && showErrors }"
                               v-model="form.description.value"></textarea>
                        <validation-message :show="showErrors && !isValid('description')">description required</validation-message>
                    </form-group>

                    <div class="flex flex-col md:flex-row">

                        <form-group class="w-full md:w-1/2 md:pr-2 mb-4">
                            <form-label :required="true">Expected Duration[Minutes]</form-label>
                            <input type="text"
                                   class="form-input"
                                   :class="{ invalid: !isValid('expected_duration') && showErrors }"
                                   v-model="form.expected_duration.value" />
                            <validation-message :show="showErrors && !isValid('expected_duration')">expected duration required</validation-message>
                        </form-group>

                        <form-group class="w-full md:w-1/2 md:pl-2 mb-4">
                            <form-label :required="true">Sort order</form-label>
                            <input type="text"
                                   class="form-input"
                                   :class="{ invalid: !isValid('sort_order') && showErrors }"
                                   v-model="form.sort_order.value" />
                            <validation-message :show="showErrors && !isValid('sort_order')">sort order required</validation-message>
                        </form-group>

                    </div>

                </panel>

                <lesson-module v-for="(module, i) in modules"
                               :module="module"
                               :index="i" />

               <!-- <modal>
                   <summer-note-editor :index="index" :value="content" @input="descriptionChanged" />
               </modal> -->

                <app-button color="secondary" class="float-right" @click="addModule">Add Module</app-button>

                <div class="flex mt-8">
                    <app-button class="mr-2" @click="submit">Update Lesson</app-button>
                    <router-link :to="'/admin/policies/management'">
                        <app-button color="danger">Cancel</app-button>
                    </router-link>
                </div>

            </div>

        </div>

    </app-layout>
</template>

<script>

    import AppLayout from '../../../components/layout/AppLayout'
    import Panel from '../../../components/ui/Panel'
    import BreadCrumbs from '../../../components/ui/BreadCrumbs'
    import AppButton from '../../../components/ui/AppButton'
    import AppTable from '../../../components/ui/AppTable'
    import FormGroup from '../../../components/forms/FormGroup'
    import FormSection from '../../../components/forms/FormSection'
    import FormLabel from '../../../components/forms/FormLabel'
    import FakeCheckbox from '../../../components/forms/FakeCheckbox'
    import ValidationMessage from '../../../components/forms/ValidationMessage'
    import LessonModule from '../../../components/LessonModule'

    // import VueHtmlEditor from "vue-html-editor/dist/vue-html-editor.js";
    // import SummerNoteEditor from '../../../components/SummerNoteEditor'

    import formHelpers from '../../../lib/form-helpers'

    export default {
        components: {
            AppLayout,
            Panel,
            BreadCrumbs,
            AppButton,
            AppTable,
            FormSection,
            FormGroup,
            FormLabel,
            FakeCheckbox,
            ValidationMessage,
            LessonModule
        },
        data() {
            return {
                content: '<p>Yeah Ok</p>',
                showErrors: false,
                masterPolicySet: {
                    id: null,
                    title: null
                },
                form: {
                    id: { value: this.$route.params.id, required: true },
                    company_policy_id: { value: null, required: true },
                    status: { value: 1, required: true },
                    title: { value: '', required: true },
                    description: { value: '', required: true },
                    expected_duration: { value: '', required: true, validation: 'number' },
                    sort_order: { value: '', required: true, validation: 'number' },
                }
            }
        },
        computed: {
            modules() {
                return this.$store.state.lesson.modules;
            }
        },
        methods: {
            addModule() {
                this.$store.commit('addLessonModule');
            },
            isValid(field) {
                return formHelpers.isValid(this.form, field);
            },
            submit() {

                if(formHelpers.isFormValid(this.form)) {

                    const data = formHelpers.getFormData(this.form, true);

                    this.$store.dispatch('updateCompanyLesson', data).then((success) => {
                        if(success) {

                            this.$fire({
                                title: "Lesson updated",
                                text: success.message,
                                type: "success",
                                timer: 3000
                            });

                            this.$router.push('/admin/policies/management');

                        }
                    });

                }else{
                    this.showErrors = true;
                }

            }
        },
        mounted() {

            this.$store.commit('initModules');

            this.$store.dispatch('getCompanyLesson', this.$route.params.id).then((data) => {

                const lesson = data.lessonDetails;
                const modules = data.lessonModules;

                // form set
                this.form.company_policy_id.value = lesson.company_policy_id;
                this.form.description.value = lesson.description;
                this.form.expected_duration.value = lesson.expected_duration;
                this.form.sort_order.value = lesson.sort_order;
                this.form.title.value = lesson.title;

                // set modules
                this.$store.commit('setModules', modules);

            });

        }
    }

</script>

<style lang="css">

    .lesson-module {
        border: solid 2px #d1d1d1;
        border-radius: 3px;
        background: #fff;
    }

    .lesson-module--header {
        border-bottom: solid 2px #4aa96c;
    }

</style>
